import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { FormalityAction } from "../types";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useWindowSize } from "../hooks/useWindowSize";

export const ErrorAlert = () => {
    const [, width] = useWindowSize();
    const dispatch = useAppDispatch();
    const { alert } = useAppSelector((state) => state.formality);

    const {
        alertType,
        emoticon,
        message,
        messageIsHtml = false,
        title,
    } = useMemo(() => {
        const alertType = alert?.alertType || undefined;
        const emoticon = alert?.emoticon || undefined;
        const message = alert?.message || undefined;
        const messageIsHtml = alert?.messageIsHTML === true ? true : false;
        const title = alert?.title || undefined;
        return {
            alertType,
            emoticon,
            message,
            messageIsHtml,
            title,
        };
    }, [alert]);

    return (
        <div className="container">
            <Alert
                className="mb1 animate__animated animate__fadeIn"
                dismissible
                variant={alertType}
                style={{ fontSize: width >= 576 ? 16 : 14 }}
                onClose={() =>
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: "",
                    })
                }
            >
                <strong>
                    {emoticon ? (
                        <span className="me-2" role="img" aria-label="emoticon">
                            {emoticon}
                        </span>
                    ) : null}
                    <span className="user-alert-title">{title}</span>
                </strong>
                {messageIsHtml && message ? (
                    <div
                        className="mt-3 user-alert-message"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                ) : (
                    <div className="user-alert-message">{message}</div>
                )}
            </Alert>
        </div>
    );
};

import { API, graphqlOperation } from "aws-amplify";
import { CalendarEvent } from "../Classes/CalendarEvent";
import { FormDataAction, FormalityAction, UserAction } from "../types";
import { getSavedUserDocuments } from "./formalityActions";
import { ManagerAction } from "../types/manager";
import { omit, pick, omitBy, isNil } from "lodash";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import moment from "moment";

// export const transferFormDataToNewEmployee = (
//   employees,
//   pendingEmployees
// ) => async (dispatch) => {
//   try {
//     console.warn("current", employees);
//     console.warn("pending", pendingEmployees);
//     return Promise.resolve();
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };
export const cleanPendingEmployees = (employees) => async (dispatch) => {
    const deletePendingEmployee = async (id) => {
        try {
            const { data } = await API.graphql(
                graphqlOperation(mutations.deleteFormalityUser, {
                    input: { id },
                })
            );
            return data;
        } catch (error) {
            throw error;
        }
    };

    try {
        dispatch({ type: ManagerAction.CLEAN_PENDING_EMPLOYEES_START });
        const pendingDeletes = employees.map((item) =>
            deletePendingEmployee(item)
        );
        const deletedEmployees = await Promise.all(pendingDeletes);
        dispatch({ type: ManagerAction.CLEAN_PENDING_EMPLOYEES_SUCCESS });
        return Promise.resolve(
            deletedEmployees && deletedEmployees.length
                ? deletedEmployees.length
                : 0
        );
    } catch (error) {
        dispatch({
            type: ManagerAction.CLEAN_PENDING_EMPLOYEES_FAIL,
            payload: error,
        });
        return Promise.reject(error);
    }
};

export const createEmployeeCalendarEvents = (companyId) => async (dispatch) => {
    try {
        dispatch({ type: ManagerAction.CREATE_EMPLOYEE_CALENDAR_EVENTS_START });
        const calendarEvents = [];

        const insertUsername = (username) => {
            if (username[0]) {
                return `${username}'s `;
            }
            return "";
        };

        const getImmigrationEventTag = (eventType) => {
            switch (eventType) {
                case "expDate":
                    return `visaExtensionWorkPermit`;
                case `ninetyDayExpDate`:
                    return `90DayReporting`;
                case `visaExpDate`:
                    return `visaExtensionWorkPermit`;
                default:
                    return "Something's not right: " + eventType;
            }
        };

        const getEventTitle = (eventType, userId) => {
            const username = names
                .filter((item) => userId === item.userId)
                .map((item) => item.names.firstName);
            switch (eventType) {
                case "expDate":
                    return `${insertUsername(username)}Passport Expires`;
                case `ninetyDayExpDate`:
                    return `${insertUsername(username)}90-Day Notification`;
                case `visaExpDate`:
                    return `${insertUsername(username)}Visa Expires`;
                default:
                    return "Something's not right: " + eventType;
            }
        };

        const getEventStart = (date) => {
            return moment(date)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format();
        };

        const getEventEnd = (date) => {
            return moment(date)
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .format();
        };

        const companyFormData = await dispatch(getCompanyFormData(companyId));
        const names = companyFormData
            .filter((item) => item.name === "personalInfoFormData")
            .map((item) => ({
                userId: item.userId,
                names: pick(JSON.parse(item.data), ["firstName", "familyName"]),
            }));
        const travelDocEvents = companyFormData
            .filter((item) => item.name === "travelDocFormData")
            .map((item) => ({
                userId: item.userId,
                events: pick(JSON.parse(item.data), [
                    "expDate",
                    "ninetyDayExpDate",
                    "visaExpDate",
                ]),
            }));
        for (let user of travelDocEvents) {
            for (let event in user.events) {
                calendarEvents.push(
                    new CalendarEvent(
                        getEventTitle(event, user.userId),
                        getEventStart(user.events[event]),
                        getEventEnd(user.events[event]),
                        true,
                        {
                            userId: user.userId,
                            eventType: getImmigrationEventTag(event),
                        }
                    )
                );
            }
        }
        dispatch({
            type: ManagerAction.CREATE_EMPLOYEE_CALENDAR_EVENTS_SUCCESS,
            payload: calendarEvents,
        });
        return Promise.resolve(calendarEvents);
    } catch (error) {
        dispatch({
            type: ManagerAction.CREATE_EMPLOYEE_CALENDAR_EVENTS_FAIL,
        });
        return Promise.reject(error);
    }
};

export const getCompanyEmployees = (companyId) => async (dispatch) => {
    try {
        dispatch({ type: ManagerAction.GET_COMPANY_EMPLOYEES_START });
        let { data } = await API.graphql(
            graphqlOperation(queries.listFormalityUsers, {
                filter: {
                    companyId: { eq: companyId },
                },
                limit: 50,
            })
        );
        dispatch({ type: ManagerAction.GET_COMPANY_EMPLOYEES_SUCCESS });
        return Promise.resolve(data.listFormalityUsers.items);
    } catch (error) {
        console.warn(error);
        dispatch({ type: ManagerAction.GET_COMPANY_EMPLOYEES_FAIL });
        return Promise.reject(error);
    }
};

export const disconnectAllEmployeesFromCompany =
    () => async (dispatch, getState) => {
        const disconnectEmployee = async (employeeId) => {
            try {
                const userInput = {
                    id: employeeId,
                    role: null,
                    companyId: null,
                };
                console.log("🪓 Disconnecting Employee from Company...");
                await API.graphql(
                    graphqlOperation(mutations.updateFormalityUser, {
                        input: userInput,
                    })
                );
                const response = await API.post(
                    "thaiFormalityAPI",
                    "/cognito/disconnect-user-from-company",
                    {
                        body: {
                            userId: employeeId,
                        },
                    }
                );
                console.log(`👹 ${response.message}`);
                return response.message;
            } catch (error) {
                throw error;
            }
        };

        try {
            dispatch({
                type: ManagerAction.DISCONNECT_ALL_EMPLOYEES_FROM_COMPANY_START,
            });
            const {
                auth: { id: currentUserId },
                subscription: {
                    employees: { items: employees },
                },
            } = getState();
            const employeesToDisconnect = employees.filter(
                (item) => item.id !== currentUserId
            );
            const pendingDisconnectedEmployees = employeesToDisconnect.map(
                (item) => disconnectEmployee(item.id)
            );
            const disconnectedEmployees = await Promise.all(
                pendingDisconnectedEmployees
            );
            dispatch({
                type: ManagerAction.DISCONNECT_ALL_EMPLOYEES_FROM_COMPANY_SUCCESS,
            });
            return Promise.resolve(disconnectedEmployees);
        } catch (error) {
            dispatch({
                type: ManagerAction.DISCONNECT_ALL_EMPLOYEES_FROM_COMPANY_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const disconnectEmployeeFromCompany = (employee) => async (dispatch) => {
    try {
        const userInput = {
            id: employee.id,
            role: null,
            companyId: null,
        };
        console.log("🪓 Disconnecting Employee from Company...");
        await API.graphql(
            graphqlOperation(mutations.updateFormalityUser, {
                input: userInput,
            })
        );
        const response = await API.post(
            "thaiFormalityAPI",
            "/cognito/disconnect-user-from-company",
            {
                body: {
                    userId: employee.id,
                },
            }
        );
        dispatch({
            type: ManagerAction.REMOVE_DISCONNECTED_EMPLOYEE,
            payload: employee.id,
        });
        console.log(`👹 ${response.message}`);
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getCompanyFormData = (companyId) => async (dispatch, getState) => {
    try {
        const {
            auth: { isDemo },
            subscription: { forms },
        } = getState();
        dispatch({ type: ManagerAction.GET_COMPANY_FORM_DATA_START });
        if (!isDemo) {
            let { data } = await API.graphql(
                graphqlOperation(queries.listFormalityForms, {
                    filter: {
                        companyId: { eq: companyId },
                    },
                    limit: 50,
                })
            );
            dispatch({ type: ManagerAction.GET_COMPANY_FORM_DATA_SUCCESS });
            return Promise.resolve(data.listFormalityForms.items);
        } else {
            dispatch({ type: ManagerAction.GET_COMPANY_FORM_DATA_SUCCESS });
            const companyFormData = [];
            for (let item of forms.items) {
                const payload = [
                    {
                        companyId,
                        id: `${item.auth.id}_personalInfoFormData`,
                        userId: item.auth.id,
                        name: "personalInfoFormData",
                        data: JSON.stringify(item.personalInfoFormData),
                    },
                    {
                        companyId,
                        id: `${item.auth.id}_travelDocFormData`,
                        userId: item.auth.id,
                        name: "travelDocFormData",
                        data: JSON.stringify(item.travelDocFormData),
                    },
                ];
                companyFormData.push(...payload);
            }
            return Promise.resolve(companyFormData);
        }
    } catch (error) {
        console.warn(error);
        dispatch({ type: ManagerAction.GET_COMPANY_FORM_DATA_FAIL, error });
        return Promise.reject(error);
    }
};

export const getCompanyData = (companyId) => async (dispatch) => {
    try {
        dispatch({ type: ManagerAction.GET_COMPANY_DATA_START });
        const {
            data: { getFormalityCompany: companyData },
        } = await API.graphql(
            graphqlOperation(queries.getFormalityCompany, { id: companyId })
        );
        dispatch({ type: ManagerAction.GET_COMPANY_DATA_SUCCESS });
        return Promise.resolve(companyData);
    } catch (error) {
        dispatch({ type: ManagerAction.GET_COMPANY_DATA_FAIL });
        return Promise.reject(error);
    }
};
export const getManagerCompanyData = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ManagerAction.GET_COMPANY_MANAGER_DATA_START });
        const {
            user: { companyId },
        } = getState();
        let employees = await dispatch(getCompanyEmployees(companyId));
        // console.warn("employees", employees);
        const pendingEmployees = employees.filter((item) => item.isPending);
        // console.warn("pending employees", pendingEmployees);
        if (pendingEmployees.length) {
            const registeredEmployeesEmails = employees
                .filter((item) => item.role === "employee")
                .map((item) => item.email);
            // console.warn("registered employees", registeredEmployeesEmails);
            const pendingEmployeesToDelete = pendingEmployees
                .filter((item) =>
                    registeredEmployeesEmails.includes(item.email)
                )
                .map((item) => item.id);
            // console.warn("pending deletes", pendingEmployeesToDelete);
            if (pendingEmployeesToDelete.length) {
                console.log("🧼 Cleaning pending employee placeholders...");
                const cleaned = await dispatch(
                    cleanPendingEmployees(pendingEmployeesToDelete)
                );
                console.log(
                    `✨ ${cleaned} pending employee placeholder(s) cleaned!`
                );
            }
            employees = employees.filter(
                (item) => !pendingEmployeesToDelete.includes(item.id)
            );
        }
        const companyData = await dispatch(getCompanyData(companyId));
        console.warn(
            "How many employees are here?  Can't seem to set the limit on connected fields at the schema level!",
            companyData.employees.items.length
        );
        await dispatch(createEmployeeCalendarEvents(companyId));
        const payload = omitBy(companyData, isNil);
        dispatch({
            type: ManagerAction.GET_COMPANY_MANAGER_DATA_SUCCESS,
            payload: {
                ...omit(payload, ["employees"]),
                employees: { items: [...employees], nextToken: null },
            },
        });
        return Promise.resolve();
    } catch (error) {
        if (error.errors) {
            console.warn(error.errors[0].message);
            dispatch({
                type: ManagerAction.GET_COMPANY_MANAGER_DATA_FAIL,
                payload: error.errors[0].message,
            });
            return Promise.reject(error.errors[0].message);
        }
        console.warn(error);
        dispatch({
            type: ManagerAction.GET_COMPANY_MANAGER_DATA_FAIL,
            payload: error,
        });
        return Promise.reject(error);
    }
};

export const viewEmployeeDocumentList =
    (employee, isDemo = false) =>
    async (dispatch, getState) => {
        try {
            const {
                formality: { savedUserDocs },
            } = getState();
            let docs = [];
            if (
                !isDemo ||
                !savedUserDocs.length ||
                (savedUserDocs.length &&
                    savedUserDocs[0].key.split("/")[0] === !employee.id)
            ) {
                docs = await dispatch(getSavedUserDocuments(employee.id));
            }
            dispatch({ type: UserAction.SET_IMPERSONATING, payload: employee });
            return Promise.resolve(docs);
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const editEmployeeFormData =
    (employee) => async (dispatch, getState) => {
        try {
            const {
                auth: { isDemo },
            } = getState();
            if (!isDemo) {
                await dispatch(getEmployeeFormData(employee.id));
                await dispatch(getSavedUserDocuments(employee.id));
            } else {
                const {
                    subscription: { forms },
                } = getState();
                const employeeForms = forms.items.filter(
                    (item) => item.id === employee.id
                );
                dispatch({
                    type: FormDataAction.SETUP_DEMO_FORMS,
                    payload: omit(employeeForms[0], "auth", "id"),
                });
            }
            await dispatch(getSavedUserDocuments(employee.id));
            dispatch({ type: UserAction.SET_IMPERSONATING, payload: employee });
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    };

export const getEmployeeFormData =
    (employeeId, forTransfer = false) =>
    async (dispatch) => {
        try {
            dispatch({ type: ManagerAction.GET_EMPLOYEE_FORM_DATA_START });
            const {
                data: {
                    listFormalityForms: { items },
                },
            } = await API.graphql(
                graphqlOperation(queries.listFormalityForms, {
                    filter: {
                        userId: { eq: employeeId },
                    },
                    limit: 50,
                })
            );
            const formData = {};
            for (let item of items) {
                formData[item.name] = JSON.parse(item.data);
            }
            if (!forTransfer) {
                dispatch({
                    type: ManagerAction.GET_EMPLOYEE_FORM_DATA_SUCCESS,
                    payload: formData,
                });
            }
            return Promise.resolve(formData);
        } catch (error) {
            dispatch({
                type: ManagerAction.GET_EMPLOYEE_FORM_DATA_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const resetImpersonating = () => (dispatch, getState) => {
    const {
        user: { impersonating },
    } = getState();
    if (impersonating.id) {
        dispatch({ type: UserAction.SET_IMPERSONATING, payload: {} });
        dispatch({ type: FormDataAction.RESET_FORMS });
        dispatch({ type: FormalityAction.RESET_SAVED_USER_DOCS });
    }
};

export const sendEmployeeInvite =
    ({ username: recipient, firstName, from: manager, url }) =>
    async (dispatch) => {
        // console.warn(recipient, firstName, manager, url);
        dispatch({ type: ManagerAction.SEND_EMPLOYEE_INVITE_START });
        try {
            const response = await API.post(
                "thaiFormalityAPI",
                "/email/invite",
                {
                    body: {
                        recipient,
                        firstName,
                        manager,
                        url,
                    },
                }
            );
            console.log(`💌 Invitation email sent to ${recipient}!`);
            dispatch({ type: ManagerAction.SEND_EMPLOYEE_INVITE_SUCCESS });
            return Promise.resolve(response);
        } catch (error) {
            console.warn(error.message);
            dispatch({
                type: ManagerAction.SEND_EMPLOYEE_INVITE_FAIL,
                payload: error.message,
            });
            return Promise.reject(
                error.response.data.error
                    ? new Error(error.response.data.error.message)
                    : error
            );
        }
    };

import { AuthAction, AuthState, AuthUser, SignupPayload } from "../types";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState: AuthState = {
    attemptedValues: {},
    attributes: [],
    awaitingSignUpConfirmation: false,
    codeResent: false,
    cognitoUser: undefined,
    confirmationError: "",
    email: "",
    forgotPassword: false,
    id: "",
    isAdmin: false,
    isDemo: false,
    newPasswordRequired: false,
    signInError: "",
    signUpError: "",
};
const complete_new_password_success = createAction<null>(
    AuthAction.COMPLETE_NEW_PASSWORD_SUCCESS
);
const confirm_verification_code_start = createAction<null>(
    AuthAction.CONFIRM_VERIFICATION_CODE_START
);
// const confirm_verification_code_fail = createAction<null>(
//     AuthAction.CONFIRM_VERIFICATION_CODE_FAIL
// );
const confirm_verification_code_stop = createAction<null>(
    AuthAction.CONFIRM_VERIFICATION_CODE_STOP
);
const confirm_verification_code_success = createAction<null>(
    AuthAction.CONFIRM_VERIFICATION_CODE_SUCCESS
);
const forgot_password_fail = createAction<null>(
    AuthAction.FORGOT_PASSWORD_FAIL
);
const forgot_password_start = createAction<null>(
    AuthAction.FORGOT_PASSWORD_START
);
const forgot_submit_password_success = createAction<null>(
    AuthAction.FORGOT_SUBMIT_PASSWORD_SUCCESS
);
const get_cognito_user_details_success = createAction<AuthUser>(
    AuthAction.GET_COGNITO_USER_DETAILS_SUCCESS
);
const initiate_sign_up_start = createAction<SignupPayload>(
    AuthAction.INITIATE_SIGN_UP_START
);
const initiate_sign_up_success = createAction<{
    awaitingSignUpConfirmation: boolean;
}>(AuthAction.INITIATE_SIGN_UP_SUCCESS);
const resend_verification_code_fail = createAction<null>(
    AuthAction.RESEND_VERIFICATION_CODE_FAIL
);
const resend_verification_code_start = createAction<null>(
    AuthAction.RESEND_VERIFICATION_CODE_START
);
const resend_verification_code_success = createAction<null>(
    AuthAction.RESEND_VERIFICATION_CODE_SUCCESS
);
const reset_auth = createAction<null>(AuthAction.RESET_AUTH);
const set_sign_up_error = createAction<string>(AuthAction.SET_SIGN_UP_ERROR);
const set_unconfirmed_user = createAction<{
    signUpError: string;
    attemptedValues: Record<string, any>;
}>(AuthAction.SET_UNCONFIRMED_USER);
const setup_demo_auth = createAction<Record<string, any>>(
    AuthAction.SETUP_DEMO_AUTH
);
const sign_in_fail = createAction<{
    attemptedValues: Record<string, any>;
    error: string;
}>(AuthAction.SIGN_IN_FAIL);
const sign_in_success = createAction<Record<string, any>>(
    AuthAction.SIGN_IN_SUCCESS
);
const sign_out_success = createAction<Record<string, any>>(
    AuthAction.SIGN_OUT_SUCCESS
);
const update_auth = createAction<Record<string, any>>(AuthAction.UPDATE_AUTH);
const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(complete_new_password_success, (state) => {
            return {
                ...state,
                newPasswordRequired: false,
                cognitoUser: undefined,
            };
        })
        .addCase(confirm_verification_code_start, (state) => {
            return { ...state, codeResent: false };
        })
        .addCase(confirm_verification_code_stop, (state) => {
            return {
                ...state,
                awaitingSignUpConfirmation: false,
                codeResent: false,
            };
        })
        .addCase(confirm_verification_code_success, (state) => {
            return { ...state, awaitingSignUpConfirmation: false };
        })
        .addCase(forgot_password_fail, (state) => {
            return { ...state, forgotPassword: false };
        })
        .addCase(forgot_password_start, (state) => {
            return { ...state, forgotPassword: true };
        })
        .addCase(forgot_submit_password_success, (state) => {
            return { ...state, forgotPassword: false };
        })
        .addCase(get_cognito_user_details_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(initiate_sign_up_start, (state, action) => {
            return {
                ...state,
                attemptedValues: action.payload,
                signUpError: "",
            };
        })
        .addCase(initiate_sign_up_success, (state, action) => {
            return {
                ...state,
                awaitingSignUpConfirmation:
                    action.payload.awaitingSignUpConfirmation,
            };
        })
        .addCase(resend_verification_code_fail, (state) => {
            return {
                ...state,
                awaitingSignUpConfirmation: true,
                codeResent: true,
            };
        })
        .addCase(resend_verification_code_start, (state) => {
            return {
                ...state,
                awaitingSignUpConfirmation: true,
                codeResent: true,
            };
        })
        .addCase(resend_verification_code_success, (state) => {
            return {
                ...state,
                awaitingSignUpConfirmation: true,
                codeResent: true,
            };
        })
        .addCase(reset_auth, () => {
            return initialState;
        })
        .addCase(set_sign_up_error, (state, action) => {
            return {
                ...state,
                signUpError: action.payload,
            };
        })
        .addCase(set_unconfirmed_user, (state, action) => {
            return {
                ...state,
                signUpError: action.payload.signUpError,
                attemptedValues: action.payload.attemptedValues,
            };
        })
        .addCase(setup_demo_auth, (state, action) => {
            return {
                ...state,
                ...action.payload.auth,
                attemptedValues: "",
                awaitingSignUpConfirmation: false,
            };
        })
        .addCase(sign_in_fail, (state, action) => {
            return {
                ...state,
                attemptedValues: action.payload.attemptedValues,
                signInError: action.payload.error,
            };
        })
        .addCase(sign_in_success, (state, action) => {
            return {
                ...state,
                ...action.payload,
                attemptedValues: {},
                awaitingSignUpConfirmation: false,
            };
        })
        .addCase(sign_out_success, () => {
            return initialState;
        })
        .addCase(update_auth, (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        });
});
export default reducer;
// export default (state = { ...initialState }, action) => {
//     switch (action.type) {
//         case "GET_COGNITO_USER_DETAILS_SUCCESS":
//             return { ...state, ...action.payload };
//         case "INITIATE_SIGN_UP_START":
//             return {
//                 ...state,
//                 attemptedValues: action.payload,
//                 signUpError: "",
//             };
//         case "INITIATE_SIGN_UP_SUCCESS":
//             return {
//                 ...state,
//                 awaitingSignUpConfirmation:
//                     !action.payload.awaitingSignUpConfirmation,
//             };
//         // case "INITIATE_SIGN_UP_FAIL":
//         //   return { ...state,... };
//         case "RESEND_VERIFICATION_CODE_FAIL":
//             return {
//                 ...state,
//                 awaitingSignUpConfirmation: false,
//                 confirmationError: action.payload,
//             };
//         case "FORGOT_PASSWORD_FAIL":
//             return { ...state, forgotPassword: false };
//         case "FORGOT_PASSWORD_START":
//             return { ...state, forgotPassword: true };
//         case "FORGOT_SUBMIT_PASSWORD_SUCCESS":
//             return { ...state, forgotPassword: false };
//         case "RESEND_VERIFICATION_CODE_START":
//             return {
//                 ...state,
//                 awaitingSignUpConfirmation: true,
//                 codeResent: true,
//             };
//         case "RESEND_VERIFICATION_CODE_SUCCESS":
//             return {
//                 ...state,
//                 awaitingSignUpConfirmation: true,
//                 codeResent: true,
//             };
//         case "RESET_AUTH":
//             return { ...initialState };
//         case "SET_SIGN_UP_ERROR":
//             return {
//                 ...state,
//                 signUpError: action.payload.signUpError,
//             };
//         case "SET_UNCONFIRMED_USER":
//             return {
//                 ...state,
//                 signUpError: action.payload.signUpError,
//                 attemptedValues: action.payload.attemptedValues,
//             };
//         case "SETUP_DEMO_AUTH":
//             return {
//                 ...state,
//                 ...action.payload.auth,
//                 attemptedValues: "",
//                 awaitingSignUpConfirmation: false,
//             };
//         case "SIGN_IN_FAIL":
//             return {
//                 ...state,
//                 attemptedValues: action.payload.attemptedValues,
//                 signInError: action.payload.error,
//             };
//         case "SIGN_IN_SUCCESS":
//             return {
//                 ...state,
//                 ...action.payload,
//                 attemptedValues: "",
//                 awaitingSignUpConfirmation: false,
//             };
//         case "UPDATE_AUTH":
//             return {
//                 ...state,
//                 ...action.payload,
//             };

//         default:
//             return state;
//     }
// };

import { API, graphqlOperation } from "aws-amplify";
import { AppThunk } from "../store/store";
import { FormDataAction } from "../types";
import { getSavedUserDocuments } from "./formalityActions";
import { isEmptyObject } from "../helpers";
import * as mutations from "../graphql/mutations";
import moment from "moment";

export const fillForm =
    (formId: string, isSubscribed: boolean): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            // console.warn("is subscribed: ", isSubscribed);
            dispatch({ type: FormDataAction.FILL_FORM_START });
            const { id: userId, isDemo } = getState().auth;
            const { impersonating } = getState().user;
            const {
                applicationDetailsFormData: { applicationDate, writtenAt },
                personalInfoFormData: {
                    dob,
                    email,
                    familyName,
                    firstName,
                    honorific,
                    middleName,
                    nationality,
                    occupation,
                    phone,
                    stateOfBirth,
                    countryOfBirth,
                },
                thaiAddressFormData: {
                    thaiAddressDistrict,
                    thaiAddressNumber,
                    thaiAddressProvince,
                    thaiAddressRoad,
                    thaiAddressSubdistrict,
                },
                tm7FormData: {
                    extensionDays: tm7ExtensionDays,
                    reasonForExtension: tm7ReasonForExtension,
                },
                tm8FormData: {
                    intendedDestination: tm8IntendedDestination,
                    departureDate: tm8DepartureDate,
                    modeOfTransportation: tm8ModeOfTransportation,
                    permitType: tm8PermitType,
                    purposeOfVisa: tm8PurposeOfVisa,
                    returnDate: tm8ReturnDate,
                },
                travelDocFormData: {
                    arrivalDate: travelDocArrivalDate,
                    arrivedBy: travelDocArrivedBy,
                    arrivedFrom: travelDocArrivedFrom,
                    expDate: travelDocExpDate,
                    issueDate: travelDocIssueDate,
                    issuingCountry: travelDocIssuingCountry,
                    issuedBy: travelDocIssuedBy,
                    number: travelDocNumber,
                    portOfArrival: travelDocPortOfArrival,
                    tm6Number: travelDocTm6Number,
                    typeOfVisa: travelDocTypeOfVisa,
                    visaIssuedAt: travelDocVisaIssuedAt,
                    visaIssueDate: travelDocVisaIssueDate,
                    visaExpDate: travelDocVisaExpDate,
                },
            } = getState().formData;
            const age = moment().diff(moment(dob), "years");
            const applicantFullname =
                `${firstName} ${middleName} ${familyName}`.toUpperCase();
            let applicationDateDay;
            let applicationDateMonth;
            let applicationDateYear;
            if (applicationDate) {
                applicationDateDay = moment(applicationDate).format("D");
                applicationDateMonth = moment(applicationDate).format("MMMM");
                applicationDateYear = moment(applicationDate).format("YYYY");
            }
            const arrivalDateDay = moment(travelDocArrivalDate).format("D");
            const arrivalDateMonth =
                moment(travelDocArrivalDate).format("MMMM");
            const arrivalDateYear = moment(travelDocArrivalDate).format("YYYY");
            const dobDay = moment(dob).format("D");
            const dobMonth = moment(dob).format("MMMM");
            const dobMonthShort = moment(dob).format("MMM");
            const dobYear = moment(dob).format("YYYY");
            const thaiFullAddress = `${thaiAddressNumber}, ${
                thaiAddressRoad ? thaiAddressRoad + ", " : ""
            }${thaiAddressSubdistrict}, ${thaiAddressDistrict}, ${thaiAddressProvince}`;
            const travelDocExpDateDay = moment(travelDocExpDate).format("D");
            const travelDocExpDateMonth =
                moment(travelDocExpDate).format("MMMM");
            const travelDocExpDateYear =
                moment(travelDocExpDate).format("YYYY");
            const travelDocIssueDateDay =
                moment(travelDocIssueDate).format("D");
            const travelDocIssueDateMonth =
                moment(travelDocIssueDate).format("MMMM");
            const travelDocIssueDateYear =
                moment(travelDocIssueDate).format("YYYY");
            let data:Record<string,any> = {};
            if (formId === "aopfavo") {
                data = {
                    age,
                    applicationDateDay,
                    applicationDateMonth,
                    applicationDateYear,
                    familyName,
                    firstName,
                    middleName,
                    nationality,
                    travelDocNumber,
                    writtenAt,
                };
            } else if (formId === "stm_2") {
                data = {
                    age,
                    applicantFullname,
                    applicationDate: applicationDate
                        ? moment(applicationDate).format("MMMM Do, YYYY")
                        : "",
                    familyName,
                    firstName,
                    honorific,
                    middleName,
                    nationality,
                    reasonForExtension: tm7ReasonForExtension,
                    writtenAt,
                };
            } else if (formId === "tm_47") {
                data = {
                    applicantFullname,
                    applicationDateDay,
                    applicationDateMonth,
                    applicationDateYear,
                    arrivalDateDay,
                    arrivalDateMonth,
                    arrivalDateYear,
                    arrivedBy: travelDocArrivedBy,
                    arrivedFrom: travelDocArrivedFrom,
                    nationality,
                    phone,
                    thaiAddressDistrict,
                    thaiAddressNumber,
                    thaiAddressProvince,
                    thaiAddressRoad,
                    thaiAddressSubdistrict,
                    tm6Number: travelDocTm6Number,
                    travelDocNumber,
                    typeOfVisa: travelDocTypeOfVisa?.toUpperCase() || "",
                    writtenAt,
                };
                if (travelDocTypeOfVisa.toLowerCase().includes("imm")) {
                    data.tm47NonImmVisa = "X";
                } else {
                    data.tm47TouristVisa = "X";
                }
            } else if (formId === "tm_7") {
                data = {
                    age,
                    applicantFullname,
                    applicationDateDay,
                    applicationDateMonth,
                    applicationDateYear,
                    arrivalDateDay,
                    arrivalDateMonth,
                    arrivalDateYear,
                    arrivedBy: travelDocArrivedBy,
                    arrivedFrom: travelDocArrivedFrom,
                    dobDay,
                    dobMonth,
                    dobYear,
                    email: "Email: " + email,
                    extensionDays: tm7ExtensionDays,
                    familyName,
                    firstName,
                    middleName,
                    nationality,
                    phone: "Tel: " + phone,
                    placeOfBirth: `${stateOfBirth}, ${countryOfBirth}`,
                    portOfArrival: travelDocPortOfArrival,
                    reasonForExtension: tm7ReasonForExtension,
                    thaiAddressDistrict,
                    thaiAddressNumber,
                    thaiAddressProvince,
                    thaiAddressRoad,
                    thaiAddressSubdistrict,
                    thaiFullAddress,
                    tm6Number: travelDocTm6Number,
                    travelDocExpDateDay,
                    travelDocExpDateMonth,
                    travelDocExpDateYear,
                    travelDocIssueDateDay,
                    travelDocIssueDateMonth,
                    travelDocIssueDateYear,
                    travelDocIssuedBy,
                    travelDocNumber,
                    typeOfVisa: travelDocTypeOfVisa?.toUpperCase() || "",
                    writtenAt,
                };
            } else if (formId === "tm_8") {
                const departureDateDay = moment(tm8DepartureDate).format("D");
                const departureDateMonth =
                    moment(tm8DepartureDate).format("MMMM");
                const departureDateYear =
                    moment(tm8DepartureDate).format("YYYY");
                const returnDateDay = moment(tm8ReturnDate).format("D");
                const returnDateMonth = moment(tm8ReturnDate).format("MMMM");
                const returnDateYear = moment(tm8ReturnDate).format("YYYY");
                const visaIssueDateDay = moment(travelDocVisaIssueDate).format(
                    "D"
                );
                const visaIssueDateMonth = moment(
                    travelDocVisaIssueDate
                ).format("MMMM");
                const visaIssueDateYear = moment(travelDocVisaIssueDate).format(
                    "YYYY"
                );
                const visaExpDateDay = moment(travelDocVisaExpDate).format("D");
                const visaExpDateMonth =
                    moment(travelDocVisaExpDate).format("MMMM");
                const visaExpDateYear =
                    moment(travelDocVisaExpDate).format("YYYY");
                data = {
                    writtenAt,
                    applicationDateDay,
                    applicationDateMonth,
                    applicationDateYear,
                    familyName,
                    firstName,
                    middleName,
                    nationality,
                    age,
                    dobDay,
                    dobMonth: dobMonthShort,
                    dobYear,
                    stateOfBirth,
                    countryOfBirth,
                    occupation,
                    thaiAddressNumber,
                    thaiAddressRoad,
                    thaiAddressSubdistrict,
                    thaiAddressDistrict,
                    thaiAddressProvince,
                    singleReEntry: tm8PermitType === "single" ? "X" : "",
                    multipleReEntry: tm8PermitType === "multiple" ? "X" : "",
                    intendedDestination: tm8IntendedDestination,
                    departureDateDay,
                    departureDateMonth,
                    departureDateYear,
                    modeOfTransportation: tm8ModeOfTransportation,
                    purposeOfVisa: tm8PurposeOfVisa,
                    returnDateDay,
                    returnDateMonth,
                    returnDateYear,
                    travelDocIssuingCountry,
                    travelDocIssuedBy,
                    travelDocNumber,
                    travelDocIssueDateDay,
                    travelDocIssueDateMonth,
                    travelDocIssueDateYear,
                    travelDocExpDateDay,
                    travelDocExpDateMonth,
                    travelDocExpDateYear,
                    visaTypeNonImm: travelDocTypeOfVisa
                        .toLowerCase()
                        .includes("non-imm")
                        ? "X"
                        : "",
                    visaTypeTourist:
                        travelDocTypeOfVisa.toLowerCase() === "tourist"
                            ? "X"
                            : "",
                    visaTypeTransit:
                        travelDocTypeOfVisa.toLowerCase() === "transit"
                            ? "X"
                            : "",
                    visaTypeOther:
                        travelDocTypeOfVisa.toLowerCase() === "other"
                            ? "X"
                            : "",
                    visaIssuedAt: travelDocVisaIssuedAt,
                    visaIssueDateDay,
                    visaIssueDateMonth,
                    visaIssueDateYear,
                    arrivalDateDay,
                    arrivalDateMonth,
                    arrivalDateYear,
                    visaExpDateDay,
                    visaExpDateMonth,
                    visaExpDateYear,
                };
            }
            const response = await API.post(
                "thaiFormalityAPI",
                `${isDemo ? "/form-demo" : "/form"}`,
                {
                    body: {
                        formId,
                        data: JSON.stringify(data),
                        userId: impersonating.id ? impersonating.id : userId,
                        isDemo,
                        isSubscribed,
                    },
                }
            );
            console.warn(
                "Fix this so that you get the info in the response, and don't download the whole list."
            );
            dispatch(
                getSavedUserDocuments(
                    impersonating.id ? impersonating.id : userId
                )
            );
            dispatch({ type: FormDataAction.FILL_FORM_SUCCESS });
            return Promise.resolve(response.url);
        } catch (error) {
            dispatch({ type: FormDataAction.FILL_FORM_FAIL, payload: error });
            return Promise.reject(error);
        }
    };

// export const getFormFields = (formId) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: "GET_FORM_DATA_START" });
//     const response = await API.get("thaiFormalityAPI", `/form?id=${formId}`);
//     console.warn(response);
//     dispatch({ type: "GET_FORM_DATA_SUCCESS", payload: response });
//     return Promise.resolve();
//   } catch (error) {
//     console.warn(error);
//     dispatch({ type: "GET_FORM_DATA_FAIL", payload: error });
//     return Promise.reject(error);
//   }
// };

// export const getUserFormData = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: "GET_USER_FORM_DATA_START" });
//     const response = await API.get("thaiFormalityAPI", `/users/object/${id}`);
//     if (response.id) {
//       const payload = omit(response, ["id"]);
//       dispatch({ type: "GET_USER_FORM_DATA_SUCCESS", payload });
//     }
//     return Promise.resolve();
//   } catch (error) {
//     dispatch({ type: "GET_USER_FORM_DATA_FAIL", payload: error });
//     return Promise.reject(error);
//   }
// };

export const saveFormData =
    (payload: {
        name: string;
        data: Record<string, any>;
    }): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: FormDataAction.SAVE_FORM_DATA_START });
            const formData = getState().formData;
            const {
                id: currentUserId,
                attributes: { "custom:companyId": employeeCompanyId },
            } = getState().auth;
            const { impersonating } = getState().user;
            type Input = {
                id: string;
                userId: string;
                name: string;
                data: string;
                companyId?: string;
            };
            if (payload.name !== "applicationDetailsFormData") {
                const input: Input = {
                    id: `${
                        impersonating.id ? impersonating.id : currentUserId
                    }_${payload.name}`,
                    userId: impersonating.id ? impersonating.id : currentUserId,
                    name: payload.name,
                    data: JSON.stringify(payload.data),
                };
                if (employeeCompanyId) {
                    input.companyId = employeeCompanyId;
                }
                const formExists =
                    !isEmptyObject(formData) &&
                    !formData[payload.name as keyof typeof formData].isNew;
                if (formExists) {
                    console.log(`💾 Updating form: ${payload.name}`);
                    await API.graphql(
                        graphqlOperation(mutations.updateFormalityForm, {
                            input,
                        })
                    );
                    console.log(`💾 Form updated: ${payload.name}`);
                } else {
                    console.log(`💾 Creating new form: ${payload.name}`);
                    await API.graphql(
                        graphqlOperation(mutations.createFormalityForm, {
                            input,
                        })
                    );
                    console.log(`💾 New form saved: ${payload.name}`);
                }
                // const formData = data.data.updateFormalityForm;
                // console.warn(formData);
            }
            dispatch({
                type: FormDataAction.SAVE_FORM_DATA_SUCCESS,
                payload: { formName: [payload.name], data: payload.data },
            });
            return Promise.resolve();
        } catch (error: any) {
            console.warn(error);
            if (error.errors) {
                dispatch({
                    type: FormDataAction.SAVE_FORM_DATA_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(
                    new Error(
                        `Failed to update user: ${error.errors[0].message}`
                    )
                );
            } else {
                dispatch({
                    type: FormDataAction.SAVE_FORM_DATA_FAIL,
                    payload: error,
                });
                return Promise.reject(error);
            }
        }
    };
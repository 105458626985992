import { API } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { PayPalAction } from "../types";

export const getPaypalToken = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PayPalAction.GET_PAYPAL_TOKEN_START });
        const {
            auth: { isDemo },
            paypal: { accessToken: currentToken, tokenExpires: expiresIn },
        } = getState();
        if (isDemo) {
            dispatch({
                type: PayPalAction.GET_PAYPAL_TOKEN_FAIL,
                payload: new Error("App is in Demo mode"),
            });
            return Promise.resolve(null);
        }
        if (!currentToken || moment(expiresIn).isSameOrBefore(moment())) {
            const response = await API.get("thaiFormalityAPI", `/paypal-token`);
            const tokenExpires = moment()
                .add(response["expires_in"], "seconds")
                .format();
            dispatch({
                type: PayPalAction.GET_PAYPAL_TOKEN_SUCCESS,
                payload: {
                    accessToken: response["access_token"],
                    tokenExpires,
                },
            });
            return Promise.resolve(response["access_token"]);
        }
        dispatch({
            type: PayPalAction.GET_PAYPAL_TOKEN_SUCCESS,
            payload: {},
        });
        return Promise.resolve(currentToken);
    } catch (error) {
        dispatch({ type: PayPalAction.GET_PAYPAL_TOKEN_FAIL, payload: error });
        return Promise.reject(error);
    }
};

export const getPaypalTransactions = () => async (dispatch) => {
    try {
        const endTime = moment().toISOString();
        const startTime = moment().subtract(1, "year").toISOString();
        const accessToken = await dispatch(getPaypalToken());
        dispatch({
            type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_START,
        });
        const config = {
            method: "get",
            url: `https://api.${
                process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
            }paypal.com/v1/reporting/transactions?start_date=${startTime}&end_date=${endTime}`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const { data } = await axios(config);
        dispatch({
            type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_SUCCESS,
        });
        return Promise.resolve(data);
    } catch (error) {
        dispatch({
            type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_FAIL,
            payload: error,
        });
        return Promise.reject(error);
    }
};

export const cancelPaypalSubscriptionHateLink =
    (url) => async (dispatch, getState) => {
        const accessToken = await dispatch(getPaypalToken());
        const config = {
            method: "post",
            url: url,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const { data } = await axios(config);
        console.warn(data);
    };
export const cancelPaypalSubscription =
    (id, reason) => async (dispatch, getState) => {
        try {
            const {
                auth: {
                    attributes: { "custom:role": role },
                },
                subscription: { paypalSubscriptionId: companySubscriptionId },
                user: { paypalSubscriptionId: userSubscriptionId },
            } = getState();
            const subscriptionId = id
                ? id
                : role === "manager"
                ? companySubscriptionId
                : userSubscriptionId;
            const accessToken = await dispatch(getPaypalToken());
            dispatch({ type: PayPalAction.CANCEL_PAYPAL_SUBSCRIPTION_START });
            const config = {
                method: "post",
                url: `https://api.${
                    process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
                }paypal.com/v1/billing/subscriptions/${subscriptionId}/cancel`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: {
                    reason: reason ? reason : "no reason provided",
                },
            };
            const { data } = await axios(config);
            dispatch({ type: PayPalAction.CANCEL_PAYPAL_SUBSCRIPTION_SUCCESS });
            return Promise.resolve(data);
        } catch (error) {
            dispatch({
                type: PayPalAction.CANCEL_PAYPAL_SUBSCRIPTION_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const getPaypalSubscriptionDetails =
    (id) => async (dispatch, getState) => {
        try {
            const {
                auth: {
                    attributes: { "custom:role": role },
                },
                subscription: { paypalSubscriptionId: companySubscriptionId },
                user: { paypalSubscriptionId: userSubscriptionId },
            } = getState();
            const subscriptionId = id
                ? id
                : role === "manager"
                ? companySubscriptionId
                : userSubscriptionId;
            const accessToken = await dispatch(getPaypalToken());
            dispatch({
                type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_DETAILS_START,
            });
            const config = {
                method: "get",
                url: `https://api.${
                    process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
                }paypal.com/v1/billing/subscriptions/${subscriptionId}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            const { data } = await axios(config);
            // console.warn("Paypal subscription details", data);
            dispatch({
                type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_DETAILS_SUCCESS,
            });
            return Promise.resolve(data);
        } catch (error) {
            dispatch({
                type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_DETAILS_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const getPaypalSubscriptionTransactions =
    (subscriptionId, dateRange) => async (dispatch, getState) => {
        try {
            const endTime = moment().toISOString();
            const startTime = moment().subtract(1, "year").toISOString();
            const accessToken = await dispatch(getPaypalToken());
            dispatch({
                type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_START,
            });
            const config = {
                method: "get",
                url: `https://api.${
                    process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
                }paypal.com/v1/billing/subscriptions/${subscriptionId}/transactions?start_time=${startTime}&end_time=${endTime}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            const { data } = await axios(config);
            // console.warn("Paypal subscription transactions", data);
            dispatch({
                type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_SUCCESS,
            });
            return Promise.resolve(data);
        } catch (error) {
            dispatch({
                type: PayPalAction.GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };
export const paypalSubscriptionIsActive =
    (id) => async (dispatch, getState) => {
        try {
            dispatch({
                type: PayPalAction.CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_START,
            });
            const {
                auth: {
                    attributes: { "custom:role": role, isDemo },
                },
                subscription: {
                    paymentMethod: companyPaymentMethod,
                    paypalSubscriptionId: companySubscriptionId,
                },
                user: {
                    paymentMethod: userPaymentMethod,
                    paypalSubscriptionId: userSubscriptionId,
                },
            } = getState();
            if (isDemo) {
                dispatch({
                    type: PayPalAction.CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_SUCCESS,
                });
                return Promise.resolve(false);
            }
            const accessToken = await dispatch(getPaypalToken());

            if (id) {
                const config = {
                    method: "get",
                    url: `https://api.${
                        process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
                    }paypal.com/v1/billing/subscriptions/${id}`,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
                const { data } = await axios(config);
                console.warn("isActive", data["status"] === "ACTIVE");
                dispatch({
                    type: PayPalAction.CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_SUCCESS,
                });
                return Promise.resolve(
                    data["status"] === "ACTIVE" ? true : false
                );
            } else if (
                (role === "manager" &&
                    companyPaymentMethod === "payPal" &&
                    companySubscriptionId) ||
                (!role && userPaymentMethod === "payPal" && userSubscriptionId)
            ) {
                const config = {
                    method: "get",
                    url: `https://api.${
                        process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
                    }paypal.com/v1/billing/subscriptions/${
                        role === "manager"
                            ? companySubscriptionId
                            : userSubscriptionId
                    }`,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
                const { data } = await axios(config);
                dispatch({
                    type: PayPalAction.CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_SUCCESS,
                });
                return Promise.resolve(
                    data["status"] === "ACTIVE" ? true : false
                );
            }
            return Promise.resolve(false);
        } catch (error) {
            dispatch({
                type: PayPalAction.CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_FAIL,
                payload: error,
            });
            console.warn("Fix this!: ", error.response || error);
            const message = error.response
                ? error.response.data.message
                : error.message;
            if (message.includes("specified resource does not exist")) {
                return Promise.resolve(null);
            } else {
                return Promise.reject(error);
            }
        }
    };

export const approveCompanyPaypalSubscriptionRevision =
    (url) => async (dispatch, getState) => {
        try {
            dispatch({
                type: PayPalAction.APPROVE_COMPANY_PAYPAL_SUBSCRIPTION_REVISION_START,
            });
            const accessToken = await dispatch(getPaypalToken());
            const config = {
                method: "get",
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            const { data } = await axios(config);
            console.warn(data);
            dispatch({
                type: PayPalAction.APPROVE_COMPANY_PAYPAL_SUBSCRIPTION_REVISION_SUCCESS,
            });
            return Promise.resolve(data);
        } catch (error) {
            dispatch({
                type: PayPalAction.APPROVE_COMPANY_PAYPAL_SUBSCRIPTION_REVISION_FAIL,
                payload: error.message,
            });
            return Promise.reject(error);
        }
    };

export const getReviseCompanyPaypalSubscriptionLink =
    (revisionDirection) => async (dispatch, getState) => {
        try {
            dispatch({
                type: PayPalAction.GET_REVISE_COMPANY_PAYPAL_SUBSCRIPTION_LINK_START,
            });
            const LE_KA_PLAN =
                process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_LE_KA;
            const BIG_BOSS_PLAN =
                process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_BIG_BOSS;
            const {
                subscription: { paypalSubscriptionId },
            } = getState();
            const accessToken = await dispatch(getPaypalToken());
            const newPlan =
                revisionDirection === "upgrade" ? BIG_BOSS_PLAN : LE_KA_PLAN;
            const config = {
                method: "post",
                url: `https://api.${
                    process.env.USER_BRANCH !== "prod" ? "sandbox." : ""
                }paypal.com/v1/billing/subscriptions/${paypalSubscriptionId}/revise`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: { plan_id: newPlan },
            };
            const { data } = await axios(config);
            console.warn("Paypal subscription upgrade details", data);
            dispatch({
                type: PayPalAction.GET_REVISE_COMPANY_PAYPAL_SUBSCRIPTION_LINK_SUCCESS,
            });
            return Promise.resolve(data);
        } catch (error) {
            dispatch({
                type: PayPalAction.GET_REVISE_COMPANY_PAYPAL_SUBSCRIPTION_LINK_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

import { useContext } from "react";
import { checkCompanyExists } from "../actions/subscriptionActions";
import { companyRegistrationFormSchema } from "../validators";
import { FormalityAction, SubscriptionAction } from "../types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Spinner } from "./shared/Spinner";
import { ThaiAddressSelector } from "./ThaiAddressSelector";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { Button } from "react-bootstrap";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    selectedPlan: string;
}
export const RegistrationFormCompany = ({ selectedPlan }: Props) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        id: currentUserId,
        attributes: { email },
        isDemo,
    } = useAppSelector((state) => state.auth);
    const data = useAppSelector((state) => state.subscription);

    if (isDev) {
        console.log(
            "%cContact first name and family name may not be write!",
            "color:hotpink"
        );
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                companyName: data && data.companyName ? data.companyName : "",
                companyNumber:
                    data && data.companyNumber ? data.companyNumber : "",
                contactFamilyName: data?.contactFamilyName || "",
                contactFirstName: data?.contactFirstName || "",
                contactEmail: email || "Something's not right...",
                paymentMethod:
                    data && data.paymentMethod ? data.paymentMethod : "",
                phone: data && data.phone ? data.phone : "",
                addressNumber:
                    data && data.addressNumber ? data.addressNumber : "",
                road: data && data.road ? data.road : "",
                subdistrict: data && data.subdistrict ? data.subdistrict : "",
                district: data && data.district ? data.district : "",
                province: data && data.province ? data.province : "",
                postalCode: data && data.postalCode ? data.postalCode : "",
                subscriptionPlan: selectedPlan,
            }}
            validationSchema={companyRegistrationFormSchema}
            onSubmit={async (values, actions) => {
                try {
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: null,
                    });
                    actions.setSubmitting(true);
                    const companyExists = await dispatch(
                        checkCompanyExists(values.companyNumber)
                    );
                    if (companyExists) {
                        dispatch({
                            type: FormalityAction.SET_USER_ALERT,
                            payload: {
                                alertType: "primary",
                                message:
                                    "A company is already been registered with that TIN",
                                emoticon: "",
                                title: "Problem registering company",
                            },
                        });
                        return;
                    }
                    // await dispatch(saveCompanyRegistrationInformation(values));
                    dispatch({
                        type: SubscriptionAction.UPDATE_COMPANY_SUBSCRIPTION,
                        payload: {
                            ...values,
                            id: values.companyNumber,
                            contactId: currentUserId,
                        },
                    });
                    navigate("/paypal-payment");
                } catch (error: any) {
                    console.warn(error);
                    actions.setSubmitting(false);
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: {
                            alertType: "primary",
                            message:
                                typeof error === "string"
                                    ? error
                                    : error.message,
                            emoticon: "",
                            title: "Problem Registering Company",
                        },
                    });
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldValue,
                touched,
            }) => (
                <Form className="container" noValidate>
                    <div className="display text-center text-muted mb-3">
                        Company Registration
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="companyName"
                                    className="form-label"
                                >
                                    Company Name
                                </label>
                                <Field
                                    autoFocus
                                    className={`form-control text-left ${
                                        errors.companyName &&
                                        touched.companyName
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="companyName"
                                    name="companyName"
                                    placeholder="ACME Limited"
                                    tabIndex="1"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="companyName" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="companyNumber"
                                    className="form-label"
                                >
                                    Company TIN
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.companyNumber &&
                                        touched.companyNumber
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="companyNumber"
                                    name="companyNumber"
                                    placeholder="0123456789"
                                    tabIndex="2"
                                    spellCheck="false"
                                    type="companyNumber"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="companyNumber" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="contactName"
                                    className="form-label"
                                >
                                    Main Contact
                                </label>
                                <div
                                    className="d-flex justify-content-between"
                                    id="contactName"
                                >
                                    <div className="flex-fill d-flex flex-column">
                                        <Field
                                            className={`form-control text-left ${
                                                errors.contactFirstName &&
                                                touched.contactFirstName
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            id="contactFirstName"
                                            name="contactFirstName"
                                            placeholder="Supicha"
                                            tabIndex="3"
                                            spellCheck="false"
                                            type="text"
                                        />
                                        <div className="formikErrorMessage text-left">
                                            <ErrorMessage name="contactFirstName" />
                                        </div>
                                    </div>
                                    <div className="flex-fill d-flex flex-column ms-1">
                                        <Field
                                            className={`form-control text-left ${
                                                errors.contactFamilyName &&
                                                touched.contactFamilyName
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            id="contactFamilyName"
                                            name="contactFamilyName"
                                            placeholder="Jones"
                                            tabIndex="4"
                                            spellCheck="false"
                                            type="text"
                                        />
                                        <div className="formikErrorMessage text-left">
                                            <ErrorMessage name="contactFamilyName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="contactEmail"
                                    className="form-label"
                                >
                                    Contact Email
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.contactEmail &&
                                        touched.contactEmail
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="contactEmail"
                                    name="contactEmail"
                                    placeholder="K. Supicha"
                                    tabIndex="-1"
                                    spellCheck="false"
                                    type="email"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="contactEmail" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="phone" className="form-label">
                                    Phone
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.phone && touched.phone
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="phone"
                                    name="phone"
                                    placeholder="+66891231234"
                                    tabIndex="5"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="phone" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="subscriptionPlan"
                                    className="form-label"
                                >
                                    Subscription Plan
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.subscriptionPlan &&
                                        touched.subscriptionPlan
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="subscriptionPlan"
                                    name="subscriptionPlan"
                                    spellCheck="false"
                                    tabIndex="-1"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="subscriptionPlan" />
                                </div>
                                <div className="form-group mb-2">
                                    <label
                                        htmlFor="paymentMethod"
                                        className="form-label mt-0"
                                    >
                                        Payment Method
                                    </label>
                                    <Field
                                        as="select"
                                        className={`form-select text-left ${
                                            errors.paymentMethod &&
                                            touched.paymentMethod
                                                ? "is-invalid"
                                                : ""
                                        }`}
                                        value={
                                            !values.paymentMethod
                                                ? data.paymentMethod
                                                : values.paymentMethod
                                        }
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        tabIndex="6"
                                        type="text"
                                    >
                                        <option value="">
                                            Select Payment Method
                                        </option>
                                        <option value="bankTransfer" disabled>
                                            Bank Transfer
                                        </option>
                                        <option value="payPal">PayPal</option>
                                        <option value="other" disabled>
                                            Other
                                        </option>
                                    </Field>
                                    <div className="formikErrorMessage">
                                        <ErrorMessage name="paymentMethod" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm">
                            <ThaiAddressSelector
                                formDestination={"registration"}
                                tabIndex="7"
                                values={values}
                            />
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="addressNumber"
                                    className="form-label"
                                >
                                    Address Number
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.addressNumber &&
                                        touched.addressNumber
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="addressNumber"
                                    name="addressNumber"
                                    placeholder="122/3"
                                    tabIndex="8"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="addressNumber" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="road" className="form-label">
                                    Road
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.road && touched.road
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="road"
                                    name="road"
                                    placeholder="Soi 12"
                                    tabIndex="9"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="road" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="subdistrict"
                                    className="form-label"
                                >
                                    Subdistrict (
                                    <span className="font-italic">Tambol</span>/
                                    <span className="font-italic">Khwaeng</span>
                                    )
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.subdistrict &&
                                        touched.subdistrict
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="subdistrict"
                                    name="subdistrict"
                                    placeholder="Changklang"
                                    tabIndex="10"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="subdistrict" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="district"
                                    className="form-label"
                                >
                                    District (
                                    <span className="font-italic">Amphoe</span>/
                                    <span className="font-italic">Khet</span>)
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.district && touched.district
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="district"
                                    name="district"
                                    placeholder="Muang"
                                    tabIndex="11"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="district" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="province"
                                    className="form-label"
                                >
                                    Province (
                                    <span className="font-italic">
                                        Changwat
                                    </span>
                                    )
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.province && touched.province
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="province"
                                    name="province"
                                    placeholder="Chiang Mai"
                                    tabIndex="12"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="province" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="postalCode"
                                    className="form-label"
                                >
                                    Postal Code
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.postalCode && touched.postalCode
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="postalCode"
                                    name="postalCode"
                                    placeholder="50140"
                                    tabIndex="13"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="postalCode" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <Button
                            className="d-flex align-items-center justify-content-center"
                            disabled={isSubmitting}
                            style={{ height: "40px", width: "96px" }}
                            type="submit"
                            variant={getButtonColor("primary", isDark)}
                        >
                            {isSubmitting ? (
                                <Spinner
                                    size={10}
                                    color={getSpinnerColor("primary", isDark)}
                                    type="beat"
                                />
                            ) : (
                                <span>Register</span>
                            )}
                        </Button>
                        <Button
                            className="custom-cancel-button ms-2"
                            onClick={() => {
                                dispatch({
                                    type: SubscriptionAction.STOP_SUBSCRIPTION_PROCESS,
                                });
                                navigate(-1);
                            }}
                            style={{ height: "40px", width: "96px" }}
                            variant="link"
                        >
                            cancel
                        </Button>
                    </div>
                    {isDemo ? (
                        <small className="text-danger">
                            Registration is disabled in demo
                        </small>
                    ) : null}
                </Form>
            )}
        </Formik>
    );
};

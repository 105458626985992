export enum UserAction {
    GET_FORMALITY_USER_FAIL = "get_formality_user_fail",
    GET_FORMALITY_USER_START = "get_formality_user_start",
    GET_FORMALITY_USER_SUCCESS = "get_formality_user_success",
    SAVE_SUBSCRIPTION_FORM_VALUES = "save_subscription_form_values",
    SET_CALENDAR_VIEW = "set_calendar_view",
    SET_EMPLOYEE_TO_DISCONNECT = "set_employee_to_disconnect",
    SET_IMPERSONATING = "set_impersonating",
    STOP_SUBSCRIPTION_PROCESS = "stop_subscription_process",
    UPDATE_USER = "update_user",
}

export type UserState = {
    addressNumber: string;
    companyId: string;
    cookiesAccepted: boolean;
    district: string;
    email: string;
    employeeToDisconnect: any;
    familyName: string;
    firstName: string;
    impersonating: any;
    myCalendarEvents: any[];
    myEmployees: any[];
    paypalSubscriptionId: string;
    phone: string;
    postalCode: string;
    province: string;
    road: string;
    showCalendar: boolean;
    subdistrict: string;
    subscriptionPlan: string;
}

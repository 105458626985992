import { useState } from "react";
import $ from "jquery";
import { API } from "aws-amplify";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Spinner } from "./shared/Spinner";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { FormDataAction, SubscriptionAction } from "../types";

export const ThaiAddressSelector = ({
    formDestination,
    tabIndex = "-1",
    values,
}) => {
    const dispatch = useAppDispatch();
    const { isDemo } = useAppSelector((state) => state.auth);
    const { thaiAddressFormData } = useAppSelector((state) => state.formData);
    const [isSpinning, setIsSpinning] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState([]);

    const handleChange = (selected) => {
        setSelectedAddress(selected);
        const address = selected[0];
        let payload = {};
        if (address) {
            payload =
                formDestination === "thaiAddressForm"
                    ? {
                          thaiAddressSubdistrict: address.district_en,
                          thaiAddressDistrict: address.amphoe_en,
                          thaiAddressProvince: address.province_en,
                          thaiAddressPostalCode: address.postalCode,
                      }
                    : {
                          subdistrict: address.district_en,
                          district: address.amphoe_en,
                          province: address.province_en,
                          postalCode: address.postalCode,
                      };
            if (formDestination === "thaiAddressForm") {
                payload.isNew = thaiAddressFormData.thaiAddressSubdistrict
                    ? false
                    : true;
                dispatch({
                    type: FormDataAction.SET_THAI_ADDRESS_SEARCH_DATA,
                    payload,
                });
                $("#thaiAddressNumber").focus();
            } else if (formDestination === "registration") {
                dispatch({
                    type: SubscriptionAction.SET_REGISTRATION_ADDRESS,
                    payload: { ...values, ...payload },
                });
                $("#addressNumber").focus();
            }
        }
    };

    const handleSearch = async (postalCode) => {
        try {
            setIsSpinning(true);
            const response = await API.post(
                "thaiFormalityAPI",
                `/thai-postalcode-lookup${isDemo ? "-demo" : ""}`,
                {
                    body: {
                        postalCode: postalCode,
                        lang: "en",
                    },
                }
            );
            setOptions(response ? response : []);
            setIsSpinning(false);
        } catch (error) {
            setIsSpinning(false);
            console.warn(
                error.response ? error.response.data.error.message : error
            );
        }
    };

    return (
        <div className="w-100">
            <div className="form-group w-100">
                <label
                    htmlFor="thai-postal-code-search-input"
                    className="form-label mt-0"
                >
                    Fill by Postal Code
                </label>
                <div className="input-group w-100">
                    <AsyncTypeahead
                        ariaDescribedBy="thaiAddressHelper"
                        clearButton
                        id="thai-postal-code-search-input"
                        inputProps={{
                            maxLength: 5,
                            disabled: selectedAddress.length,
                            tabIndex,
                        }}
                        isLoading={isSpinning}
                        labelKey={(option) =>
                            `${option.district_en}, ${option.amphoe_en}, ${option.province_en}  ${option.postalCode}`
                        }
                        minLength={5}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        options={options}
                        placeholder="e.g. 50200"
                        renderMenuItemChildren={(option) => (
                            <li className="list-group item">
                                <div>{option.district_en}</div>
                                <small className="text-muted">
                                    {option.amphoe_en}, {option.province_en}
                                    {"  "}
                                    {option.postalCode}
                                </small>
                            </li>
                        )}
                        selected={selectedAddress}
                    />
                    {isSpinning && (
                        <div className="my-rbt-aux">
                            <Spinner size={8} color="primary" type="beat" />
                        </div>
                    )}
                </div>
                {/* <small className="form-text text-muted" id="thai-address-helper">
          Optional, yet helpful
        </small> */}
            </div>
        </div>
    );
};

export enum ManagerAction {
    CLEAN_PENDING_EMPLOYEES_FAIL = "clean_pending_employees_fail",
    CLEAN_PENDING_EMPLOYEES_START = "clean_pending_employees_start",
    CLEAN_PENDING_EMPLOYEES_SUCCESS = "clean_pending_employees_success",
    CREATE_EMPLOYEE_CALENDAR_EVENTS_FAIL = "create_employee_calendar_events_fail",
    CREATE_EMPLOYEE_CALENDAR_EVENTS_START = "create_employee_calendar_events_start",
    CREATE_EMPLOYEE_CALENDAR_EVENTS_SUCCESS = "create_employee_calendar_events_success",
    DISCONNECT_ALL_EMPLOYEES_FROM_COMPANY_FAIL = "disconnect_all_employees_from_company_fail",
    DISCONNECT_ALL_EMPLOYEES_FROM_COMPANY_START = "disconnect_all_employees_from_company_start",
    DISCONNECT_ALL_EMPLOYEES_FROM_COMPANY_SUCCESS = "disconnect_all_employees_from_company_success",
    GET_COMPANY_DATA_FAIL = "get_company_data_fail",
    GET_COMPANY_DATA_START = "get_company_data_start",
    GET_COMPANY_DATA_SUCCESS = "get_company_data_success",
    GET_COMPANY_EMPLOYEES_FAIL = "get_company_employees_fail",
    GET_COMPANY_EMPLOYEES_START = "get_company_employees_start",
    GET_COMPANY_EMPLOYEES_SUCCESS = "get_company_employees_success",
    GET_COMPANY_FORM_DATA_FAIL = "get_company_form_data_fail",
    GET_COMPANY_FORM_DATA_START = "get_company_form_data_start",
    GET_COMPANY_FORM_DATA_SUCCESS = "get_company_form_data_success",
    GET_COMPANY_MANAGER_DATA_FAIL = "get_company_manager_data_fail",
    GET_COMPANY_MANAGER_DATA_START = "get_company_manager_data_start",
    GET_COMPANY_MANAGER_DATA_SUCCESS = "get_company_manager_data_success",
    GET_EMPLOYEE_FORM_DATA_FAIL = "get_employee_form_data_fail",
    GET_EMPLOYEE_FORM_DATA_START = "get_employee_form_data_start",
    GET_EMPLOYEE_FORM_DATA_SUCCESS = "get_employee_form_data_success",
    REMOVE_DISCONNECTED_EMPLOYEE = "remove_disconnected_employee",
    SEND_EMPLOYEE_INVITE_FAIL = "send_employee_invite_fail",
    SEND_EMPLOYEE_INVITE_START = "send_employee_invite_start",
    SEND_EMPLOYEE_INVITE_SUCCESS = "send_employee_invite_success",
}
export type CalendarEvent = {
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    resource: any;
};

export type EmployeeInviteData = {
    companyId: string;
    familyName: string;
    firstName: string;
    from: string;
    url: string;
    username: string;
};

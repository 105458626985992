import { createAction, createReducer } from "@reduxjs/toolkit";
import { AuthAction, PayPalAction, PayPalState } from "../types";

const initialState: PayPalState = {
    accessToken: "",
    tokenExpires: "",
};

const get_paypal_token_success = createAction<{
    accessToken: string;
    tokenExpires: string;
}>(PayPalAction.GET_PAYPAL_TOKEN_SUCCESS);

const signOut = createAction<null>(AuthAction.SIGN_OUT_SUCCESS);

const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(get_paypal_token_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(signOut, () => {
            return initialState;
        });
});
export default reducer;

import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    children: JSX.Element | JSX.Element[];
}
export const PublicRoute = ({ children }: Props) => {
    const { id, attributes, isDemo } = useAppSelector((state) => state.auth);
    const { impersonating } = useAppSelector((state) => state.user);
    const isAuthenticated = !!id;
    const isManager = attributes && attributes["custom:role"] === "manager";
        if (isDev) {
            console.log(
                `%cIs Manager?: ${isManager}`,
                "color:cyan"
            );
        }
    const location = useLocation();
    const getRoute = () => {
        if (!isManager) {
            return "/forms";
        } else {
            if (impersonating.id) {
                return location.pathname;
            }
            return "/my-employees";
        }
    };
    if (isAuthenticated) {
        const route = getRoute();
        if (isDev) {
            console.log(
                `%cRerouting to ${route} in Public Route`,
                "color:cyan"
            );
        }
        return (
            <Navigate
                to={{
                    pathname: route,
                }}
                replace
                state={{ from: location }}
            />
        );
    } else {
        if (isDev) {
            console.log(
                `%cShowing Public Route ${isDemo ? "(Demo)" : ""}`,
                "color:cyan"
            );
        }
        return children;
    }

    // return (
    //     <Route
    //         {...rest}
    //         render={({ location }) =>
    //             !isAuthenticated ? (
    //                 children
    //             ) : (
    //                 <Navigate
    //                     to={{
    //                         // pathname: isManager ? "/my-employees" : "/forms",
    //                         pathname: getRoute(),
    //                         state: { from: location },
    //                     }}
    //                     replace
    //                 />
    //             )
    //         }
    //     />
    // );
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../components/ErrorAlert";
import { Footer } from "../components/shared/Footer";
import { LinkPaypalSubscriptionConfirmation } from "../components/LinkPaypalSubscriptionConfirmation";
import { LinkPaypalSubscriptionForm } from "../components/LinkPaypalSubscriptionForm";
import { RegistrationFormCompany } from "../components/RegistrationFormCompany";
import { RegistrationFormIndividual } from "../components/RegistrationFormIndividual";
import { SubscriptionPlanSelector } from "../components/SubscriptionPlanSelector";
import { LinkPaypalSubscriptionConfirmationFormCompany } from "../components/LinkPaypalSubscriptionConfirmationFormCompany";
import { LinkPaypalSubscriptionConfirmationFormIndividual } from "../components/LinkPaypalSubscriptionConfirmationFormIndividual";
import { useWindowSize } from "../hooks/useWindowSize";
import { useAppSelector } from "../hooks/reduxHooks";

interface Props {
    navbarOffset: number;
}
export const RegistrationPage = ({ navbarOffset }: Props) => {
    const navigate = useNavigate();
    const [, width] = useWindowSize();
    const {
        attributes: { "custom:role": role },
    } = useAppSelector((state) => state.auth);
    const { isSubscribing, alert, selectedPlan, subscribingAs } =
        useAppSelector((state) => state.formality);

    const [isLinking, setIsLinking] = useState(false);
    const [linkingAs, setLinkingAs] = useState("");
    const [subscriptionData, setSubscriptionData] = useState("");

    useEffect(() => {
        if (role === "manager") {
            navigate("/my-employees");
        }
    }, [navigate, role]);

    const renderContent = () => {
        if (isSubscribing) {
            return subscribingAs === "company" ? (
                <RegistrationFormCompany selectedPlan={selectedPlan} />
            ) : (
                <RegistrationFormIndividual selectedPlan={selectedPlan} />
            );
        } else if (isLinking && !subscriptionData && !linkingAs) {
            return (
                <LinkPaypalSubscriptionForm
                    setSubscriptionData={setSubscriptionData}
                    setIsLinking={setIsLinking}
                />
            );
        } else if (isLinking && subscriptionData && !linkingAs) {
            return (
                <LinkPaypalSubscriptionConfirmation
                    setIsLinking={setIsLinking}
                    setLinkingAs={setLinkingAs}
                    subscriptionData={subscriptionData}
                    setSubscriptionData={setSubscriptionData}
                />
            );
        } else if (isLinking && subscriptionData && linkingAs) {
            if (linkingAs === "company") {
                return (
                    <LinkPaypalSubscriptionConfirmationFormCompany
                        subscriptionData={subscriptionData}
                    />
                );
            } else {
                return (
                    <LinkPaypalSubscriptionConfirmationFormIndividual
                        subscriptionData={subscriptionData}
                    />
                );
            }
        }
        return <SubscriptionPlanSelector />;
    };

    return (
        <>
            <div
                className={`container d-flex flex-column align-items-center ${
                    width >= 576
                        ? "center-vertical-with-nav"
                        : "center-vertical-with-nav"
                }`}
                style={{ paddingTop: navbarOffset }}
            >
                <div
                    className="mt-3 mt-sm-5 w-100"
                    // style={{ marginBottom: -60 }}
                ></div>
                <div className="d-flex flex-column justify-content-center flex-grow-1 w-100 mb-3">
                    <div className="d-flex justify-content-center">
                        {renderContent()}
                    </div>
                    {!isLinking && !isSubscribing ? (
                        <div className="text-center">
                            <button
                                className="btn btn-link text-muted"
                                onClick={() => setIsLinking(true)}
                            >
                                Link Existing Subscription
                            </button>
                        </div>
                    ) : null}
                </div>
                <Footer />
            </div>
            {alert ? (
                <div className="fixed-bottom">
                    <ErrorAlert />
                </div>
            ) : null}
        </>
    );
};

import { RequiredFormDataItem } from "./forms";

export enum FormalityAction {
    CREATE_FORMALITY_USER_FAIL = "create_formality_user_fail",
    CREATE_FORMALITY_USER_START = "create_formality_user_start",
    CREATE_FORMALITY_USER_SUCCESS = "create_formality_user_success",
    DELETE_ALL_SAVED_DOCS_FAIL = "delete_all_saved_docs_fail",
    DELETE_ALL_SAVED_DOCS_START = "delete_all_saved_docs_start",
    DELETE_ALL_SAVED_DOCS_SUCCESS = "delete_all_saved_docs_success",
    DELETE_ALL_USER_FORMS_FAIL = "delete_all_user_forms_fail",
    DELETE_ALL_USER_FORMS_START = "delete_all_user_forms_start",
    DELETE_ALL_USER_FORMS_SUCCESS = "delete_all_user_forms_success",
    DELETE_FORMALITY_COMPANY_FAIL = "delete_formality_company_fail",
    DELETE_FORMALITY_COMPANY_START = "delete_formality_company_start",
    DELETE_FORMALITY_COMPANY_SUCCESS = "delete_formality_company_success",
    DELETE_FORMALITY_USER_FAIL="delete_formality_user_fail",
    DELETE_FORMALITY_USER_START="delete_formality_user_start",
    DELETE_FORMALITY_USER_SUCCESS="delete_formality_user_success",
    DELETE_SAVED_FORM_FAIL = "delete_saved_form_fail",
    DELETE_SAVED_FORM_START = "delete_saved_form_start",
    DELETE_SAVED_FORM_SUCCESS = "delete_saved_form_success",
    DOWNLOAD_SAVED_FORM_FAIL = "download_saved_form_fail",
    DOWNLOAD_SAVED_FORM_START = "download_saved_form_start",
    DOWNLOAD_SAVED_FORM_SUCCESS = "download_saved_form_success",
    FORMALITY_INIT_FAIL = "formality_init_fail",
    FORMALITY_INIT_START = "formality_init_start",
    FORMALITY_INIT_SUCCESS = "formality_init_success",
    GET_SAVED_USER_DOCS_FAIL = "get_saved_user_docs_fail",
    GET_SAVED_USER_DOCS_START = "get_saved_user_docs_start",
    GET_SAVED_USER_DOCS_SUCCESS = "get_saved_user_docs_success",
    PRINT_FORM_FAIL = "print_form_fail",
    PRINT_FORM_START = "print_form_start",
    PRINT_FORM_SUCCESS = "print_form_success",
    RESET_SAVED_USER_DOCS = "reset_saved_user_docs",
    SAVE_FORM_URL = "save_form_url",
    SEND_SUPPORT_MAIL_FAIL = "send_support_mail_fail",
    SEND_SUPPORT_MAIL_START = "send_support_mail_start",
    SEND_SUPPORT_MAIL_SUCCESS = "send_support_mail_success",
    SET_FORM_TO_UPLOAD = "set_form_to_upload",
    SET_FORM_URL = "set_form_url",
    SET_PRINT_ERROR = "set_print_error",
    SET_SELECTED_EVENT = "set_selected_event",
    SET_SELECTED_FORM = "set_selected_form",
    SET_USER_ALERT = "set_user_alert",
    UPLOAD_FORM_FAIL = "upload_form_fail",
    UPLOAD_FORM_START = "upload_form_start",
    UPLOAD_FORM_SUCCESS = "upload_form_success",
    UPDATE_FORMALITY_COMPANY_FAIL = "update_formality_company_fail",
    UPDATE_FORMALITY_COMPANY_START = "update_formality_company_start",
    UPDATE_FORMALITY_COMPANY_SUCCESS = "update_formality_company_success",
    UPDATE_FORMALITY_USER_FAIL = "update_formality_user_fail",
    UPDATE_FORMALITY_USER_START = "update_formality_user_start",
    UPDATE_FORMALITY_USER_SUCCESS = "update_formality_user_success",
    UPDATE_FORMALITY_USER_EMAIL_FAIL = "update_formality_user_email_fail",
    UPDATE_FORMALITY_USER_EMAIL_START = "update_formality_user_email_start",
    UPDATE_FORMALITY_USER_EMAIL_SUCCESS = "update_formality_user_email_success",
    VIEW_SAVED_FORM_FAIL = "view_saved_form_fail",
    VIEW_SAVED_FORM_START = "view_saved_form_start",
    VIEW_SAVED_FORM_SUCCESS = "view_saved_form_success",
}

export interface FormalityState {
    alert: FormalityAlert | undefined;
    // alert: {
    //   alertType: "danger",
    //   message: "Houston, there's been a problem.",
    //   emoticon: "",
    //   title: "Example Error"
    // },
    formToUpload: FormalityForm | undefined;
    formUrl: string;
    hasPrintError: boolean;
    hasUploadError: boolean;
    isLoading: boolean;
    isProcessing: boolean;
    isRegistered: boolean;
    isSubscribing: boolean;
    isUploading: boolean;
    printError: string;
    savedUserDocs: any[];
    selectedEvent: FormalityEvent | undefined;
    selectedForm: string;
    selectedPlan: string;
    subscribingAs: string;
}

export type FormalityEvent = {
    name: string;
    requiredForms: FormalityForm[];
};
export enum FormalityFormType {
    DEPENDENT = "dependent",
    FILL = "fill",
    UPLOAD = "upload",
}
export type FormalityForm = {
    name: string;
    shortName: string;
    title: string;
    printTitle: string;
    type: FormalityFormType;
    printable: boolean;
    requiredFormData: RequiredFormDataItem[];
};

export enum FormalityAlertType {
    DANGER = "danger",
    INFO = "info",
    PRIMARY = "primary",
    SUCCESS = "success",
    WARNING = "warning",
}
export type FormalityAlert = {
    alertType: FormalityAlertType;
    message: string;
    messageIsHTML?: boolean;
    emoticon: string;
    title: string;
};

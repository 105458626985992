import { createAction, createReducer } from "@reduxjs/toolkit";
import {
    AuthAction,
    SubscriptionAction,
    UserAction,
    type UserState,
} from "../types";
import { ManagerAction } from "../types/manager";

const initialState: UserState = {
    addressNumber: "",
    companyId: "",
    cookiesAccepted: true,
    district: "",
    email: "",
    employeeToDisconnect: {},
    familyName: "",
    firstName: "",
    impersonating: {},
    myCalendarEvents: [],
    myEmployees: [],
    paypalSubscriptionId: "",
    phone: "",
    postalCode: "",
    province: "",
    road: "",
    showCalendar: false,
    subdistrict: "",
    subscriptionPlan: "",
};

const create_employee_calendar_events_success = createAction<any[]>(
    ManagerAction.CREATE_EMPLOYEE_CALENDAR_EVENTS_SUCCESS
);
// const get_company_manager_data_success = createAction<any>(
//     ManagerAction.GET_COMPANY_MANAGER_DATA_SUCCESS
// );
const get_formality_user_success = createAction<any>(
    UserAction.GET_FORMALITY_USER_SUCCESS
);
const save_company_registration_info_success = createAction<any>(
    SubscriptionAction.SAVE_COMPANY_REGISTRATION_INFO_SUCCESS
);
const save_subscription_form_values = createAction<any>(
    SubscriptionAction.SAVE_SUBSCRIPTION_FORM_VALUES
);
const stop_subscription_process = createAction<any>(
    SubscriptionAction.STOP_SUBSCRIPTION_PROCESS
);
const set_employee_to_disconnect = createAction<any>(
    UserAction.SET_EMPLOYEE_TO_DISCONNECT
);
const set_impersonating = createAction<any>(UserAction.SET_IMPERSONATING);
const set_calendar_view = createAction<boolean>(UserAction.SET_CALENDAR_VIEW);
const update_user = createAction<any>(UserAction.UPDATE_USER);
const sign_out_success = createAction<Record<string, any>>(
    AuthAction.SIGN_OUT_SUCCESS
);

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(create_employee_calendar_events_success, (state, action) => {
            return {
                ...state,
                myCalendarEvents: [...action.payload],
            };
        })
        .addCase(get_formality_user_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        // .addCase(get_company_manager_data_success, (state, action) => {
        //     return { ...state, myEmployees: action.payload };
        // })
        .addCase(save_company_registration_info_success, (state, action) => {
            return {
                ...state,
                companyId: action.payload.companyId,
            };
        })
        .addCase(save_subscription_form_values, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(stop_subscription_process, (state) => {
            return {
                ...state,
                email: "",
                familyName: "",
                firstName: "",
                phone: "",
                addressNumber: "",
                road: "",
                subdistrict: "",
                district: "",
                province: "",
                postalCode: "",
                subscriptionPlan: "",
            };
        })
        .addCase(set_employee_to_disconnect, (state, action) => {
            return { ...state, employeeToDisconnect: action.payload };
        })
        .addCase(set_impersonating, (state, action) => {
            return { ...state, impersonating: action.payload };
        })
        .addCase(set_calendar_view, (state, action) => {
            return { ...state, showCalendar: action.payload };
        })
        .addCase(update_user, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(sign_out_success, () => {
            return initialState;
        });
});
export default reducer;

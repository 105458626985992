/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFormalityCompany = /* GraphQL */ `
  query GetFormalityCompany($id: ID!) {
    getFormalityCompany(id: $id) {
      addressNumber
      companyNumber
      contactId
      district
      id
      isSubscribed
      companyName
      owner
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      subdistrict
      subscriptionPlan
      contact {
        id
        email
        addressNumber
        companyId
        cookiesAccepted
        district
        familyName
        firstName
        isPending
        isSubscribed
        paymentMethod
        paypalSubscriptionId
        phone
        postalCode
        province
        road
        role
        subdistrict
        subscriptionPlan
        forms {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          email
          addressNumber
          companyId
          cookiesAccepted
          district
          familyName
          firstName
          isPending
          isSubscribed
          paymentMethod
          paypalSubscriptionId
          phone
          postalCode
          province
          road
          role
          subdistrict
          subscriptionPlan
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFormalityCompanys = /* GraphQL */ `
  query ListFormalityCompanys(
    $filter: ModelFormalityCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormalityCompanys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        addressNumber
        companyNumber
        contactId
        district
        id
        isSubscribed
        companyName
        owner
        paymentMethod
        paypalSubscriptionId
        phone
        postalCode
        province
        road
        subdistrict
        subscriptionPlan
        contact {
          id
          email
          addressNumber
          companyId
          cookiesAccepted
          district
          familyName
          firstName
          isPending
          isSubscribed
          paymentMethod
          paypalSubscriptionId
          phone
          postalCode
          province
          road
          role
          subdistrict
          subscriptionPlan
          createdAt
          updatedAt
          owner
        }
        employees {
          nextToken
        }
        forms {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFormalityUser = /* GraphQL */ `
  query GetFormalityUser($id: ID!) {
    getFormalityUser(id: $id) {
      id
      email
      addressNumber
      companyId
      cookiesAccepted
      district
      familyName
      firstName
      isPending
      isSubscribed
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      role
      subdistrict
      subscriptionPlan
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFormalityUsers = /* GraphQL */ `
  query ListFormalityUsers(
    $filter: ModelFormalityUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormalityUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        addressNumber
        companyId
        cookiesAccepted
        district
        familyName
        firstName
        isPending
        isSubscribed
        paymentMethod
        paypalSubscriptionId
        phone
        postalCode
        province
        road
        role
        subdistrict
        subscriptionPlan
        forms {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFormalityForm = /* GraphQL */ `
  query GetFormalityForm($id: ID!) {
    getFormalityForm(id: $id) {
      id
      companyId
      userId
      name
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFormalityForms = /* GraphQL */ `
  query ListFormalityForms(
    $filter: ModelFormalityFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormalityForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        userId
        name
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

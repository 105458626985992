import { createAction, createReducer } from "@reduxjs/toolkit";
import { AuthAction, FormDataAction, FormalityAction, FormDataState } from "../types";
import { ManagerAction } from "../types/manager";
const initialState: FormDataState = {
    applicationDetailsFormData: {},
    personalInfoFormData: {},
    stm2FormData: {},
    thaiAddressFormData: {},
    tm47FormData: {},
    tm7FormData: {},
    tm8FormData: {},
    travelDocFormData: {},
};

const delete_all_user_forms_success = createAction<null>(
    FormalityAction.DELETE_ALL_USER_FORMS_SUCCESS
);
const get_employee_form_data_success = createAction<any>(
    ManagerAction.GET_EMPLOYEE_FORM_DATA_SUCCESS
);
const save_form_data_success = createAction<any>(
    FormDataAction.SAVE_FORM_DATA_SUCCESS
);
const set_user_form_data = createAction<any>(FormDataAction.SET_USER_FORM_DATA);
const setup_demo_forms = createAction<any>(FormDataAction.SETUP_DEMO_FORMS);
const set_user_init_form_data = createAction<any>(
    FormDataAction.SET_USER_INIT_FORM_DATA
);
const set_thai_address_search_data = createAction<any>(
    FormDataAction.SET_THAI_ADDRESS_SEARCH_DATA
);
const reset_forms = createAction<null>(FormDataAction.RESET_FORMS);
const signOut = createAction<null>(AuthAction.SIGN_OUT_SUCCESS);

const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(delete_all_user_forms_success, () => {
            return initialState;
        })
        .addCase(get_employee_form_data_success, (state, action) => {
            return { ...initialState, ...action.payload };
        })
        .addCase(setup_demo_forms, (state, action) => {
            return { ...initialState, ...action.payload };
        })
        .addCase(set_user_init_form_data, (state, action) => {
            return { ...initialState, ...action.payload };
        })
        .addCase(save_form_data_success, (state, action) => {
            return { ...state, [action.payload.formName]: action.payload.data };
        })
        .addCase(set_thai_address_search_data, (state, action) => {
            return {
                ...state,
                thaiAddressFormData: {
                    ...state.thaiAddressFormData,
                    ...action.payload,
                },
            };
        })
        .addCase(set_user_form_data, (state, action) => {
            return { ...state, [action.payload.formName]: action.payload.data };
        })
        .addCase(reset_forms, () => {
            return initialState;
        })
        .addCase(signOut, () => {
            return initialState;
        });
});
export default reducer;

import { useState, useCallback, useContext } from "react";
import { Button } from "react-bootstrap";
import { getButtonColor } from "../helpers";
import { omit } from "lodash";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Spinner } from "../components/shared/Spinner";
import { SubscriptionAction, UserAction } from "../types";
import { ThemeContext } from "../contexts/ThemeProvider";
import { updateFormalityUser, saveCompanyRegistrationInformation, } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    navbarOffset: number;
}
export const PaypalPayment = ({ navbarOffset }: Props) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [, width] = useWindowSize();

    // const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const KI_NOK_PLAN = process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_KI_NOK;
    const LE_KA_PLAN = process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_LE_KA;
    const BIG_BOSS_PLAN =
        process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_BIG_BOSS;

    const { id: currentUserId } = useAppSelector((state) => state.auth);
    const { selectedPlan, subscribingAs } = useAppSelector(
        (state) => state.formality
    );
    const subscription = useAppSelector((state) => state.subscription);
    const [error, setError] = useState("");
    const [isProcessingSubscription, setIsProcessingSubscription] =
        useState(false);
    const [paymentApprovalDetails, setPaymentApprovalDetails] =
        useState<Record<string, any>>();
    const [{ isPending }] = usePayPalScriptReducer();

    // const getCurrency = useCallback(() => {
    //     switch (selectedPlan) {
    //         case "ฝรั่งขี้นก":
    //             return "THB";
    //         case "เลขานุการ":
    //             return "HKD";
    //         case "หัวหน้าใหญ่":
    //             return "USD";
    //         default:
    //             break;
    //     }
    // }, [selectedPlan]);

    const getPlanId = useCallback(() => {
        switch (selectedPlan) {
            case "ฝรั่งขี้นก":
                return KI_NOK_PLAN;
            case "เลขานุการ":
                return LE_KA_PLAN;
            case "หัวหน้าใหญ่":
                return BIG_BOSS_PLAN;
            default:
                break;
        }
    }, [BIG_BOSS_PLAN, KI_NOK_PLAN, LE_KA_PLAN, selectedPlan]);

    const getPaymentTerms = () => {
        switch (selectedPlan) {
            case "ฝรั่งขี้นก":
                return "250 THB per month";
            case "เลขานุการ":
                return "5 USD per month";
            case "หัวหน้าใหญ่":
                return "200 USD per month";
            default:
                break;
        }
    };

    return (
        <div
            className="container d-flex flex-column align-items-center justify-content-center text-center vh-100"
            style={{ paddingTop: navbarOffset }}
        >
            <h1 className="display text-muted mb-3">Subscription Payment</h1>
            {error ? (
                <>
                    <p className={`mb-3 ${width >= 576 ? "lead" : ""}`}>
                        Something's not right
                    </p>
                    <p
                        className={`mb-3 text-danger ${
                            width >= 576 ? "lead" : ""
                        }`}
                    >
                        {error}
                    </p>
                    <Button
                        className="my-3"
                        onClick={() => {
                            setError("");
                            alert("I don't do anything yet!");
                        }}
                        variant={getButtonColor("primary", isDark)}
                        // onClick={() => navigate("/")}
                    >
                        Try Again
                    </Button>
                </>
            ) : null}
            {isProcessingSubscription && !error ? (
                <>
                    <Spinner color="primary" size={72} type="moon" />
                    <p className={`mb-3 ${width >= 576 ? "lead" : ""}`}>
                        Processing...
                    </p>
                    <Button
                        className="my-3"
                        onClick={() => navigate("/")}
                        variant={getButtonColor("primary", isDark)}
                    >
                        Cancel
                    </Button>
                </>
            ) : null}
            {!isProcessingSubscription && paymentApprovalDetails ? (
                <>
                    <p className={`mb-3 ${width >= 576 ? "lead" : ""}`}>
                        Thank you for subscribing to Thai Formality!
                    </p>
                    <Button
                        className="my-3"
                        onClick={() => navigate("/")}
                        variant={getButtonColor("primary", isDark)}
                    >
                        Continue
                    </Button>
                </>
            ) : null}
            {!isProcessingSubscription && !paymentApprovalDetails ? (
                <>
                    <p className={`mb-3 ${width >= 576 ? "lead" : ""}`}>
                        You are about to pay for a subscription to Thai
                        Formality.
                    </p>
                    <ul
                        className="list-group mb-4 w-100"
                        style={{ maxWidth: 480 }}
                    >
                        <li className="d-flex align-items-center justify-content-between list-group-item">
                            <span className={`${width >= 576 ? "lead" : ""}`}>
                                Selected Plan:
                            </span>
                            <span className={`${width >= 576 ? "lead" : ""}`}>
                                {selectedPlan}
                            </span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between list-group-item">
                            <span className={`${width >= 576 ? "lead" : ""}`}>
                                Subscription price:
                            </span>
                            <span className={`${width >= 576 ? "lead" : ""}`}>
                                {getPaymentTerms()}
                            </span>
                        </li>
                    </ul>
                    <div className="w-100" style={{ maxWidth: 420 }}>
                        {isPending ? (
                            <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: 132 }}
                            >
                                <Spinner color="muted" size={72} type="moon" />
                            </div>
                        ) : null}
                        <PayPalButtons
                            // options={{
                            //     clientId: CLIENT_ID,
                            //     currency: getCurrency(),
                            //     intent: "tokenize",
                            //     vault: true,
                            // }}
                            style={{
                                shape: "pill",
                                color: "gold",
                                layout: "vertical",
                                label: "subscribe",
                            }}
                            createSubscription={(data, actions) => {
                                const planId = getPlanId();
                                if (!planId) {
                                    throw new Error("No plan id found!");
                                }
                                return actions.subscription.create({
                                    plan_id: planId,
                                });
                            }}
                            onApprove={async (data, actions) => {
                                try {
                                    if (isDev) {
                                        console.log(
                                            "%cGet this type right",
                                            "color:hotpink",
                                            data
                                        );
                                    }
                                    setIsProcessingSubscription(true);
                                    const payload = {
                                        ...omit(subscription, ["employees"]),
                                        paymentMethod: "payPal",
                                        paypalSubscriptionId:
                                            data.subscriptionID,
                                        contactId: currentUserId,
                                    };
                                    if (subscribingAs === "company") {
                                        await dispatch(
                                            saveCompanyRegistrationInformation(
                                                payload
                                            )
                                        );
                                    } else {
                                        await dispatch(
                                            updateFormalityUser(payload)
                                        );
                                        dispatch({
                                            type: UserAction.UPDATE_USER,
                                            payload,
                                        });
                                    }
                                    setPaymentApprovalDetails(data);
                                    setIsProcessingSubscription(false);
                                } catch (error: any) {
                                    console.warn(error);
                                    setError(error.message);
                                }
                            }}
                        />
                    </div>
                    <button
                        className="btn btn-link text-muted"
                        onClick={() => {
                            dispatch({
                                type: SubscriptionAction.STOP_SUBSCRIPTION_PROCESS,
                            });
                            navigate("/");
                        }}
                    >
                        cancel
                    </button>
                </>
            ) : null}
        </div>
    );
};

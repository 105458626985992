import { FormalityForm } from "./formality";

export enum SubscriptionAction {
    CHECK_COMPANY_EXISTS_FAIL = "check_company_exists_fail",
    CHECK_COMPANY_EXISTS_START = "check_company_exists_start",
    CHECK_COMPANY_EXISTS_SUCCESS = "check_company_exists_success",
    GET_COMPANY_SUCCESS = "get_company_success",
    SAVE_COMPANY_REGISTRATION_INFO_FAIL = "save_company_registration_info_fail",
    SAVE_COMPANY_REGISTRATION_INFO_START = "save_company_registration_info_start",
    SAVE_COMPANY_REGISTRATION_INFO_SUCCESS = "save_company_registration_info_success",
    SAVE_SUBSCRIPTION_FORM_VALUES = "save_subscription_form_values",
    SAVE_USER_TO_COMPANY = "save_user_to_company",
    SET_REGISTRATION_ADDRESS = "set_registration_address",
    START_SUBSCRIPTION_PROCESS = "start_subscription_process",
    STOP_SUBSCRIPTION_PROCESS = "stop_subscription_process",
    UPDATE_COMPANY_SUBSCRIPTION = "update_company_subscription",
}
export type SubscriptionState = {
    employees: { items: CompanyEmployee[]; nextToken: any };
    forms: any[];
    addressNumber: string;
    companyNumber: string;
    contactId: string;
    district: string;
    firstName: string;
    familyName: string;
    id: string;
    isSubscribed: string;
    companyName: string;
    paymentMethod: string;
    paypalSubscriptionId: string;
    phone: string;
    postalCode: string;
    province: string;
    road: string;
    subdistrict: string;
    subscriptionPlan: string;
    contact: any;
    contactFirstName: string;
    contactFamilyName: string;
};

export type CompanyEmployee = {
    id: string;
    email: string;
    firstName: string;
    familyName: string;
    isPending: boolean;
    companyId: string;
};

export type Company = {
    addressNumber: string;
    companyNumber: string;
    contactId: string;
    district: string;
    id: string;
    isSubscribed: string;
    companyName: string;
    paymentMethod: string;
    paypalSubscriptionId: string;
    phone: string;
    postalCode: string;
    province: string;
    road: string;
    subdistrict: string;
    subscriptionPlan: string;
    contact: any;
    employees: CompanyEmployee[];
    forms: FormalityForm[];
};

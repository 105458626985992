import { useContext } from "react";
import { Button } from "react-bootstrap";
import { getTitleCase, getPlanName, getButtonColor } from "../helpers";
import { ThemeContext } from "../contexts/ThemeProvider";
import moment from "moment";
import numeral from "numeral";

export const LinkPaypalSubscriptionConfirmation = ({
    subscriptionData,
    setIsLinking,
    setLinkingAs,
    setSubscriptionData,
}) => {
    const { isDark } = useContext(ThemeContext);
    const {
        billing_info: {
            last_payment: {
                amount: {
                    currency_code: lastPaymentCurrency,
                    value: lastPaymentValue,
                },
                time: lastPaymentTime,
            },
        },
        subscriber: {
            email_address: email,
            name: { given_name: firstName, surname: familyName },
        },
        id: paypalSubscriptionId,
        plan_id: planId,
        status,
    } = subscriptionData;

    const handleConfirmation = () => {
        try {
            if (getPlanName(planId) === "ฝรั่งขี้นก") {
                setLinkingAs("individual");
                // do individual linking
            } else {
                setLinkingAs("company");
                // do company linking
            }
            // dispatch({
            //   type: "START_LINK_SUBSCRIPTION_PROCESS",
            //   payload: {
            //     ...subscriptionData,
            //     paypalSubscriptionId: getPlanName()
            //   }
            // })
        } catch (error) {
            console.warn(error);
        }
    };

    const renderSubscriptionData = () => {
        return (
            <ul className="list-group w-100" style={{ maxWidth: 480 }}>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className="lead">Subscription Id</span>
                    <span className="lead">{paypalSubscriptionId}</span>
                </li>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className={"lead"}>Subscription Status</span>
                    <span
                        className={`lead ${
                            status === "ACTIVE" ? "text-success" : "text-danger"
                        }`}
                    >
                        {getTitleCase(status)}
                    </span>
                </li>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className="lead">Subscriber Name</span>
                    <span className="lead">
                        {firstName} {familyName}
                    </span>
                </li>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className="lead">Subscriber Email</span>
                    <span className="lead">{email}</span>
                </li>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className="lead">Subscribed Plan</span>
                    <span className="lead">{getPlanName(planId)}</span>
                </li>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className="lead">Last Payment Amount</span>
                    <span className="lead">
                        {numeral(lastPaymentValue).format("0,0.00")}{" "}
                        {lastPaymentCurrency}
                    </span>
                </li>
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span className="lead">Last Payment Date</span>
                    <span className="lead">
                        {moment(lastPaymentTime).format("DD MMM YYYY")}{" "}
                    </span>
                </li>
            </ul>
        );
    };

    return (
        <div className="container d-flex flex-column align-items-center animate__animated animate__fadeIn">
            <div className="display text-center text-muted mb-3 mb-sm-5">
                Confirm Subscription
            </div>
            {renderSubscriptionData()}
            <Button
                className="d-flex align-items-center justify-content-center mt-2 mt-sm-3"
                disabled={status !== "ACTIVE"}
                onClick={() => handleConfirmation()}
                variant={getButtonColor("primary", isDark)}
            >
                Confirm Subscription
            </Button>
            {status !== "ACTIVE" ? (
                <small className="text-danger text-center mt-2">
                    You may only link active subscriptions
                </small>
            ) : null}
            <Button
                className="custom-link-button mt-3"
                onClick={() => {
                    setSubscriptionData("");
                    setIsLinking(true);
                }}
                type="button"
                variant="link"
            >
                Back
            </Button>
        </div>
    );
};

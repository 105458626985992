import { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { thaiAddressFormSchema } from "../../validators";
import { SaveFormButton } from "../SaveFormButton";
import { saveFormData } from "../../actions";
import { MobileFormHeader } from "../MobileFormHeader";
import { ThaiAddressSelector } from "../ThaiAddressSelector";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

interface Props {
    title: string;
}
export const ThaiAddressForm = ({ title }: Props) => {
    const thaiAddressNumberRef = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();

    const { thaiAddressFormData: data } = useAppSelector(
        (state) => state.formData
    );

    const [isSaved, setIsSaved] = useState(false);
    const [saveError, setSaveError] = useState("");

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    thaiAddressDistrict: data.thaiAddressDistrict || "",
                    thaiAddressNumber: data.thaiAddressNumber || "",
                    thaiAddressPostalCode: data.thaiAddressPostalCode || "",
                    thaiAddressProvince: data.thaiAddressProvince || "",
                    thaiAddressRoad: data.thaiAddressRoad || "",
                    thaiAddressSubdistrict: data.thaiAddressSubdistrict || "",
                }}
                validationSchema={thaiAddressFormSchema}
                onSubmit={async (values, actions) => {
                    try {
                        setIsSaved(false);
                        actions.setSubmitting(true);
                        await dispatch(
                            saveFormData({
                                name: "thaiAddressFormData",
                                data: values,
                            })
                        );
                        actions.setSubmitting(false);
                        setIsSaved(true);
                    } catch (error: any) {
                        actions.setSubmitting(false);
                        setSaveError(error.message);
                        console.warn(error);
                    }
                }}
            >
                {({ dirty, isSubmitting, values }) => (
                    <div className="animate__animated animate__fadeIn p-3">
                        <ThaiAddressSelector
                            formDestination="thaiAddressForm"
                            tabIndex="1"
                            values={values}
                        />
                        <Form
                            // className="animate__animated animate__fadeIn"
                            noValidate
                        >
                            <MobileFormHeader title={title} />
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="thaiAddressNumber"
                                    className="form-label mt-2"
                                >
                                    Address Number
                                </label>
                                <Field
                                    ref={thaiAddressNumberRef}
                                    className="form-control"
                                    id="thaiAddressNumber"
                                    name="thaiAddressNumber"
                                    placeholder="80/12 Thapae Road"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="thaiAddressNumber" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="thaiAddressRoad"
                                    className="form-label"
                                >
                                    Road
                                </label>
                                <Field
                                    className="form-control"
                                    id="thaiAddressRoad"
                                    name="thaiAddressRoad"
                                    placeholder="Soi 1"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="thaiAddressRoad" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="thaiAddressSubdistrict"
                                    className="form-label"
                                >
                                    Subdistrict (
                                    <span className="font-italic">Tambol</span>/
                                    <span className="font-italic">Khwaeng</span>
                                    )
                                </label>
                                <Field
                                    className="form-control"
                                    id="thaiAddressSubdistrict"
                                    name="thaiAddressSubdistrict"
                                    placeholder="Changklang"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="thaiaddressSubdistrict" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="thaiAddressDistrict"
                                    className="form-label"
                                >
                                    District (
                                    <span className="font-italic">Amphoe</span>/
                                    <span className="font-italic">Khet</span>)
                                </label>
                                <Field
                                    className="form-control"
                                    id="thaiAddressDistrict"
                                    name="thaiAddressDistrict"
                                    placeholder="Muang"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="thaiAddressDistrict" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="thaiAddressProvince"
                                    className="form-label"
                                >
                                    Province (
                                    <span className="font-italic">
                                        Changwat
                                    </span>
                                    )
                                </label>
                                <Field
                                    className="form-control"
                                    id="thaiAddressProvince"
                                    name="thaiAddressProvince"
                                    placeholder="Chiang Mai"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="thaiAddressProvince" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="thaiAddressPostalCode"
                                    className="form-label"
                                >
                                    Postal Code
                                </label>
                                <Field
                                    className="form-control"
                                    id="thaiAddressPostalCode"
                                    name="thaiAddressPostalCode"
                                    placeholder="5000"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="thaiAddressPostalCode" />
                                </div>
                            </div>
                            <SaveFormButton
                                dirty={dirty}
                                isSaved={isSaved}
                                isSubmitting={isSubmitting}
                                saveError={saveError}
                            />
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    );
};

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFormalityCompany = /* GraphQL */ `
  mutation CreateFormalityCompany(
    $input: CreateFormalityCompanyInput!
    $condition: ModelFormalityCompanyConditionInput
  ) {
    createFormalityCompany(input: $input, condition: $condition) {
      addressNumber
      companyNumber
      contactId
      district
      id
      isSubscribed
      companyName
      owner
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      subdistrict
      subscriptionPlan
      contact {
        id
        email
        addressNumber
        companyId
        cookiesAccepted
        district
        familyName
        firstName
        isPending
        isSubscribed
        paymentMethod
        paypalSubscriptionId
        phone
        postalCode
        province
        road
        role
        subdistrict
        subscriptionPlan
        forms {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          email
          addressNumber
          companyId
          cookiesAccepted
          district
          familyName
          firstName
          isPending
          isSubscribed
          paymentMethod
          paypalSubscriptionId
          phone
          postalCode
          province
          road
          role
          subdistrict
          subscriptionPlan
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFormalityCompany = /* GraphQL */ `
  mutation UpdateFormalityCompany(
    $input: UpdateFormalityCompanyInput!
    $condition: ModelFormalityCompanyConditionInput
  ) {
    updateFormalityCompany(input: $input, condition: $condition) {
      addressNumber
      companyNumber
      contactId
      district
      id
      isSubscribed
      companyName
      owner
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      subdistrict
      subscriptionPlan
      contact {
        id
        email
        addressNumber
        companyId
        cookiesAccepted
        district
        familyName
        firstName
        isPending
        isSubscribed
        paymentMethod
        paypalSubscriptionId
        phone
        postalCode
        province
        road
        role
        subdistrict
        subscriptionPlan
        forms {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          email
          addressNumber
          companyId
          cookiesAccepted
          district
          familyName
          firstName
          isPending
          isSubscribed
          paymentMethod
          paypalSubscriptionId
          phone
          postalCode
          province
          road
          role
          subdistrict
          subscriptionPlan
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFormalityCompany = /* GraphQL */ `
  mutation DeleteFormalityCompany(
    $input: DeleteFormalityCompanyInput!
    $condition: ModelFormalityCompanyConditionInput
  ) {
    deleteFormalityCompany(input: $input, condition: $condition) {
      addressNumber
      companyNumber
      contactId
      district
      id
      isSubscribed
      companyName
      owner
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      subdistrict
      subscriptionPlan
      contact {
        id
        email
        addressNumber
        companyId
        cookiesAccepted
        district
        familyName
        firstName
        isPending
        isSubscribed
        paymentMethod
        paypalSubscriptionId
        phone
        postalCode
        province
        road
        role
        subdistrict
        subscriptionPlan
        forms {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          email
          addressNumber
          companyId
          cookiesAccepted
          district
          familyName
          firstName
          isPending
          isSubscribed
          paymentMethod
          paypalSubscriptionId
          phone
          postalCode
          province
          road
          role
          subdistrict
          subscriptionPlan
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFormalityUser = /* GraphQL */ `
  mutation CreateFormalityUser(
    $input: CreateFormalityUserInput!
    $condition: ModelFormalityUserConditionInput
  ) {
    createFormalityUser(input: $input, condition: $condition) {
      id
      email
      addressNumber
      companyId
      cookiesAccepted
      district
      familyName
      firstName
      isPending
      isSubscribed
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      role
      subdistrict
      subscriptionPlan
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFormalityUser = /* GraphQL */ `
  mutation UpdateFormalityUser(
    $input: UpdateFormalityUserInput!
    $condition: ModelFormalityUserConditionInput
  ) {
    updateFormalityUser(input: $input, condition: $condition) {
      id
      email
      addressNumber
      companyId
      cookiesAccepted
      district
      familyName
      firstName
      isPending
      isSubscribed
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      role
      subdistrict
      subscriptionPlan
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFormalityUser = /* GraphQL */ `
  mutation DeleteFormalityUser(
    $input: DeleteFormalityUserInput!
    $condition: ModelFormalityUserConditionInput
  ) {
    deleteFormalityUser(input: $input, condition: $condition) {
      id
      email
      addressNumber
      companyId
      cookiesAccepted
      district
      familyName
      firstName
      isPending
      isSubscribed
      paymentMethod
      paypalSubscriptionId
      phone
      postalCode
      province
      road
      role
      subdistrict
      subscriptionPlan
      forms {
        items {
          id
          companyId
          userId
          name
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFormalityForm = /* GraphQL */ `
  mutation CreateFormalityForm(
    $input: CreateFormalityFormInput!
    $condition: ModelFormalityFormConditionInput
  ) {
    createFormalityForm(input: $input, condition: $condition) {
      id
      companyId
      userId
      name
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFormalityForm = /* GraphQL */ `
  mutation UpdateFormalityForm(
    $input: UpdateFormalityFormInput!
    $condition: ModelFormalityFormConditionInput
  ) {
    updateFormalityForm(input: $input, condition: $condition) {
      id
      companyId
      userId
      name
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFormalityForm = /* GraphQL */ `
  mutation DeleteFormalityForm(
    $input: DeleteFormalityFormInput!
    $condition: ModelFormalityFormConditionInput
  ) {
    deleteFormalityForm(input: $input, condition: $condition) {
      id
      companyId
      userId
      name
      data
      createdAt
      updatedAt
      owner
    }
  }
`;

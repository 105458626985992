import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { travelDocumentFormSchema } from "../../validators";
import { saveFormData } from "../../actions";
import { SaveFormButton } from "../SaveFormButton";
import { MobileFormHeader } from "../MobileFormHeader";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export const TravelDocumentInfo = ({ title, setScrollTo }) => {
    const dispatch = useAppDispatch();
    const [, width] = useWindowSize();
    const { travelDocFormData: data } = useAppSelector(
        (state) => state.formData
    );
    const [isSaved, setIsSaved] = useState(false);
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    arrivalDate: data.arrivalDate || "",
                    arrivedBy: data.arrivedBy || "",
                    arrivedFrom: data.arrivedFrom || "",
                    expDate: data.expDate || "",
                    issueDate: data.issueDate || "",
                    issuedBy: data.issuedBy || "",
                    issuingCountry: data.issuingCountry || "",
                    number: data.number || "",
                    portOfArrival: data.portOfArrival || "",
                    tm6Number: data.tm6Number || "",
                    typeOfVisa: data.typeOfVisa || "",
                    visaIssuedAt: data.visaIssuedAt || "",
                    visaIssueDate: data.visaIssueDate || "",
                    visaExpDate: data.visaExpDate || "",
                    ninetyDayExpDate: data.ninetyDayExpDate || "",
                }}
                validationSchema={travelDocumentFormSchema}
                onSubmit={async (values, actions) => {
                    try {
                        setIsSaved(false);
                        await dispatch(
                            saveFormData({
                                name: "travelDocFormData",
                                data: values,
                            })
                        );
                        actions.setSubmitting(false);
                        setIsSaved(true);
                        if (width >= 576) {
                            setScrollTo("top");
                        }
                    } catch (error) {
                        actions.setSubmitting(false);
                        console.warn(error);
                    }
                }}
            >
                {({ values, dirty, errors, isSubmitting, setFieldValue }) => (
                    <Form
                        className="animate__animated animate__fadeIn p-3"
                        noValidate
                    >
                        <MobileFormHeader title={title} />
                        <div className="form-group mb-2">
                            <label htmlFor="number" className="form-label mt-0">
                                Travel document #
                            </label>
                            <Field
                                className="form-control"
                                id="number"
                                name="number"
                                placeholder="123456789"
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="number" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="issuingCountry"
                                className="form-label"
                            >
                                Issuing Country
                            </label>
                            <Field
                                className="form-control"
                                id="issuingCountry"
                                name="issuingCountry"
                                placeholder="U.S.A."
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="issuingCountry" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="issuedBy" className="form-label">
                                Issued By
                            </label>
                            <Field
                                className="form-control"
                                id="issuedBy"
                                name="issuedBy"
                                placeholder="US Dept. of State"
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="issuedBy" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="issueDate" className="form-label">
                                Issue Date
                            </label>
                            <Field
                                className="form-control"
                                id="issueDate"
                                name="issueDate"
                                spellCheck="false"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="issueDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="expDate" className="form-label">
                                Expiry Date
                            </label>
                            <Field
                                className="form-control"
                                id="expDate"
                                name="expDate"
                                spellCheck="false"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="expDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="tm6Munber" className="form-label">
                                Arrival Card (T.M. 6) #
                            </label>
                            <Field
                                className="form-control"
                                onFocus={() => {
                                    if (width > 768) setScrollTo("bottom");
                                }}
                                id="tm6Number"
                                name="tm6Number"
                                placeholder="AB12345"
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="tm6Number" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="arrivalDate" className="form-label">
                                Date of Arrival (T.M. 6)
                            </label>
                            <Field
                                className="form-control"
                                id="arrivalDate"
                                name="arrivalDate"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="arrivalDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="ninetyDayExpDate"
                                className="form-label"
                            >
                                Next 90-Day Notification
                            </label>
                            <Field
                                className="form-control"
                                id="ninetyDayExpDate"
                                name="ninetyDayExpDate"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="ninetyDayExpDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="arrivedBy" className="form-label">
                                Arrived by (type of transportation)
                            </label>
                            <Field
                                className="form-control"
                                id="arrivedBy"
                                name="arrivedBy"
                                placeholder="Air, Sea, Land..."
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="arrivedBy" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="arrivedFrom" className="form-label">
                                Arrived From
                            </label>
                            <Field
                                className="form-control"
                                id="arrivedFrom"
                                name="arrivedFrom"
                                placeholder="Mozambique"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="arrivedFrom" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="portOfArrival"
                                className="form-label"
                            >
                                Port of Arrival
                            </label>
                            <Field
                                className="form-control"
                                id="portOfArrival"
                                name="portOfArrival"
                                placeholder="BKK"
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="portOfArrival" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="typeOfVisa" className="form-label">
                                Type of Visa
                            </label>
                            <Field
                                as="select"
                                className="form-select"
                                id="typeOfVisa"
                                name="typeOfVisa"
                                type="text"
                            >
                                <option value="">Select Visa Type</option>
                                <option value="non-imm-b">
                                    Non-immigrant B
                                </option>
                                <option value="non-imm-o">
                                    Non-immigrant O
                                </option>
                                <option value="tourist">Tourist</option>
                                <option value="transit">Transit</option>
                                <option value="other">Other</option>
                            </Field>
                            <div className="formikErrorMessage">
                                <ErrorMessage name="typeOfVisa" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="visaIssuedAt"
                                className="form-label"
                            >
                                Visa Issued At
                            </label>
                            <Field
                                className="form-control"
                                id="visaIssuedAt"
                                name="visaIssuedAt"
                                placeholder="e.g. Chiang Mai Immigration"
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="visaIssuedAt" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="visaIssueDate"
                                className="form-label"
                            >
                                Visa Issue Date
                            </label>
                            <Field
                                className="form-control"
                                id="visaIssueDate"
                                name="visaIssueDate"
                                spellCheck="false"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="visaIssueDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="visaExpDate" className="form-label">
                                Visa Expiry Date
                            </label>
                            <Field
                                className="form-control"
                                id="visaExpDate"
                                name="visaExpDate"
                                spellCheck="false"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="visaExpDate" />
                            </div>
                        </div>
                        <SaveFormButton
                            dirty={dirty}
                            isSaved={isSaved}
                            isSubmitting={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export interface AdminState {
    cognitoUsers: any[];
    companies: any[];
    users: any[];
}

export enum AdminAction {
    DELETE_ADMIN_COMPANY = "delete_admin_company",
    DELETE_COGNITO_USER = "delete_cognito_user",
    GET_ADMIN_DATA_FAIL="get_admin_data_fail",
    GET_ADMIN_DATA_START="get_admin_data_start",
    GET_ADMIN_DATA_SUCCESS="get_admin_data_success",
    GET_ALL_COGNITO_USERS_FAIL = "get_all_cognito_users_fail",
    GET_ALL_COGNITO_USERS_START = "get_all_cognito_users_start",
    GET_ALL_COGNITO_USERS_SUCCESS = "get_all_cognito_users_success",
    GET_FORMALITY_COMPANIES_FAIL = "get_formality_companies_fail",
    GET_FORMALITY_COMPANIES_START = "get_formality_companies_start",
    GET_FORMALITY_COMPANIES_SUCCESS = "get_formality_companies_success",
    GET_FORMALITY_USERS_FAIL = "get_formality_users_fail",
    GET_FORMALITY_USERS_START = "get_formality_users_start",
    GET_FORMALITY_USERS_SUCCESS = "get_formality_users_success",
    GET_FORMALITY_USERS_OF_COMPANY_FAIL="get_formality_users_of_company_fail",
    GET_FORMALITY_USERS_OF_COMPANY_START="get_formality_users_of_company_start",
    GET_FORMALITY_USERS_OF_COMPANY_SUCCESS="get_formality_users_of_company_success",
    REMOVE_MULTIPLE_FORMALITY_USERS_FAIL = "remove_multiple_formality_users_fail",
    REMOVE_MULTIPLE_FORMALITY_USERS_START = "remove_multiple_formality_users_start",
    REMOVE_MULTIPLE_FORMALITY_USERS_SUCCESS = "remove_multiple_formality_users_success",
    REMOVE_USER_FROM_APP_FAIL = "remove_user_from_app_fail",
    REMOVE_USER_FROM_APP_START = "remove_user_from_app_start",
    REMOVE_USER_FROM_APP_SUCCESS = "remove_user_from_app_success",
    UPDATE_ADMIN = "update_admin",
}

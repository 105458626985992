import { useContext, useState } from "react";
import { AuthAction } from "../types";
import { Formik, Form as FForm, Field, ErrorMessage } from "formik";
import { initiateSignUp } from "../actions";
import { signUpSchema } from "../validators";
import { Spinner } from "../components/shared/Spinner";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useWindowSize } from "../hooks/useWindowSize";
import {
    Button,
    Form,
    InputGroup,
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { ThemeContext } from "../contexts/ThemeProvider";
import { getButtonColor, getSpinnerColor } from "../helpers";

interface Props {
    params: {
        company?: string | null | undefined;
        from?: string | null | undefined;
        id?: string | null | undefined;
        isInvite?: boolean;
        username?: string | null | undefined;
        firstName?: string | null | undefined;
        familyName?: string | null | undefined;
    };
}
export const SignUp = ({
    params: { company, from, id, isInvite, username, firstName, familyName },
}: Props) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const [, width] = useWindowSize();
    const {
        attemptedValues: {
            username: attemptedUsername,
            password: attemptedPassword,
        },
    } = useAppSelector((state) => state.auth);

    const [showPassword, setShowPassword] = useState(false);

    const popover = (
        <Popover id="password-popover">
            <Popover.Header as="h3">Password Requirements</Popover.Header>
            <Popover.Body>
                <div>
                    Minimum 8 characters and at least one of each of the
                    following:
                    <ul>
                        <li>digit</li>
                        <li>lowercase letter</li>
                        <li>uppercase letter</li>
                        <li>special character</li>
                    </ul>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <Formik
            initialValues={
                !isInvite
                    ? {
                          username: attemptedUsername ? attemptedUsername : "",
                          password: attemptedPassword ? attemptedPassword : "",
                          confirmPassword: "",
                      }
                    : {
                          companyId: id,
                          companyName: company,
                          username: username,
                          password: attemptedPassword || "",
                          role: "employee",
                          firstName,
                          familyName,
                      }
            }
            validationSchema={signUpSchema}
            onSubmit={async (values, actions) => {
                try {
                    actions.setSubmitting(true);
                    await dispatch(initiateSignUp(values));
                } catch (error: any) {
                    actions.setSubmitting(false);
                    if (
                        error.message ===
                        "An account with the given email already exists."
                    ) {
                        actions.setFieldError("username", error.message);
                        dispatch({
                            type: AuthAction.SET_SIGN_UP_ERROR,
                            payload: error.message,
                        });
                    } else if (error.code === "InvalidPasswordException") {
                        actions.setFieldError(
                            "password",
                            error.message.replace(
                                /Password did not conform with policy: /,
                                ""
                            )
                        );
                    }
                    console.warn(error);
                }
            }}
        >
            {({ values, errors, isSubmitting, setFieldValue, touched }) => {
                return (
                    <FForm
                        className="d-flex flex-column align-items-center"
                        noValidate
                    >
                        {isInvite && (
                            <>
                                <p className="text-dark text-center">
                                    You've been invited by{" "}
                                    <strong>{from}</strong> to sign up with Thai
                                    Formality.
                                </p>
                                <p className="text-danger text-center">
                                    By signing up, you agree to allow your
                                    employer, <strong>{company}</strong>, access
                                    to the information you submit on this site.
                                </p>
                                {/* <p className="text-dark text-center">
                  Our privacy policy, terms & conditions, and other information
                  can be found{" "}
                  <Link to="/faq" tabIndex="-1">
                    here
                  </Link>
                  .
                </p> */}
                            </>
                        )}
                        <Form.Group className="mb-2">
                            <Form.Label>Email</Form.Label>
                            <Field
                                autoComplete={
                                    !isInvite ? "username" : undefined
                                }
                                className={`form-control text-center ${
                                    errors.username && touched.username
                                        ? "is-invalid"
                                        : null
                                }`}
                                disabled={isInvite}
                                id="username"
                                name="username"
                                placeholder="somchai@example.com"
                                spellCheck="false"
                                style={{ width: "300px" }}
                                tabIndex="1"
                                type="email"
                            />
                            <div className="formikErrorMessage text-left">
                                <ErrorMessage name="username" />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="d-flex align-items-center mb-2">
                                <span>Password</span>
                                <OverlayTrigger
                                    rootClose
                                    trigger={["click", "focus"]}
                                    placement={width < 576 ? "top" : "right"}
                                    overlay={popover}
                                >
                                    <i
                                        className="material-icons text-info ms-2"
                                        style={{
                                            cursor: "pointer",
                                            fontSize: 20,
                                        }}
                                    >
                                        info
                                    </i>
                                </OverlayTrigger>
                            </Form.Label>
                            <InputGroup>
                                <Field
                                    autoComplete="new-password"
                                    className={`form-control text-center ${
                                        (errors.password && touched.password) ||
                                        (errors.confirmPassword &&
                                            touched.confirmPassword)
                                            ? "is-invalid"
                                            : null
                                    }`}
                                    id="password"
                                    name="password"
                                    placeholder="5@wasd33Krab"
                                    spellCheck="false"
                                    style={{ width: 250 }}
                                    tabIndex="2"
                                    type={showPassword ? "text" : "password"}
                                />
                                <InputGroup.Text id="button-addon1">
                                    <i
                                        className="material-icons text-muted"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        {showPassword
                                            ? "visibility_off"
                                            : "visibility"}
                                    </i>
                                </InputGroup.Text>
                            </InputGroup>
                            <div className="formikErrorMessage text-left">
                                <ErrorMessage name="password" />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <InputGroup>
                                <Field
                                    autoComplete="new-password"
                                    className={`form-control text-center ${
                                        errors.confirmPassword &&
                                        touched.confirmPassword
                                            ? "is-invalid"
                                            : null
                                    }`}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder="confirm password"
                                    spellCheck="false"
                                    style={{ width: 250 }}
                                    tabIndex="3"
                                    type={showPassword ? "text" : "password"}
                                />
                                <InputGroup.Text id="button-addon1">
                                    <i
                                        className="material-icons text-muted"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        {showPassword
                                            ? "visibility_off"
                                            : "visibility"}
                                    </i>
                                </InputGroup.Text>
                            </InputGroup>
                            <div className="formikErrorMessage text-left">
                                <ErrorMessage name="confirmPassword" />
                            </div>
                        </Form.Group>
                        <div style={{ width: 300 }}>
                            <Button
                                className="d-flex align-items-center justify-content-center w-100 mt-2"
                                disabled={isSubmitting}
                                style={{ height: "40px" }}
                                tabIndex={3}
                                type="submit"
                                variant={getButtonColor("primary", isDark)}
                            >
                                {isSubmitting ? (
                                    <Spinner
                                        color={getSpinnerColor(
                                            "primary",
                                            isDark
                                        )}
                                        size={10}
                                        type="beat"
                                    />
                                ) : (
                                    <span>Sign Up</span>
                                )}
                            </Button>
                        </div>
                    </FForm>
                );
            }}
        </Formik>
    );
};

import React from "react";
import { Amplify, Hub, Auth } from "aws-amplify";
import { AppRouter } from "./routers/AppRouter";
import { AuthAction, FormalityAction } from "./types"; //enums
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { createRoot } from "react-dom/client";
import { formalityInit } from "./actions";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import awsconfig from "./aws-exports";
import store from "./store/store";

import "./styles/styles.scss";
import "animate.css";
import "bootstrap/dist/js/bootstrap.bundle";
import ThemeProvider from "./contexts/ThemeProvider";

const isDev = process.env.NODE_ENV === "development";

if (isDev) {
    console.log(
        "%cRemove bootstrap bundle when all is replaced with React Bootstrap",
        "color:hotpink"
    );
}

const container = document.getElementById("root");
const root = createRoot(container!);

const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
if (!CLIENT_ID) {
    throw new Error("Missing paypal client id in env vars!");
}
const initialOptions = {
    clientId: CLIENT_ID,
    components: "buttons",
    currency: "THB",
    intent: "subscription",
    vault: true,
};

root.render(
    <React.StrictMode>
        <ThemeProvider>
            <Provider store={store}>
                <PayPalScriptProvider
                    deferLoading={false}
                    options={initialOptions}
                >
                    <AppRouter />
                </PayPalScriptProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);

const doSignIn = async (cognitoUser: CognitoUser) => {
    const username = cognitoUser.getUsername();

    cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
            // Handle error if necessary
            if (isDev) {
                console.error(err);
            }
            store.dispatch({
                type: FormalityAction.SET_USER_ALERT,
                payload: err.message,
            });
            return;
        }

        const emailAttribute: CognitoUserAttribute | undefined =
            attributes?.find(
                (attr: CognitoUserAttribute) => attr.getName() === "email"
            );

        if (isDev) {
            console.log("🚚 Dispatching auth data to Redux...");
        }
        const attributesObj = attributes?.reduce((acc, { Name, Value }) => {
            acc[Name] = Value;
            return acc;
        }, {} as { [key: string]: any });
        store.dispatch({
            type: AuthAction.SIGN_IN_SUCCESS,
            payload: {
                id: username,
                email: emailAttribute?.getValue() || "",
                attributes: attributesObj,
            },
        });
        store.dispatch({ type: FormalityAction.SET_USER_ALERT, payload: null });
        store.dispatch(formalityInit(cognitoUser));
    });
};

console.log("%c🙏🏽 Welcome to Thai Formality!", "color:violet");
// Amplify.configure(awsconfig);

Hub.listen("auth", async (data) => {
    if (isDev) {
        console.log(
            "%c🎟️ HUB EVENT/PAYLOAD",
            "color:cyan",
            data.payload.event,
            data.payload
        );
    }
    switch (data.payload.event) {
        case "configured":
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();

                if (isDev) {
                    console.log("%c✔️ User is already signed in", "color:cyan");
                }
                doSignIn(cognitoUser);
            } catch (error) {
                if (isDev) {
                    console.log("%c👮 User is not signed in", "color:orange");
                }
            }
            break;
        case "signIn":
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                doSignIn(cognitoUser);
            } catch (error) {
                console.warn(error);
            }
            break;
        case "signOut":
            store.dispatch({ type: AuthAction.SIGN_OUT_SUCCESS });
            break;
        default:
            break;
    }
});

Amplify.configure(awsconfig);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { useContext } from "react";
import { Button } from "react-bootstrap";
import { checkCompanyExists } from "../actions/subscriptionActions";
import { companyRegistrationFormSchema } from "../validators";
import { FormalityAction, SubscriptionAction } from "../types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getButtonColor, getPlanName, getSpinnerColor } from "../helpers";
import { Spinner } from "./shared/Spinner";
import { ThaiAddressSelector } from "./ThaiAddressSelector";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";

export const LinkPaypalSubscriptionConfirmationFormCompany = ({
    subscriptionData,
}) => {
    const { isDark } = useContext(ThemeContext);
    const {
        subscriber: {
            email_address: email,
            name: { given_name: firstName, surname: familyName },
        },
        plan_id: planId,
    } = subscriptionData;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id: currentUserId, isDemo } = useAppSelector((state) => state.auth);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                companyName: "",
                companyNumber: "",
                contactFirstName: firstName ? firstName : "",
                contactFamilyName: familyName ? familyName : "",
                contactEmail: email || "Something's not right...",
                phone: "",
                addressNumber: "",
                road: "",
                subdistrict: "",
                district: "",
                province: "",
                postalCode: "",
                subscriptionPlan: getPlanName(planId) || "",
            }}
            validationSchema={companyRegistrationFormSchema}
            onSubmit={async (values, actions) => {
                try {
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: null,
                    });
                    actions.setSubmitting(true);
                    const companyExists = await dispatch(
                        checkCompanyExists(values.companyNumber)
                    );
                    if (companyExists) {
                        dispatch({
                            type: FormalityAction.SET_USER_ALERT,
                            payload: {
                                alertType: "primary",
                                message:
                                    "A company is already been registered with that TIN",
                                emoticon: "",
                                title: "Problem registering company",
                            },
                        });
                        return;
                    }
                    // await dispatch(saveCompanyRegistrationInformation(values));
                    dispatch({
                        type: SubscriptionAction.UPDATE_COMPANY_SUBSCRIPTION,
                        payload: {
                            ...values,
                            id: values.companyNumber,
                            contactId: currentUserId,
                        },
                    });
                    navigate("/payment");
                } catch (error) {
                    console.warn(error);
                    actions.setSubmitting(false);
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: {
                            alertType: "primary",
                            message:
                                typeof error === "string"
                                    ? error
                                    : error.message,
                            emoticon: "",
                            title: "Problem Registering Company",
                        },
                    });
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldValue,
                touched,
            }) => (
                <Form className="w-100">
                    <div
                        className="display text-center text-muted mt-4 mt-xl-0 mb-4"
                        noValidate
                    >
                        Link Paypal Subscription
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="companyName"
                                    className="form-label"
                                >
                                    Company Name
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.companyName &&
                                        touched.companyName
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="companyName"
                                    name="companyName"
                                    placeholder="ACME Limited"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="companyName" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="companyNumber"
                                    className="form-label"
                                >
                                    Company TIN
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.companyNumber &&
                                        touched.companyNumber
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="companyNumber"
                                    name="companyNumber"
                                    placeholder="0123456789"
                                    spellCheck="false"
                                    type="companyNumber"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="companyNumber" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="contactName"
                                    className="form-label"
                                >
                                    Main Contact
                                </label>
                                <div
                                    className="d-flex justify-content-between"
                                    id="contactName"
                                >
                                    <div className="flex-fill d-flex flex-column">
                                        <Field
                                            className={`form-control text-left ${
                                                errors.contactFirstName &&
                                                touched.contactFirstName
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            id="contactFirstName"
                                            name="contactFirstName"
                                            placeholder="Supicha"
                                            readOnly
                                            spellCheck="false"
                                            type="text"
                                        />
                                        <div className="formikErrorMessage text-left">
                                            <ErrorMessage name="contactFirstName" />
                                        </div>
                                    </div>
                                    <div className="flex-fill d-flex flex-column ms-1">
                                        <Field
                                            className={`form-control text-left ${
                                                errors.contactFamilyName &&
                                                touched.contactFamilyName
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            id="contactFamilyName"
                                            name="contactFamilyName"
                                            placeholder="Jones"
                                            readOnly
                                            spellCheck="false"
                                            type="text"
                                        />
                                        <div className="formikErrorMessage text-left">
                                            <ErrorMessage name="contactFamilyName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="contactEmail"
                                    className="form-label"
                                >
                                    Contact Email
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.contactEmail &&
                                        touched.contactEmail
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="contactEmail"
                                    name="contactEmail"
                                    placeholder="K. Supicha"
                                    spellCheck="false"
                                    type="email"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="contactEmail" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="phone" className="form-label">
                                    Phone
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.phone && touched.phone
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="phone"
                                    name="phone"
                                    placeholder="+66891231234"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="phone" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="subscriptionPlan"
                                    className="form-label"
                                >
                                    Subscription Plan
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.subscriptionPlan &&
                                        touched.subscriptionPlan
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="subscriptionPlan"
                                    name="subscriptionPlan"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="subscriptionPlan" />
                                </div>
                                {/* <Field
                  as="select"
                  children={getPaymentMethodOptions()}
                  className={`form-control text-left ${
                    errors.paymentMethod && touched.paymentMethod
                      ? "is-invalid"
                      : ""
                  }`}
                  id="paymentMethod"
                  name="paymentMethod"
                  // placeholder="+66891231234"
                  spellCheck="false"
                  type="text"
                />
                <div className="formikErrorMessage text-left">
                  <ErrorMessage name="paymentMethod" />
                </div> */}
                            </div>
                        </div>
                        <div className="col-sm">
                            <ThaiAddressSelector
                                formDestination={"registration"}
                                tabIndex="4"
                                values={values}
                            />
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="addressNumber"
                                    className="form-label"
                                >
                                    Address Number
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.addressNumber &&
                                        touched.addressNumber
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="addressNumber"
                                    name="addressNumber"
                                    placeholder="122/3"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="addressNumber" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="road" className="form-label">
                                    Road
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.road && touched.road
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="road"
                                    name="road"
                                    placeholder="Soi 12"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="road" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="subdistrict"
                                    className="form-label"
                                >
                                    Subdistrict (
                                    <span className="font-italic">Tambol</span>/
                                    <span className="font-italic">Khwaeng</span>
                                    )
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.subdistrict &&
                                        touched.subdistrict
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="subdistrict"
                                    name="subdistrict"
                                    placeholder="Changklang"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="subdistrict" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="district"
                                    className="form-label"
                                >
                                    District (
                                    <span className="font-italic">Amphoe</span>/
                                    <span className="font-italic">Khet</span>)
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.district && touched.district
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="district"
                                    name="district"
                                    placeholder="Muang"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="district" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="province"
                                    className="form-label"
                                >
                                    Province (
                                    <span className="font-italic">
                                        Changwat
                                    </span>
                                    )
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.province && touched.province
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="province"
                                    name="province"
                                    placeholder="Chiang Mai"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="province" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="postalCode"
                                    className="form-label"
                                >
                                    Postal Code
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.postalCode && touched.postalCode
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="postalCode"
                                    name="postalCode"
                                    placeholder="50140"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="postalCode" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <Button
                            className="d-flex align-items-center justify-content-center"
                            disabled={isSubmitting}
                            style={{ height: "40px", width: "96px" }}
                            type="submit"
                            variant={getButtonColor("primary", isDark)}
                        >
                            {isSubmitting ? (
                                <Spinner
                                    size={10}
                                    color={getSpinnerColor("primary", isDark)}
                                    type="beat"
                                />
                            ) : (
                                <span>Link</span>
                            )}
                        </Button>
                        <Button
                            className="ms-2"
                            onClick={() => {
                                dispatch({
                                    type: SubscriptionAction.STOP_SUBSCRIPTION_PROCESS,
                                });
                                navigate(-1);
                            }}
                            style={{ height: "40px", width: "96px" }}
                            variant={getButtonColor("primary", isDark)}
                        >
                            Cancel
                        </Button>
                    </div>
                    {isDemo ? (
                        <small className="text-danger">
                            Registration is disabled in demo
                        </small>
                    ) : null}
                </Form>
            )}
        </Formik>
    );
};

import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { confirmVerificationCode } from "../actions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { signUpConfirmationSchema } from "../validators";
import { Spinner } from "./shared/Spinner";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";

export const SignUpConfirmation = () => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const {
        attemptedValues: { username: attemptedUsername },
    } = useAppSelector((state) => state.auth);

    // using this instead of isSubmitting so the button keeps spinning until after the page gets redirected
    const [isSpinning, setIsSpinning] = useState(false);

    return (
        <Formik
            initialValues={{
                code: "",
            }}
            validationSchema={signUpConfirmationSchema}
            onSubmit={async (values, actions) => {
                try {
                    setIsSpinning(true);
                    await dispatch(confirmVerificationCode(values.code));
                } catch (error) {
                    setIsSpinning(false);
                    console.warn(error);
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldError,
                touched,
            }) => (
                <Form
                    className="d-flex flex-column align-items-center animate__animated animate__fadeIn"
                    noValidate
                >
                    <div className="form-group mb-2">
                        <label htmlFor="code" className="form-label">
                            Verification Code
                        </label>
                        <Field
                            aria-describedby="verificationCodeHelp"
                            className={`form-control text-center ${
                                errors.code && touched.code ? "is-invalid" : ""
                            }`}
                            id="code"
                            name="code"
                            placeholder="123456"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            type="text"
                        />
                        {!errors.code ? (
                            <small
                                id="verificationCodeHelp"
                                className="d-flex form-text text-muted"
                                style={{ maxWidth: 300 }}
                            >
                                Check your{" "}
                                {attemptedUsername
                                    ? attemptedUsername.toLowerCase() + " "
                                    : ""}
                                inbox for this
                            </small>
                        ) : null}
                        <div
                            className="d-flex formikErrorMessage text-left"
                            style={{ maxWidth: 300 }}
                        >
                            <ErrorMessage name="code" />
                        </div>
                    </div>
                    <div style={{ width: 300 }}>
                        <Button
                            className={`d-flex align-items-center justify-content-center w-100 mt-3`}
                            disabled={!dirty || isSpinning}
                            style={{ height: "40px" }}
                            type="submit"
                            variant={getButtonColor("primary", isDark)}
                        >
                            {isSpinning ? (
                                <Spinner
                                    color={getSpinnerColor("primary", isDark)}
                                    size={10}
                                    type="beat"
                                />
                            ) : (
                                <span>Confirm Code</span>
                            )}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
// import * as mutations from "../graphql/mutations";
import {
    deleteAllUserForms,
    deleteFormalityCompany,
    deleteFormalityUser,
} from "./formalityActions";
import {
    cancelPaypalSubscription,
    getPaypalSubscriptionTransactions,
    // getPaypalTransactions
} from "./paypalActions";
import { getCompanyData } from "./managerActions";
import { AdminAction, FormalityAction } from "../types";
import { AppThunk } from "../store/store";

const isDev = process.env.NODE_ENV === "development";

interface GetCognitoUsersResponseItem {
    Username: string;
    UserCreateDate: Date;
    UserLastModifiedDate: Date;
    UserStatus: string;
    Enabled: boolean;
    Attributes: { [key: string]: { Name: string; Value: any } };
}

export const getAdminData =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            dispatch({ type: AdminAction.GET_ADMIN_DATA_START });
            const {
                auth: { isAdmin },
            } = getState();
            if (isAdmin) {
                await dispatch(getFormalityCompaniesAdmin());
                await dispatch(getFormalityUsersAdmin());
                await dispatch(getAllCognitoUsersAdmin());
                // const paypalTransactions = await dispatch(getPaypalTransactions());
                // console.warn(paypalTransactions);
                dispatch({ type: AdminAction.GET_ADMIN_DATA_SUCCESS });
            }
            return Promise.resolve();
        } catch (error: any) {
            dispatch({ type: AdminAction.GET_ADMIN_DATA_FAIL, payload: error });
            dispatch({
                type: FormalityAction.SET_USER_ALERT,
                payload: {
                    alertType: "danger",
                    message: error.response
                        ? error.response.data.message
                        : error.message,
                    emoticon: "",
                    title: "Problem loading Admin data",
                },
            });
            return Promise.reject(error);
        }
    };
export const getSavedUserDocumentsAdmin =
    (user: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        try {
            const { id, paypalSubscriptionId, role } = user;

            const getPath = () => {
                if (role || (!role && paypalSubscriptionId)) {
                    return "";
                }
                return "free/";
            };
            dispatch({ type: FormalityAction.GET_SAVED_USER_DOCS_START });
            const docs = await Storage.list(`${getPath()}${id}/`, {
                level: "public",
            });
            dispatch({ type: FormalityAction.GET_SAVED_USER_DOCS_SUCCESS });
            return Promise.resolve(docs);
        } catch (error) {
            dispatch({ type: FormalityAction.GET_SAVED_USER_DOCS_FAIL, error });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const deleteAllSavedDocumentsAdmin =
    (savedUserDocs: any[]): AppThunk<Promise<any>> =>
    async (dispatch) => {
        const deleteDocument = async (key: string) => {
            const response = await Storage.remove(key, {
                level: "public",
            });
            return response;
        };

        try {
            dispatch({ type: FormalityAction.DELETE_ALL_SAVED_DOCS_START });
            const pendingDeletes = savedUserDocs.map((form) =>
                deleteDocument(form.key)
            );
            const deletedDocs = await Promise.all(pendingDeletes);
            dispatch({ type: FormalityAction.DELETE_ALL_SAVED_DOCS_SUCCESS });
            return Promise.resolve(deletedDocs);
        } catch (error) {
            dispatch({ type: FormalityAction.DELETE_ALL_SAVED_DOCS_FAIL });
            console.warn(error);
            return Promise.reject(error);
        }
    };

const removeFormalityUserAdmin =
    (user: any): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            console.log(`🔥 Deleting ${user.id}'s forms...`);
            await dispatch(deleteAllUserForms(user.id));
            console.log(`🙆🏻‍♀️ ${user.id}'s Forms deleted!`);

            const docs = await dispatch(getSavedUserDocumentsAdmin(user));

            if (docs.length) {
                console.log(`🔥 Deleting ${user.id}'s documents...`);
                await dispatch(deleteAllSavedDocumentsAdmin(docs));
                console.log(`🙆🏻‍♀️ ${user.id}'s Documents deleted!`);
            }

            if (user.role === "manager") {
                const companyData = await dispatch(
                    getCompanyData(user.companyId)
                );
                if (companyData) {
                    const {
                        companyName,
                        contact: { id: contactId },
                        paypalSubscriptionId,
                    } = companyData;
                    if (paypalSubscriptionId && contactId === user.id) {
                        const { status } = await dispatch(
                            getPaypalSubscriptionTransactions(
                                paypalSubscriptionId
                            )
                        );
                        if (status === "ACTIVE") {
                            console.log(
                                `🔥 Cancelling ${user.id}'s PayPal subscription ${paypalSubscriptionId}...`
                            );
                            await dispatch(
                                cancelPaypalSubscription(paypalSubscriptionId)
                            );
                            console.log(
                                `🙆🏻‍♀️ ${user.id}'s PayPal subscription ${paypalSubscriptionId} cancelled!`
                            );
                        }
                        if (companyName) {
                            console.warn(user.companyId, companyName);
                            console.log(
                                `🔥 Deleting company: ${companyName}...`
                            );
                            await dispatch(
                                deleteFormalityCompany(user.companyId)
                            );
                            console.log(`🙆🏻‍♀️ ${companyName} deleted!`);
                            dispatch({
                                type: AdminAction.DELETE_ADMIN_COMPANY,
                                payload: user.companyId,
                            });
                        }
                    }
                }
            }

            if (!user.role && user.paypalSubscriptionId) {
                const { status } = await dispatch(
                    getPaypalSubscriptionTransactions(user.paypalSubscriptionId)
                );
                if (status === "ACTIVE") {
                    console.log(
                        `🔥 Cancelling ${user.id}'s PayPal subscription ${user.paypalSubscriptionId}...`
                    );
                    await dispatch(
                        cancelPaypalSubscription(user.paypalSubscriptionId)
                    );
                    console.log(
                        `🙆🏻‍♀️ ${user.id}'s PayPal subscription ${user.paypalSubscriptionId} cancelled!`
                    );
                }
            }

            console.log(`🔥 Deleting Formality user: ${user.id}...`);
            await dispatch(deleteFormalityUser(user.id));
            console.log(`🙆🏻‍♀️ ${user.id} deleted!`);

            console.log("🔥 Deleting Cognito user account...");
            await API.post("thaiFormalityAPI", "/cognito/delete-user", {
                body: {
                    userId: user.id,
                },
            });
            console.log("🙆 User account deleted!");
            dispatch({
                type: AdminAction.DELETE_COGNITO_USER,
                payload: user.id,
            });
            console.log("🙆 State updated");

            return Promise.resolve();
        } catch (error: any) {
            if (error.errors) {
                return Promise.reject(
                    new Error(`Failed to user: ${error.errors[0].message}`)
                );
            } else {
                return Promise.reject(
                    new Error(`Failed to user: ${error.message}`)
                );
            }
        }
    };

export const removeMultipleFormalityUsers =
    (users: any[]): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: AdminAction.REMOVE_MULTIPLE_FORMALITY_USERS_START,
            });
            // const pendingUserDeletes = users.map((user) =>
            //   removeFormalityUser(user.id)
            // );
            // const deletedUsers = Promise.all(pendingUserDeletes);
            // console.warn(deletedUsers);
            const pendingRemovals = users.map(
                async (user) => await dispatch(removeFormalityUserAdmin(user))
            );
            const removedUsers = Promise.all(pendingRemovals);
            console.log(removedUsers);

            dispatch({
                type: AdminAction.REMOVE_MULTIPLE_FORMALITY_USERS_SUCCESS,
            });
            return Promise.resolve();
        } catch (error: any) {
            dispatch({
                type: AdminAction.REMOVE_MULTIPLE_FORMALITY_USERS_FAIL,
                error,
            });
            if (error.errors) {
                dispatch({
                    type: AdminAction.REMOVE_MULTIPLE_FORMALITY_USERS_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(
                    new Error(`Failed to user: ${error.errors[0].message}`)
                );
            } else {
                dispatch({
                    type: AdminAction.REMOVE_MULTIPLE_FORMALITY_USERS_FAIL,
                    payload: error.message,
                });
                return Promise.reject(
                    new Error(`Failed to user: ${error.message}`)
                );
            }
        }
    };

export const removeUserFromApp =
    (userId: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            if (isDev) {
                console.log("%c🤷🏻‍♀️ Fix success dispatch!", "color:hotpink");
            }
            dispatch({ type: AdminAction.REMOVE_USER_FROM_APP_START });
            const {
                data: { getFormalityUser: formalityUser },
            }: GraphQLResult<any> = await API.graphql(
                graphqlOperation(queries.getFormalityUser, { id: userId })
            );
            if (formalityUser) {
                console.warn("Formality User", formalityUser);
                if (formalityUser.role === "manager") {
                    console.log("🙆 User is a manager");
                    const employeesToDelete = await dispatch(
                        getFormalityUsersOfCompany(formalityUser.companyId)
                    );
                    console.warn(employeesToDelete);
                    await dispatch(
                        removeMultipleFormalityUsers(employeesToDelete)
                    );
                } else {
                    console.log("🙆 User is not a manager");
                    await dispatch(removeFormalityUserAdmin(formalityUser));
                    return Promise.resolve();
                }
            } else {
                console.log("🤷🏻‍♀️ User not in database!");
                console.log("🔥 Deleting Cognito user account...");
                await API.post("thaiFormalityAPI", "/cognito/delete-user", {
                    body: {
                        userId,
                    },
                });
                console.log("🙆 User account deleted!");
                dispatch({
                    type: AdminAction.DELETE_COGNITO_USER,
                    payload: userId,
                });
                dispatch({ type: AdminAction.REMOVE_USER_FROM_APP_SUCCESS });
                return Promise.resolve();
            }
            console.log("🤸🏻‍♀️ And we're done!");
        } catch (error) {
            console.warn(error);
            dispatch({ type: AdminAction.REMOVE_USER_FROM_APP_FAIL });
            return Promise.reject(error);
        }
    };

export const getAllCognitoUsersAdmin =
    (): AppThunk<Promise<void>> => async (dispatch) => {
        try {
            dispatch({ type: AdminAction.GET_ALL_COGNITO_USERS_START });
            const response = await API.get(
                "thaiFormalityAPI",
                `/cognito/list-all-users`,
                {}
            );
            const payload = response.users.Users.map(
                (item: GetCognitoUsersResponseItem) => {
                    const attributes: { [key: string]: any } = {};
                    const attributeVals = Object.values(item.Attributes);
                    for (let attribute of attributeVals) {
                        attributes[attribute.Name] = attribute.Value;
                    }
                    return {
                        username: item.Username,
                        createdOn: item.UserCreateDate,
                        lastModified: item.UserLastModifiedDate,
                        userStatus: item.UserStatus,
                        enabled: item.Enabled,
                        attributes,
                    };
                }
            );
            dispatch({
                type: AdminAction.GET_ALL_COGNITO_USERS_SUCCESS,
                payload: { cognitoUsers: payload },
            });
            return Promise.resolve(payload);
        } catch (error) {
            dispatch({
                type: AdminAction.GET_ALL_COGNITO_USERS_FAIL,
                payload: error,
            });
        }
    };

export const getFormalityCompaniesAdmin =
    (): AppThunk<Promise<void>> => async (dispatch) => {
        try {
            dispatch({ type: AdminAction.GET_FORMALITY_COMPANIES_START });
            const {
                data: {
                    listFormalityCompanys: { items },
                },
            }: GraphQLResult<any> = await API.graphql(
                graphqlOperation(queries.listFormalityCompanys, {
                    limit: 50,
                })
            );
            dispatch({
                type: AdminAction.GET_FORMALITY_COMPANIES_SUCCESS,
                payload: { companies: items },
            });
            return Promise.resolve(items);
        } catch (error) {
            dispatch({
                type: AdminAction.GET_FORMALITY_COMPANIES_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const getFormalityUsersAdmin =
    (): AppThunk<Promise<void>> => async (dispatch) => {
        try {
            dispatch({ type: AdminAction.GET_FORMALITY_USERS_START });
            const {
                data: {
                    listFormalityUsers: { items },
                },
            }: GraphQLResult<any> = await API.graphql(
                graphqlOperation(queries.listFormalityUsers, {
                    limit: 50,
                })
            );
            dispatch({
                type: AdminAction.GET_FORMALITY_USERS_SUCCESS,
                payload: { users: items },
            });
            return Promise.resolve(items);
        } catch (error) {
            dispatch({
                type: AdminAction.GET_FORMALITY_USERS_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };
export const getFormalityUsersOfCompany =
    (companyId: string): AppThunk<Promise<any>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: AdminAction.GET_FORMALITY_USERS_OF_COMPANY_START,
            });
            const {
                data: {
                    listFormalityUsers: { items },
                },
            }: GraphQLResult<any> = await API.graphql(
                graphqlOperation(queries.listFormalityUsers, {
                    filter: { companyId: { eq: companyId } },
                    limit: 50,
                })
            );
            if (isDev) {
                console.log(
                    "%cThis dispatch has no receiving reducer!",
                    "color:hotpink",
                    items
                );
            }
            dispatch({
                type: AdminAction.GET_FORMALITY_USERS_OF_COMPANY_SUCCESS,
                payload: { users: items },
            });
            return Promise.resolve(items);
        } catch (error) {
            dispatch({
                type: AdminAction.GET_FORMALITY_USERS_OF_COMPANY_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

import { createAction, createReducer } from "@reduxjs/toolkit";
import {
    AuthAction,
    CompanyEmployee,
    DemoAction,
    SubscriptionAction,
    SubscriptionState,
} from "../types";
import { ManagerAction } from "../types/manager";
const initialState: SubscriptionState = {
    employees: { items: [], nextToken: null },
    forms: [],
    addressNumber: "",
    companyNumber: "",
    contactId: "",
    contactFirstName: "",
    contactFamilyName: "",
    district: "",
    firstName: "",
    familyName: "",
    id: "",
    isSubscribed: "",
    companyName: "",
    paymentMethod: "",
    paypalSubscriptionId: "",
    phone: "",
    postalCode: "",
    province: "",
    road: "",
    subdistrict: "",
    subscriptionPlan: "",
    contact: "",
};
const get_company_manager_data_success = createAction<any>(
    ManagerAction.GET_COMPANY_MANAGER_DATA_SUCCESS
);
const get_company_success = createAction<any>(
    SubscriptionAction.GET_COMPANY_SUCCESS
);
const setup_demo_company = createAction<any>(DemoAction.SETUP_DEMO_COMPANY);
const save_subscription_form_values = createAction<any>(
    SubscriptionAction.SAVE_SUBSCRIPTION_FORM_VALUES
);
const set_registration_address = createAction<any>(
    SubscriptionAction.SET_REGISTRATION_ADDRESS
);
const remove_disconnected_employee = createAction<any>(
    ManagerAction.REMOVE_DISCONNECTED_EMPLOYEE
);
const save_company_registration_info_success = createAction<any>(
    SubscriptionAction.SAVE_COMPANY_REGISTRATION_INFO_SUCCESS
);
const update_company_subscription = createAction<any>(
    SubscriptionAction.UPDATE_COMPANY_SUBSCRIPTION
);
const save_user_to_company = createAction<CompanyEmployee>(
    SubscriptionAction.SAVE_USER_TO_COMPANY
);
const stop_subscription_process = createAction<null>(
    SubscriptionAction.STOP_SUBSCRIPTION_PROCESS
);
const signOut = createAction<null>(AuthAction.SIGN_OUT_SUCCESS);
const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(get_company_manager_data_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(get_company_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(setup_demo_company, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(save_subscription_form_values, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(set_registration_address, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(remove_disconnected_employee, (state, action) => {
            return {
                ...state,
                employees: {
                    items: state.employees.items.filter(
                        (item) => item.id !== action.payload
                    ),
                    nextToken: state.employees.nextToken,
                },
            };
        })
        .addCase(save_company_registration_info_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(update_company_subscription, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(save_user_to_company, (state, action) => {
            return {
                ...state,
                employees: {
                    items: [...state.employees.items, action.payload],
                    nextToken: state.employees.nextToken,
                },
            };
        })
        .addCase(stop_subscription_process, () => {
            return initialState;
        })
        .addCase(signOut, () => {
            return initialState;
        });
});
export default reducer;

import classical from "../images/thai_wai_classical_240h.jpg";
import songkran from "../images/thai_wai_songkran_green_200h.jpg";
import { useWindowSize } from "../hooks/useWindowSize";
import { isIOS } from "react-device-detect";
import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";

interface Props {
    className?: string;
    font?: "normal" | "fancy";
    image?: "classical" | "songkran";
    title?: string;
    noImage?: boolean;
}
export const Header = ({
    className,
    font = "normal",
    image = "classical",
    title,
    noImage = false,
}: Props) => {
    const [, width] = useWindowSize();
    const [fontLoaded, setFontLoaded] = useState(false);

    useEffect(() => {
        const font = new FontFaceObserver("PhadThai");

        font.load(null, 10000).then(() => {
            setFontLoaded(true);
        });
    }, []);

    return (
        <div
            className={`d-flex flex-column align-items-center text-center animate__animated animate__fadeIn ${
                className || ""
            }`}
        >
            {!noImage ? (
                <img
                    src={image === "classical" ? classical : songkran}
                    alt={`Thai Wai (${image})`}
                    height={width < 576 ? "160px" : "240px"}
                    width="auto"
                />
            ) : null}
            {fontLoaded && title && font === "fancy" && (
                <h1
                    className={`header-title my-2 my-sm-3`}
                    style={{ fontWeight: isIOS ? 200 : 100 }}
                >
                    {title}
                </h1>
            )}
            {fontLoaded && title && font !== "fancy" && (
                <h1
                    className={`display my-2 my-sm-3`}
                    style={{ fontWeight: isIOS ? 200 : 100 }}
                >
                    {title}
                </h1>
            )}
            {/* <div className="display-4 text-dark my-3">{title}</div> */}
        </div>
    );
};

import { ColorScheme } from "../types";
import { createContext, useEffect, useState } from "react";
import config from "../config/config.json";
import Cookies from "js-cookie";

export const ThemeContext = createContext({
    isDark: false,
    toggleTheme: () => {},
});

interface Props {
    children: React.ReactNode;
}
export default function ThemeProvider({ children }: Props) {
    const [isDark, setIsDark] = useState(false);

    function addOneYear(date: Date) {
        const dateCopy = new Date(date);
        dateCopy.setFullYear(dateCopy.getFullYear() + 1);
        return dateCopy;
    }

    function toggleTheme() {
        const now = new Date();
        const expires = addOneYear(now);
        const el = document.querySelector("[data-bs-theme]");
        const toggledTheme = isDark ? "light" : "dark";
        el?.setAttribute("data-bs-theme", toggledTheme);
        console.log(
            "%cChanging theme to:",
            "color:cyan",
            toggledTheme === "dark" ? "🌝" : "🌞"
        );
        Cookies.set(COLOR_THEME_COOKIE_NAME, toggledTheme, {
            expires,
            path: "/",
        });
        setIsDark(!isDark);
    }

    const COLOR_THEME_COOKIE_NAME = `${config.COLOR_COOKIE_NAME}-color-theme`;

    useEffect(() => {
        // const now = new Date();
        // const expires = addOneYear(now);
        const cookieVal = Cookies.get(COLOR_THEME_COOKIE_NAME);
        if (cookieVal) {
            if (cookieVal === ColorScheme.DARK) {
                setIsDark(true);
            } else if (cookieVal === ColorScheme.LIGHT) {
                setIsDark(false);
            }
            const el = document.querySelector("[data-bs-theme]");
            el?.setAttribute("data-bs-theme", cookieVal as string);
        } else {
            const getPreferredTheme = () => {
                return window.matchMedia("(prefers-color-scheme: dark)").matches
                    ? ColorScheme.DARK
                    : ColorScheme.LIGHT;
            };
            const preferred = getPreferredTheme();
            // Stop setting cookie automatically here.
            // Cookies.set(COLOR_THEME_COOKIE_NAME, preferred, {
            //     expires,
            //     path: "/",
            // });
            if (preferred === ColorScheme.DARK) {
                setIsDark(true);
            } else if (preferred === ColorScheme.LIGHT) {
                setIsDark(false);
            }
            const el = document.querySelector("[data-bs-theme]");
            el?.setAttribute("data-bs-theme", preferred);
        }
    }, [COLOR_THEME_COOKIE_NAME]);

    return (
        <ThemeContext.Provider value={{ isDark, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}

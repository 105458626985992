import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MobileFormHeader } from "../MobileFormHeader";
import { personalInfoFormSchema } from "../../validators";
import { SaveFormButton } from "../SaveFormButton";
import { saveFormData } from "../../actions";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export const PersonalInfoForm = ({ setScrollTo, title }) => {
    const dispatch = useAppDispatch();
    const [, width] = useWindowSize();

    const {
        attributes: { email, given_name: firstName, family_name: familyName },
    } = useAppSelector((state) => state.auth);
    const { personalInfoFormData: data } = useAppSelector(
        (state) => state.formData
    );
    const { impersonating } = useAppSelector((state) => state.user);

    const [isSaved, setIsSaved] = useState(false);
    const [saveError, setSaveError] = useState("");

    return (
        <Formik
            initialValues={{
                dob: data.dob || "",
                email: impersonating.email ? impersonating.email : email,
                familyName:
                    data.familyName ||
                    impersonating.familyName ||
                    familyName ||
                    "",
                firstName:
                    data.firstName ||
                    impersonating.firstName ||
                    firstName ||
                    "",
                honorific: data.honorific || "",
                middleName: data.middleName || "",
                nationality: data.nationality || "",
                occupation: data.occupation || "",
                phone: data.phone || "",
                stateOfBirth: data.stateOfBirth || "",
                countryOfBirth: data.countryOfBirth || "",
            }}
            validationSchema={personalInfoFormSchema}
            onSubmit={async (values, actions) => {
                try {
                    setSaveError("");
                    setIsSaved(false);
                    actions.setSubmitting(true);
                    await dispatch(
                        saveFormData({
                            data: values,
                            name: "personalInfoFormData",
                        })
                    );
                    setIsSaved(true);
                    if (width >= 576) {
                        setScrollTo("top");
                    }
                    actions.setSubmitting(false);
                } catch (error) {
                    actions.setSubmitting(false);
                    setSaveError(error.message);
                    console.warn(error);
                }
            }}
        >
            {({ dirty, isSubmitting }) => (
                <Form
                    className="animate__animated animate__fadeIn p-3"
                    noValidate
                >
                    <MobileFormHeader title={title} />
                    <div className="form-group mb-2">
                        <label htmlFor="honorific" className="form-label mt-0">
                            Honorific
                        </label>
                        <Field
                            as="select"
                            className="form-select"
                            id="honorific"
                            name="honorific"
                            type="text"
                        >
                            <option value="">Select</option>
                            <option value="miss">Miss</option>
                            <option value="mr">Mr.</option>
                            <option value="mrs">Mrs.</option>
                            <option value="ms">Ms.</option>
                            <option value="">Prefer not to say</option>
                        </Field>
                        <div className="formikErrorMessage">
                            <ErrorMessage name="honorific" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="firstName" className="form-label mt-0">
                            First Name
                        </label>
                        <Field
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="Somchai"
                            spellCheck="false"
                            type="text"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="firstName" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="middleName" className="form-label">
                            Middle Name
                        </label>
                        <Field
                            className="form-control"
                            id="middleName"
                            name="middleName"
                            placeholder="Clarence"
                            spellCheck="false"
                            type="text"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="middleName" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="familyName" className="form-label">
                            Last Name
                        </label>
                        <Field
                            className="form-control"
                            id="familyName"
                            name="familyName"
                            placeholder="Jones"
                            spellCheck="false"
                            type="text"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="familyName" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="dob" className="form-label">
                            Date of Birth
                        </label>
                        <Field
                            className="form-control"
                            id="dob"
                            name="dob"
                            // pattern="\d{4}-\d{2}-\d{2}"
                            required
                            spellCheck="false"
                            type="date"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="dob" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="nationality" className="form-label">
                            Nationality
                        </label>
                        <Field
                            className="form-control"
                            id="nationality"
                            name="nationality"
                            onFocus={() => {
                                if (width > 768) setScrollTo("bottom");
                            }}
                            spellCheck="false"
                            type="text"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="nationality" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="placeOfBirth" className="form-label">
                            Place of Birth
                        </label>
                        <div
                            className="d-flex row justify-content-between"
                            id="placeOfBirth"
                        >
                            <div className="col-sm">
                                <Field
                                    className="form-control"
                                    id="stateOfBirth"
                                    name="stateOfBirth"
                                    placeholder="State/Province"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="stateOfBirth" />
                                </div>
                            </div>
                            <div className="col-sm">
                                <Field
                                    className="form-control"
                                    id="countryOfBirth"
                                    name="countryOfBirth"
                                    placeholder="Country"
                                    spellCheck="false"
                                    type="text"
                                />
                                <div className="formikErrorMessage ms-2">
                                    <ErrorMessage name="countryOfBirth" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="occupation" className="form-label">
                            Occupation
                        </label>
                        <Field
                            className="form-control"
                            id="occupation"
                            name="occupation"
                            placeholder="e.g. Teacher, Information Technology, etc."
                            spellCheck="false"
                            type="text"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="occupation" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <Field
                            className="form-control"
                            disabled
                            id="email"
                            name="email"
                            placeholder="somchai@example.com"
                            spellCheck="false"
                            type="email"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="email" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="phone" className="form-label">
                            Phone
                        </label>
                        <Field
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="+66891231234"
                            spellCheck="false"
                            type="text"
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="phone" />
                        </div>
                    </div>
                    <SaveFormButton
                        dirty={dirty}
                        isSaved={isSaved}
                        isSubmitting={isSubmitting}
                        saveError={saveError}
                    />
                </Form>
            )}
        </Formik>
    );
};

import { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { getCognitoUserDetails } from "../actions";
import { signUpConfirmationSchema } from "../validators";
import { Spinner } from "./shared/Spinner";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";

interface Props {
    setIsDone: (b: boolean) => void;
    isAwaitingConfirmationCode: boolean;
}
export const UpdateUserEmailConfirmation = ({
    setIsDone,
    isAwaitingConfirmationCode,
}: Props) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const {
        attributes: { email, "custom:companyName": companyName },
    } = useAppSelector((state) => state.auth);

    // using this instead of isSubmitting so the button keeps spinning until after the page gets redirected
    const [isSpinning, setIsSpinning] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [isCodeResent, setIsCodeResent] = useState(false);

    return (
        <Formik
            initialValues={{
                code: "",
            }}
            validationSchema={signUpConfirmationSchema}
            onSubmit={async (values, actions) => {
                try {
                    setIsSpinning(true);
                    console.log("Verifying Code");
                    await Auth.verifyCurrentUserAttributeSubmit(
                        "email",
                        values.code
                    );
                    if (companyName === "disconnected") {
                        console.log("Updating company name");
                        const cognitoUser =
                            await Auth.currentAuthenticatedUser();
                        const response = await Auth.updateUserAttributes(
                            cognitoUser,
                            {
                                "custom:companyName": "",
                            }
                        );
                        console.warn("response from cognito", response);
                    }
                    console.log("Updating state with new shit from Cognito");
                    dispatch(getCognitoUserDetails());
                    setIsDone(true);
                } catch (error: any) {
                    setIsSpinning(false);
                    if (error.code === "CodeMismatchException") {
                        actions.setFieldError(
                            "code",
                            "Invalid code.  Please try again."
                        );
                    } else {
                        actions.setFieldError("code", error.message);
                    }
                    console.warn(error);
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldError,
                touched,
            }) => (
                <Form
                    className="d-flex flex-column align-items-center animate__animated animate__fadeIn"
                    noValidate
                >
                    <div className="form-group mb-2">
                        <label htmlFor="code" className="form-label">
                            Verification Code
                        </label>
                        <Field
                            aria-describedby="verificationCodeHelp"
                            className={`form-control text-center ${
                                errors.code && touched.code ? "is-invalid" : ""
                            }`}
                            id="code"
                            name="code"
                            placeholder="123456"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            type="text"
                        />
                        {!errors.code ? (
                            <small
                                id="verificationCodeHelp"
                                className="form-text text-muted"
                                style={{ maxWidth: 300 }}
                            >
                                Check your{" "}
                                {email ? email.toLowerCase() + " " : ""}
                                inbox for this
                            </small>
                        ) : null}
                        <div
                            className="d-flex formikErrorMessage text-left"
                            style={{ maxWidth: 300 }}
                        >
                            <ErrorMessage name="code" />
                        </div>
                    </div>
                    <Button
                        className={`d-flex align-items-center justify-content-center w-100 mt-3`}
                        disabled={!dirty || isSpinning}
                        style={{ height: "40px", maxWidth: 300 }}
                        type="submit"
                        variant={getButtonColor("primary", isDark)}
                    >
                        {isSpinning ? (
                            <Spinner
                                color={getSpinnerColor("primary", isDark)}
                                size={10}
                                type="beat"
                            />
                        ) : (
                            <span>Confirm Code</span>
                        )}
                    </Button>
                    <Button
                        className="custom-link-button d-flex align-items-center justify-content-center mt-2 mt-sm-3"
                        disabled={isCodeResent}
                        onClick={async () => {
                            try {
                                setIsResending(true);
                                await Auth.verifyCurrentUserAttribute("email");
                                setIsResending(false);
                                setIsCodeResent(true);
                            } catch (error) {
                                console.warn(error);
                            }
                        }}
                        style={{ height: 26 }}
                        type="button"
                        variant="link"
                    >
                        {isResending ? (
                            <Spinner
                                size={5}
                                color={getSpinnerColor("primary", isDark)}
                                type="propagate"
                            />
                        ) : (
                            <small>
                                {isAwaitingConfirmationCode
                                    ? isCodeResent
                                        ? "Code Resent"
                                        : "Resend Verification Code"
                                    : null}
                            </small>
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

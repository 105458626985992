import { useContext, useState } from "react";
import { AuthAction } from "../types";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { handleCompleteNewPassword } from "../actions";
import { signInSchema } from "../validators";
import { Spinner } from "./shared/Spinner";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";

export const NewPasswordRequired = ({ setShowForgotPassword }) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const {
        attemptedValues: { username: attemptedUsername },
    } = useAppSelector((state) => state.auth);

    // using this instead of isSubmitting so the button keeps spinning until after the page gets redirected
    const [isSpinning, setIsSpinning] = useState(false);

    return (
        <Formik
            initialValues={{
                username: attemptedUsername || "",
                password: "",
            }}
            validationSchema={signInSchema}
            onSubmit={async (values, actions) => {
                try {
                    setIsSpinning(true);
                    await dispatch(handleCompleteNewPassword(values));
                } catch (error) {
                    setIsSpinning(false);
                    if (error.message === "User does not exist.") {
                        actions.setFieldError(
                            "username",
                            "We can't find that address. Are you new here?"
                        );
                    } else if (error.message === "User is not confirmed.") {
                        actions.setFieldError(
                            "username",
                            "Please confirm verification code."
                        );
                        dispatch({
                            type: AuthAction.SET_UNCONFIRMED_USER,
                            payload: {
                                signUpError: error.message,
                                attemptedValues: values,
                            },
                        });
                    } else {
                        console.warn(error);
                        actions.setFieldError("password", error.message);
                        if (error.message.includes("password")) {
                            setShowForgotPassword(true);
                        }
                    }
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldValue,
                touched,
            }) => (
                <Form
                    className="d-flex flex-column align-items-center"
                    noValidate
                >
                    <div className="form-group mb-2">
                        <label htmlFor="username" className="form-label">
                            Email
                        </label>
                        <Field
                            autoComplete="username"
                            className={`form-control text-center ${
                                errors.username && touched.username
                                    ? "is-invalid"
                                    : null
                            }`}
                            id="username"
                            name="username"
                            placeholder="somchai@example.com"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            tabIndex="1"
                            type="email"
                        />
                        <div className="formikErrorMessage text-left">
                            <ErrorMessage name="username" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="password" className="form-label">
                            Password
                        </label>
                        <Field
                            autoFocus
                            autoComplete="current-password"
                            className={`form-control text-center ${
                                errors.password && touched.password
                                    ? "is-invalid"
                                    : null
                            }`}
                            id="password"
                            name="password"
                            placeholder="5@wasd33Krab"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            tabIndex="2"
                            type="password"
                        />
                        {errors.password && touched.password ? (
                            <div className="formikErrorMessage text-left">
                                <ErrorMessage name="password" />
                            </div>
                        ) : (
                            <small className="text-danger">
                                Please enter new password
                            </small>
                        )}
                    </div>
                    <Button
                        className="d-flex align-items-center justify-content-center w-100 mt-2"
                        disabled={!dirty || isSpinning}
                        style={{ height: "40px" }}
                        tabIndex="3"
                        type="submit"
                        variant={getButtonColor("primary", isDark)}
                    >
                        {isSpinning ? (
                            <Spinner
                                color={getSpinnerColor("primary", isDark)}
                                size={10}
                                type="beat"
                            />
                        ) : (
                            <span>Sign In</span>
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

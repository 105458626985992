import { useContext } from "react";
import { Button } from "react-bootstrap";
import { Spinner } from "./shared/Spinner";
import { getButtonColor, getSpinnerColor, trimText } from "../helpers";
import { useAppSelector } from "../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";
import { ThemeContext } from "../contexts/ThemeProvider";

interface Props {
    isSaved: boolean;
    dirty: boolean;
    isSubmitting: boolean;
    saveError: string;
}
export const SaveFormButton = ({
    isSaved,
    dirty,
    isSubmitting,
    saveError,
}: Props) => {
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { isDemo } = useAppSelector((state) => state.auth);
    const [, width] = useWindowSize();
    const isSmallScreen = width <= 768;
    return (
        <div>
            {saveError || (isSaved && !dirty) ? (
                <div className="d-flex align-items-center mt-3">
                    <Button
                        className={`${isSmallScreen ? "w-100" : ""}`}
                        disabled
                        type="button"
                        style={{ height: 40 }}
                        variant={getButtonColor(
                            saveError ? "danger" : "success",
                            isDark
                        )}
                    >
                        <span>{saveError ? "Error" : "Saved"}</span>
                    </Button>
                    <span>
                        <small className="text-danger ms-3">
                            {saveError ? trimText(25, saveError) : ""}
                        </small>
                    </span>
                </div>
            ) : (
                <Button
                    className={`d-flex align-items-center justify-content-center mt-3 ${
                        isSmallScreen ? "w-100" : ""
                    }`}
                    disabled={!dirty || isSubmitting || isDemo}
                    style={{
                        height: 40,
                        width: isSmallScreen ? "" : 60,
                    }}
                    title={isDemo ? "Disabled in Demo" : "Save Form"}
                    type="submit"
                    variant={getButtonColor("primary", isDark)}
                >
                    {isSubmitting ? (
                        <Spinner
                            size={10}
                            color={getSpinnerColor("primary", isDark)}
                            type="beat"
                        />
                    ) : (
                        <span>Save</span>
                    )}
                </Button>
            )}
            {width <= 768 ? (
                <div className="mt-2 mb-5">
                    <Button
                        className="custom-link-button w-100"
                        onClick={() => navigate(-1)}
                    >
                        {isSaved ? "Return to forms" : "cancel"}
                    </Button>
                    {isDemo ? (
                        <small className="text-danger">
                            Saving is disabled in demo
                        </small>
                    ) : null}
                </div>
            ) : null}
            {isDemo && width > 768 ? (
                <small className="text-danger">
                    Saving is disabled in demo
                </small>
            ) : null}
        </div>
    );
};

import { useEffect, useState } from "react";
import { ApplicationDetails } from "./forms/ApplicationDetails";
import { PersonalInfoForm } from "./forms/PersonalInfoForm";
import { ThaiAddressForm } from "./forms/ThaiAddressForm";
import { TravelDocumentInfo } from "./forms/TravelDocumentInfo";
import { TM7Form } from "./forms/TM7Form";
import { animateScroll as scroll } from "react-scroll";
import { TM8Form } from "./forms/TM8Form";
import { useAppSelector } from "../hooks/reduxHooks";

export const FormSelector = () => {
    const { selectedForm } = useAppSelector((state) => state.formality);

    const [scrollTo, setScrollTo] = useState("");

    useEffect(() => {
        if (scrollTo === "bottom") {
            scroll.scrollToBottom();
        } else if (scrollTo === "top") {
            scroll.scrollToTop();
        }
        return () => setScrollTo("");
    }, [scrollTo]);

    const getSelectedForm = () => {
        if (selectedForm === "appDetails") {
            return (
                <ApplicationDetails
                    setScrollTo={setScrollTo}
                    title="Application Details"
                />
            );
        }
        if (selectedForm === "personal") {
            return (
                <PersonalInfoForm
                    setScrollTo={setScrollTo}
                    title="Personal Info"
                />
            );
        }
        if (selectedForm === "thaiAddress") {
            return (
                <ThaiAddressForm
                    setScrollTo={setScrollTo}
                    title="Thai Address"
                />
            );
        }
        if (selectedForm === "travelDoc") {
            return (
                <TravelDocumentInfo
                    setScrollTo={setScrollTo}
                    title="Travel Document"
                />
            );
        }
        if (selectedForm === "tm_7") {
            return <TM7Form setScrollTo={setScrollTo} title="T.M. 7 Form" />;
        }
        if (selectedForm === "tm_8") {
            return <TM8Form setScrollTo={setScrollTo} title="T.M. 8 Form" />;
        }
        return <p className="lead">There is no spoon.</p>;
    };
    return getSelectedForm();
};

import { Header } from "../components/Header";
// import { useNavigate } from "react-router-dom";

export const Goodbye = () => {
    // const navigate = useNavigate();
    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <Header title="สวัสดี" />
            {/* <button
        className="btn btn-outline-primary"
        onClick={() => navigate("/")}
      >
        Try Again
      </button> */}
        </div>
    );
};

import { useContext } from "react";
import { Button } from "react-bootstrap";
import { FormalityAction } from "../types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { getPaypalSubscriptionDetails } from "../actions";
import { linkPaypalBillingSchema } from "../validators";
import { Spinner } from "./shared/Spinner";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";

export const LinkPaypalSubscriptionForm = ({
    setSubscriptionData,
    setIsLinking,
}) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();

    const { email: currentUserEmail } = useAppSelector((state) => state.auth);

    return (
        <Formik
            initialValues={{
                paypalBillingId: "",
            }}
            validationSchema={linkPaypalBillingSchema}
            onSubmit={async (values, actions) => {
                try {
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: null,
                    });
                    actions.setSubmitting(true);
                    const response = await dispatch(
                        getPaypalSubscriptionDetails(values.paypalBillingId)
                    );
                    if (
                        response.subscriber.email_address !== currentUserEmail
                    ) {
                        dispatch({
                            type: FormalityAction.SET_USER_ALERT,
                            payload: {
                                alertType: "danger",
                                message:
                                    "Cannot find a subscription with that ID",
                                emoticon: "",
                                title: "Problem linking subscription",
                            },
                        });
                    } else {
                        setSubscriptionData(response);
                    }
                } catch (error) {
                    actions.setSubmitting(false);
                    let message = "";
                    if (error.response) {
                        message = error.response.data.message.includes(
                            "does not exist"
                        )
                            ? "Cannot find a subscription with that ID"
                            : error.response.message;
                    } else {
                        message = error.message;
                    }
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: {
                            alertType: "primary",
                            message: message,
                            emoticon: "",
                            title: "Problem linking subscription",
                        },
                    });
                    console.warn(error.response ? error.response : error);
                }
            }}
        >
            {({ values, isSubmitting }) => (
                <Form
                    className="d-flex flex-column flex-grow-1 align-items-center animate__animated animate__fadeIn"
                    noValidate
                >
                    <div className="display text-center text-muted mb-3 mb-sm-5">
                        Link Existing Subscription
                    </div>
                    <div className="form-group mb-3">
                        <label
                            htmlFor="paypalBillingId"
                            className="form-label mt-0"
                        >
                            Paypal Billing ID
                        </label>
                        <Field
                            className="form-control"
                            id="paypalBillingId"
                            name="paypalBillingId"
                            placeholder="I-PG0Y7P184X9X"
                            type="text"
                            style={{ width: 300 }}
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="paypalBillingId" />
                        </div>
                    </div>
                    <Button
                        className="d-flex align-items-center justify-content-center mt-2 mt-sm-3 mb-3"
                        disabled={isSubmitting}
                        type="submit"
                        style={{ height: 40, width: 160 }}
                        variant={getButtonColor("primary", isDark)}
                    >
                        {isSubmitting ? (
                            <Spinner
                                size={10}
                                color={getSpinnerColor("primary", isDark)}
                                type="beat"
                            />
                        ) : (
                            <span>Link Subscription</span>
                        )}
                    </Button>
                    <Button
                        className="custom-link-button mt-3"
                        onClick={() => {
                            setSubscriptionData("");
                            setIsLinking(false);
                        }}
                        type="button"
                        variant="link"
                    >
                        cancel
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

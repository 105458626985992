export enum PayPalAction {
    APPROVE_COMPANY_PAYPAL_SUBSCRIPTION_REVISION_FAIL = "approve_company_paypal_subscription_revision_fail",
    APPROVE_COMPANY_PAYPAL_SUBSCRIPTION_REVISION_START = "approve_company_paypal_subscription_revision_start",
    APPROVE_COMPANY_PAYPAL_SUBSCRIPTION_REVISION_SUCCESS = "approve_company_paypal_subscription_revision_success",
    CANCEL_PAYPAL_SUBSCRIPTION_FAIL = "cancel_paypal_subscription_fail",
    CANCEL_PAYPAL_SUBSCRIPTION_START = "cancel_paypal_subscription_start",
    CANCEL_PAYPAL_SUBSCRIPTION_SUCCESS = "cancel_paypal_subscription_success",
    CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_FAIL = "check_if_paypal_subscription_is_active_fail",
    CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_START = "check_if_paypal_subscription_is_active_start",
    CHECK_IF_PAYPAL_SUBSCRIPTION_IS_ACTIVE_SUCCESS = "check_if_paypal_subscription_is_active_success",
    GET_PAYPAL_SUBSCRIPTION_DETAILS_FAIL = "get_paypal_subscription_details_fail",
    GET_PAYPAL_SUBSCRIPTION_DETAILS_START = "get_paypal_subscription_details_start",
    GET_PAYPAL_SUBSCRIPTION_DETAILS_SUCCESS = "get_paypal_subscription_details_success",
    GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_FAIL = "GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_FAIL",
    GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_START = "GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_START",
    GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_SUCCESS = "GET_PAYPAL_SUBSCRIPTION_TRANSACTIONS_SUCCESS",
    GET_PAYPAL_TOKEN_FAIL = "get_paypal_token_fail",
    GET_PAYPAL_TOKEN_START = "get_paypal_token_start",
    GET_PAYPAL_TOKEN_SUCCESS = "get_paypal_token_success",
    GET_REVISE_COMPANY_PAYPAL_SUBSCRIPTION_LINK_FAIL = "get_revise_company_paypal_subscription_link_fail",
    GET_REVISE_COMPANY_PAYPAL_SUBSCRIPTION_LINK_START = "get_revise_company_paypal_subscription_link_start",
    GET_REVISE_COMPANY_PAYPAL_SUBSCRIPTION_LINK_SUCCESS = "get_revise_company_paypal_subscription_link_success",
}

export interface PayPalState {
    accessToken: string;
    tokenExpires: string;
}

import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { tm8FormSchema } from "../../validators";
import { saveFormData } from "../../actions";
import { SaveFormButton } from "../SaveFormButton";
import { MobileFormHeader } from "../MobileFormHeader";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export const TM8Form = ({ setScrollTo, title }) => {
    const dispatch = useAppDispatch();
    const { tm8FormData: data } = useAppSelector((state) => state.formData);

    const [isSaved, setIsSaved] = useState(false);
    const [saveError, setSaveError] = useState("");

    return (
        <>
            <Formik
                initialValues={{
                    permitType: data.permitType || "",
                    intendedDestination: data.intendedDestination || "",
                    departureDate: data.departureDate || "",
                    returnDate: data.returnDate || "",
                    purposeOfVisa: data.purposeOfVisa || "",
                    modeOfTransportation: data.modeOfTransportation || "",
                }}
                validationSchema={tm8FormSchema}
                onSubmit={async (values, actions) => {
                    try {
                        setIsSaved(false);
                        actions.setSubmitting(true);
                        await dispatch(
                            saveFormData({ name: "tm8FormData", data: values })
                        );
                        actions.setSubmitting(false);
                        setIsSaved(true);
                    } catch (error) {
                        actions.setSubmitting(false);
                        setSaveError(error.message);
                        console.warn(error);
                    }
                }}
            >
                {({ values, dirty, errors, isSubmitting, setFieldError }) => (
                    <Form
                        className="animate__animated animate__fadeIn p-3"
                        noValidate
                    >
                        <MobileFormHeader title={title} />
                        <div className="form-group mb-2">
                            <label htmlFor="permitType" className="form-label">
                                Permit Type
                            </label>
                            <Field
                                as="select"
                                className="form-select"
                                id="permitType"
                                name="permitType"
                                type="text"
                            >
                                <option value="">Select Permit Type</option>
                                <option value="single">Single Re-entry</option>
                                <option value="multiple">
                                    Multiple Re-entry
                                </option>
                            </Field>
                            <div className="formikErrorMessage">
                                <ErrorMessage name="permitType" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="intendedDestination"
                                className="form-label"
                            >
                                Intended Destination
                            </label>
                            <Field
                                className="form-control"
                                id="intendedDestination"
                                name="intendedDestination"
                                placeholder="e.g. Timbuktu"
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="intendedDestination" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="departureDate"
                                className="form-label"
                            >
                                Departure Date
                            </label>
                            <Field
                                className="form-control"
                                id="departureDate"
                                name="departureDate"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="departureDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="modeOfTransportation"
                                className="form-label"
                            >
                                Mode of Transportation (departure)
                            </label>
                            <Field
                                className="form-control"
                                id="modeOfTransportation"
                                name="modeOfTransportation"
                                placeholder="e.g. Air, Land, Sea, etc."
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="modeOfTransportation" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="returnDate" className="form-label">
                                Return Date
                            </label>
                            <Field
                                className="form-control"
                                id="returnDate"
                                name="returnDate"
                                type="date"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="returnDate" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="purposeOfVisa"
                                className="form-label"
                            >
                                Purpose of Visa
                            </label>
                            <Field
                                className="form-control"
                                id="purposeOfVisa"
                                name="purposeOfVisa"
                                placeholder="e.g. Business Trip, Holiday, etc."
                                spellCheck="false"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="purposeOfVisa" />
                            </div>
                        </div>
                        <SaveFormButton
                            dirty={dirty}
                            isSaved={isSaved}
                            isSubmitting={isSubmitting}
                            saveError={saveError}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

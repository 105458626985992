import { configureStore } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import logger from "redux-logger";
import admin from "../reducers/adminReducer";
import auth from "../reducers/authReducer";
import formData from "../reducers/formDataReducer";
import formality from "../reducers/formalityReducer";
import paypal from "../reducers/paypalReducer";
import subscription from "../reducers/subscriptionReducer";
import user from "../reducers/userReducer";

const isDev = process.env.NODE_ENV === "development";

const store = configureStore({
    reducer: {
        admin,
        auth,
        formData,
        formality,
        paypal,
        subscription,
        user,
    },
    devTools: isDev ? true : false,
    middleware: (getDefaultMiddleware) =>
        isDev
            ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
            : getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>;

import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { tm7FormSchema } from "../../validators";
import { saveFormData } from "../../actions";
import { SaveFormButton } from "../SaveFormButton";
import { MobileFormHeader } from "../MobileFormHeader";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export const TM7Form = ({ setScrollTo, title }) => {
    const dispatch = useAppDispatch();
    const { tm7FormData: data } = useAppSelector((state) => state.formData);

    const [isSaved, setIsSaved] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    extensionDays: data.extensionDays || "",
                    reasonForExtension: data.reasonForExtension || "",
                }}
                validationSchema={tm7FormSchema}
                onSubmit={async (values, actions) => {
                    try {
                        setIsSaved(false);
                        actions.setSubmitting(true);
                        await dispatch(
                            saveFormData({ name: "tm7FormData", data: values })
                        );
                        actions.setSubmitting(false);
                        setIsSaved(true);
                    } catch (error) {
                        actions.setSubmitting(false);
                        console.warn(error);
                    }
                }}
            >
                {({ values, dirty, errors, isSubmitting, setFieldError }) => (
                    <Form
                        className="animate__animated animate__fadeIn p-3"
                        noValidate
                    >
                        <MobileFormHeader title={title} />
                        <div className="form-group mb-2">
                            <label
                                htmlFor="extensionDays"
                                className="form-label"
                            >
                                Extension period (days)
                            </label>
                            <Field
                                className="form-control"
                                id="extensionDays"
                                name="extensionDays"
                                placeholder="365"
                                spellCheck="false"
                                type="number"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="extensionDays" />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label
                                htmlFor="reasonForExtension"
                                className="form-label"
                            >
                                Reason for extension
                            </label>
                            <Field
                                className="form-control"
                                id="reasonForExtension"
                                name="reasonForExtension"
                                placeholder="e.g. Take care of family, Work in Thailand"
                                type="text"
                            />
                            <div className="formikErrorMessage">
                                <ErrorMessage name="reasonForExtension" />
                            </div>
                        </div>
                        <SaveFormButton
                            dirty={dirty}
                            isSaved={isSaved}
                            isSubmitting={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export const checkCompanyExists = /* GraphQL */ `
  query GetFormalityCompany($id: ID!) {
    getFormalityCompany(id: $id) {
      id
    }
  }
`;

export const listFormalityCompanyIds = /* GraphQL */ `
  query ListFormalityCompanys($companyId: ID) {
    listFormalityCompanys(filter: { id: { eq: $companyId } }, limit: 50) {
      items {
        id
      }
    }
  }
`;

export enum DemoAction {
    GET_RANDOM_MANAGER_DATA_FAIL = "get_random_manager_data_fail",
    GET_RANDOM_MANAGER_DATA_START = "get_random_manager_data_start",
    GET_RANDOM_MANAGER_DATA_SUCCESS = "get_random_manager_data_success",
    SETUP_DEMO_COMPANY = "setup_demo_company",
    GET_RANDOM_USER_DATA_FAIL = "get_random_user_data_fail",
    GET_RANDOM_USER_DATA_START = "get_random_user_data_start",
    GET_RANDOM_USER_DATA_SUCCESS = "get_random_user_data_success",
}

export enum DemoSetupType {
    MANAGER = "manager",
    INDIVIDUAL = "individual",
}

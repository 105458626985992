import { startSubscription } from "../actions";
import { Button } from "react-bootstrap";
import { getButtonColor } from "../helpers";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch } from "../hooks/reduxHooks";

interface Props {
    blurb: string;
    color: string;
    className: string;
    buyPhrase: string;
    wai: string;
    waiAlt: string;
    planName: string;
    planTagline: string;
    planType: string;
}
export const CompanySubscriptionTierCard = ({
    blurb,
    color,
    className,
    buyPhrase,
    wai,
    waiAlt,
    planName,
    planTagline,
    planType,
}: Props) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const subscribeToPlan = (plan: string) => {
        dispatch(startSubscription(plan));
    };

    return (
        <div
            className={`${className} d-flex card mb-3 mx-1 mx-sm-3 text-center border-${color}`}
            style={{
                width: "18.75rem",
                maxWidth: "18.75rem",
                minWidth: "15rem",
                borderRadius: "8px",
                // marginLeft: "2px",
                // marginRight: "2px"
            }}
        >
            {/* <h3 className="card-header">{planType}</h3> */}
            <div className="card-body">
                <h5 className="text-dark" style={{ fontSize: "2.5rem" }}>
                    {planName}
                </h5>
                <h6 className="card-subtitle text-muted">{planTagline}</h6>
            </div>
            <div className="d-flex justify-content-center w-100">
                <img src={wai} alt={waiAlt} height={150} width="auto" />
            </div>
            <div className="card-body">
                <p className="card-text">{blurb}</p>
            </div>
            <div className="card-body pt-0">
                <Button
                    className={`w-100`}
                    onClick={() => {
                        subscribeToPlan(planName);
                    }}
                    variant={getButtonColor(color, isDark)}
                >
                    {buyPhrase}
                </Button>
            </div>
        </div>
    );
};
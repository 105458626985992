import { Auth } from "aws-amplify";
import { getTitleCase } from "../helpers";
import { AuthAction, FormalityAction } from "../types";
import { AppThunk } from "../store/store";
import {
    CognitoUser,
    CognitoUserSession,
    ISignUpResult,
} from "amazon-cognito-identity-js";

const isDev = process.env.NODE_ENV === "development";

export const confirmVerificationCode =
    (code: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        console.log("fix any in confirmVerificationCode");
        try {
            dispatch({ type: AuthAction.CONFIRM_VERIFICATION_CODE_START });
            const {
                auth: {
                    attemptedValues,
                    attemptedValues: {
                        username: attemptedUsername,
                        password: attemptedPassword,
                    },
                },
            } = getState();
            const response = await Auth.confirmSignUp(
                attemptedUsername.toLowerCase(),
                code
            );
            if (isDev) {
                console.log(response);
            }
            if (attemptedUsername && attemptedPassword) {
                dispatch(signIn(attemptedValues));
            } else {
                dispatch({
                    type: FormalityAction.SET_USER_ALERT,
                    payload: {
                        alertType: "success",
                        message: "Sign in to continue...",
                        emoticon: "",
                        title: "Successful sign up!",
                    },
                });
                dispatch({
                    type: AuthAction.CONFIRM_VERIFICATION_CODE_SUCCESS,
                });
            }
            return Promise.resolve();
        } catch (error: any) {
            console.warn(error);
            dispatch({
                type: AuthAction.CONFIRM_VERIFICATION_CODE_FAIL,
                payload: error,
            });
            dispatch({
                type: FormalityAction.SET_USER_ALERT,
                payload: {
                    alertType: "primary",
                    message: error.message,
                    emoticon: "",
                    title: "Problem confirming code",
                },
            });
            return Promise.reject(error);
        }
    };

export const resendVerificationCode =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        const {
            auth: { attemptedValues },
        } = getState();
        try {
            if (attemptedValues.username) {
                dispatch({
                    type: AuthAction.RESEND_VERIFICATION_CODE_START,
                });
                const response = await Auth.resendSignUp(
                    attemptedValues.username.toLowerCase()
                );
                if (isDev) {
                    console.log(response);
                }
                dispatch({
                    type: AuthAction.RESEND_VERIFICATION_CODE_SUCCESS,
                });
                return Promise.resolve();
            } else {
                throw new Error("Something's not right...");
            }
        } catch (error: any) {
            console.warn(error);
            dispatch({
                type: FormalityAction.SET_USER_ALERT,
                payload: {
                    alertType: "primary",
                    message: error.message,
                    emoticon: "",
                    title: "Problem resending code",
                },
            });
            return Promise.reject(error);
        }
    };

export const handleForgotPassword =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        const {
            auth: {
                attemptedValues: { username: attemptedUsername },
            },
        } = getState();
        try {
            dispatch({ type: FormalityAction.SET_USER_ALERT, payload: "" });
            dispatch({ type: AuthAction.FORGOT_PASSWORD_START });
            await Auth.forgotPassword(attemptedUsername);
            if (isDev) {
                console.log("fix this in handleForgotPassword");
            }
            dispatch({
                type: AuthAction.FORGOT_PASSWORD_SUCCESS,
                payload: true,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: AuthAction.FORGOT_PASSWORD_FAIL, payload: true });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const handleForgotPasswordSubmit =
    (payload: {
        code: string;
        username: string;
        password: string;
    }): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            if (isDev) {
                console.log(
                    "start/fail action types not in reducer in handleForgotPasswordSubmit"
                );
            }
            dispatch({ type: AuthAction.FORGOT_SUBMIT_PASSWORD_START });
            const { code, username, password } = payload;
            await Auth.forgotPasswordSubmit(username, code, password);
            dispatch({ type: AuthAction.FORGOT_SUBMIT_PASSWORD_SUCCESS });
            dispatch({
                type: FormalityAction.SET_USER_ALERT,
                payload: {
                    alertType: "success",
                    message: "You've successfully reset your password.",
                    emoticon: "",
                    title: "New password saved",
                },
            });
            return Promise.resolve();
        } catch (error: any) {
            dispatch({
                type: AuthAction.FORGOT_SUBMIT_PASSWORD_FAIL,
                payload: true,
            });
            dispatch({
                type: FormalityAction.SET_USER_ALERT,
                payload: {
                    alertType: "danger",
                    message: error.message,
                    emoticon: "",
                    title: "Something's not right",
                },
            });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const handleCompleteNewPassword =
    (values: Record<string, any>): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: AuthAction.COMPLETE_NEW_PASSWORD_START });
            dispatch({ type: FormalityAction.SET_USER_ALERT, payload: "" });
            const { cognitoUser } = getState().auth;
            if (!cognitoUser) {
                throw new Error("No cognito user in state!");
            }
            await Auth.completeNewPassword(cognitoUser, values.password);
            dispatch({ type: AuthAction.COMPLETE_NEW_PASSWORD_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: AuthAction.COMPLETE_NEW_PASSWORD_FAIL,
                payload: error,
            });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const signIn =
    (values: Record<string, any>): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            const { password, username } = values;
            dispatch({ type: AuthAction.SIGN_IN_START });
            const cognitoUser: CognitoUser = await Auth.signIn(
                username.toLowerCase(),
                password
            );
            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                dispatch({
                    type: AuthAction.UPDATE_AUTH,
                    payload: {
                        attemptedValues: { username: values.username },
                        newPasswordRequired: true,
                        cognitoUser,
                    },
                });
            }
            dispatch({ type: AuthAction.SIGN_IN_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            dispatch({
                type: AuthAction.SIGN_IN_FAIL,
                payload: {
                    attemptedValues: values,
                    error,
                },
            });
            return Promise.reject(error);
        }
    };

export const initiateSignUp =
    (values: Record<string, any>): AppThunk<Promise<ISignUpResult>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: AuthAction.INITIATE_SIGN_UP_START,
                payload: values,
            });
            if (isDev) {
                console.log("fix any in initiateSignUp");
            }
            const payload: any = {
                username: values.username.toLowerCase(),
                password: values.password,
            };
            if (values.companyId) {
                payload.attributes = {
                    "custom:companyId": values.companyId,
                    "custom:companyName": values.companyName,
                    "custom:role": values.role,
                    given_name: getTitleCase(values.firstName),
                    family_name: getTitleCase(values.familyName),
                };
            }
            const cognitoUser = await Auth.signUp(payload);
            const userConfirmed = cognitoUser?.userConfirmed;

            dispatch({
                type: AuthAction.INITIATE_SIGN_UP_SUCCESS,
                payload: {
                    awaitingSignUpConfirmation: !userConfirmed,
                },
            });
            return Promise.resolve(cognitoUser);
        } catch (error) {
            dispatch({
                type: AuthAction.INITIATE_SIGN_UP_FAIL,
                payload: {
                    error,
                },
            });
            return Promise.reject(error);
        }
    };

export const signOut = (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
        dispatch({ type: AuthAction.SIGN_OUT_START });
        await Auth.signOut();
        dispatch({ type: AuthAction.SIGN_OUT_SUCCESS });
        return Promise.resolve();
    } catch (error) {
        dispatch({ type: AuthAction.SIGN_OUT_FAIL, payload: error });
        return Promise.reject(error);
    }
};

export const getCognitoUserDetails =
    (): AppThunk<Promise<any>> => async (dispatch) => {
        try {
            dispatch({ type: AuthAction.GET_COGNITO_USER_DETAILS_START });
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const payload = {
                id: cognitoUser.username,
                attributes: cognitoUser.attributes,
            };
            dispatch({
                type: AuthAction.GET_COGNITO_USER_DETAILS_SUCCESS,
                payload,
            });
            return Promise.resolve(cognitoUser);
        } catch (error) {
            console.warn(error);
            dispatch({
                type: AuthAction.GET_COGNITO_USER_DETAILS_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const refreshCognitoToken =
    (): AppThunk<Promise<void>> => async (dispatch) => {
        try {
            const cognitoUser: CognitoUser =
                await Auth.currentAuthenticatedUser();
            const session: CognitoUserSession = await Auth.currentSession();
            const refreshToken = session.getRefreshToken();
            cognitoUser.refreshSession(refreshToken, (error: Error | null) => {
                if (error) {
                    console.warn(error);
                } else {
                    console.log("🙆 Tokens refreshed!");
                }
            });
            return Promise.resolve();
        } catch (error) {
            console.warn(error);
            return Promise.reject(error);
        }
    };

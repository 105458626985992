import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { getButtonColor } from "../helpers";
import { ThemeContext } from "../contexts/ThemeProvider";
import { UpdateUserEmailConfirmation } from "../components/UpdateUserEmailConfirmation";
import { UpdateUserEmailForm } from "../components/UpdateUserEmailForm";
import { useNavigate } from "react-router-dom";

interface Props {
    navbarOffset: number;
}
export const UpdateUserEmailPage = ({ navbarOffset }: Props) => {
    const {isDark} = useContext(ThemeContext)
    const navigate = useNavigate();

    const [isAwaitingConfirmationCode, setIsAwaitingConfirmationCode] =
        useState(false);
    const [isDone, setIsDone] = useState(false);

    return (
        <div
            className="container d-flex flex-column align-items-center center-vertical-with-nav"
            style={{ paddingTop: navbarOffset }}
        >
            <div className="display text-muted mb-3 mb-sm-5 text-center">
                Update Email
            </div>
            {isDone ? (
                <>
                    <p className="lead text-center">Email update successful</p>
                    <Button
                        onClick={() => navigate("/")}
                        variant={getButtonColor("primary", isDark)}
                    >
                        Continue
                    </Button>
                </>
            ) : null}
            {!isDone && isAwaitingConfirmationCode ? (
                <UpdateUserEmailConfirmation
                    isAwaitingConfirmationCode={isAwaitingConfirmationCode}
                    setIsDone={setIsDone}
                />
            ) : null}
            {!isDone && !isAwaitingConfirmationCode ? (
                <UpdateUserEmailForm
                    setIsAwaitingConfirmationCode={
                        setIsAwaitingConfirmationCode
                    }
                    isAwaitingConfirmationCode={isAwaitingConfirmationCode}
                />
            ) : null}
        </div>
    );
};

import { FormSelector } from "../components/FormSelector";
import { Footer } from "../components/shared/Footer";

interface Props {
    navbarOffset: number;
}
export const MobileFormContainer = ({ navbarOffset }: Props) => {
    return (
        <div className="container mb-3" style={{ paddingTop: navbarOffset }}>
            <FormSelector />
            <Footer />
        </div>
    );
};

import { useEffect, useState, useCallback } from "react";
import isOnline from "is-online";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "./reduxHooks";

export const useIsConnected = () => {
    const location = useLocation();
    const { isDemo } = useAppSelector((state) => state.auth);
    const [isConnected, setIsConnected] = useState(true);
    const checkInternetConnection = useCallback(async () => {
        try {
            if (location.pathname && !isDemo) {
                const result = await isOnline();
                console.log("🌐 Internet is connected: ", result);
                if (result) {
                    setIsConnected(true);
                } else {
                    setIsConnected(false);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }, [isDemo, location.pathname]);
    useEffect(() => {
        checkInternetConnection();
        return () => {};
    }, [checkInternetConnection]);
    return { isConnected, checkInternetConnection };
};

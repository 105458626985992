import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";
import wai from "../images/thai_wai_classical_240h.jpg";
import { Button } from "react-bootstrap";

export const LandingPage = () => {
    const navigate = useNavigate();
    const [, width] = useWindowSize();
    return (
        <div
            className={`d-flex flex-column align-items-center justify-content-center vh-100`}
            style={{ height: "480px", width: "100%" }}
        >
            <img
                className="mb- mb-sm-3"
                src={wai}
                alt="Thai Wai Classical"
                height={width < 576 ? "160px" : "240px"}
                width="auto"
            />
            <div className="d-flex d-sm-none tutorial-title">
                Thai Formality
            </div>
            <div className="d-none d-sm-flex tutorial-title">
                Thai Formality
            </div>
            {/* <p className="tutorial-lead">
        Thai Formality makes Thai Immigration documentation preparation easier.
      </p>
      <p className="tutorial-lead">
        For individuals and employers, things can be better.
      </p> */}
            <div className="d-flex container justify-content-center mb-3">
                <Button
                    className={`${width < 576 ? "btn-sm" : ""}`}
                    onClick={() => {
                        navigate("/", { state: { loginType: "individual" } });
                    }}
                    variant="outline-primary"
                >
                    Individual
                </Button>
                <Button
                    className={`d-flex container justify-content-center mb-3 ${
                        width < 576 ? "btn-sm" : ""
                    }`}
                    onClick={() => {
                        navigate("/", { state: { loginType: "company" } });
                    }}
                    variant="outline-secondary"
                >
                    Company
                </Button>
            </div>
        </div>
    );
};

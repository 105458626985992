import { createAction, createReducer } from "@reduxjs/toolkit";
import {
    AuthAction,
    FormalityAction,
    FormalityAlert,
    FormalityEvent,
    FormalityForm,
    FormalityState,
    SubscriptionAction,
} from "../types";
const initialState: FormalityState = {
    alert: undefined,
    formToUpload: undefined,
    formUrl: "",
    hasPrintError: false,
    hasUploadError: false,
    isLoading: false,
    isProcessing: false,
    isRegistered: false,
    isSubscribing: false,
    isUploading: false,
    printError: "",
    savedUserDocs: [],
    selectedEvent: undefined,
    selectedForm: "appDetails",
    selectedPlan: "",
    subscribingAs: "",
};
const delete_all_user_forms_success = createAction<null>(
    FormalityAction.DELETE_ALL_USER_FORMS_SUCCESS
);
const get_saved_user_docs_success = createAction<any>(
    FormalityAction.GET_SAVED_USER_DOCS_SUCCESS
);
const delete_saved_form_success = createAction<string>(
    FormalityAction.DELETE_SAVED_FORM_SUCCESS
);
const formality_init_start = createAction<null>(
    FormalityAction.FORMALITY_INIT_START
);
const formality_init_success = createAction<null>(
    FormalityAction.FORMALITY_INIT_SUCCESS
);
const reset_saved_user_docs = createAction<null>(
    FormalityAction.RESET_SAVED_USER_DOCS
);
const print_form_fail = createAction<string>(FormalityAction.PRINT_FORM_FAIL);
const print_form_start = createAction<null>(FormalityAction.PRINT_FORM_START);
const print_form_success = createAction<null>(
    FormalityAction.PRINT_FORM_SUCCESS
);
const save_form_url = createAction<string>(FormalityAction.SAVE_FORM_URL);
const set_form_url = createAction<string>(FormalityAction.SET_FORM_URL);
const set_form_to_upload = createAction<FormalityForm>(
    FormalityAction.SET_FORM_TO_UPLOAD
);
const set_print_error = createAction<string>(FormalityAction.SET_PRINT_ERROR);
const set_selected_event = createAction<FormalityEvent>(
    FormalityAction.SET_SELECTED_EVENT
);
const set_selected_form = createAction<string>(
    FormalityAction.SET_SELECTED_FORM
);
const set_user_alert = createAction<FormalityAlert>(
    FormalityAction.SET_USER_ALERT
);
const start_subscription_process = createAction<string>(
    SubscriptionAction.START_SUBSCRIPTION_PROCESS
);
const stop_subscription_process = createAction<null>(
    SubscriptionAction.STOP_SUBSCRIPTION_PROCESS
);
const upload_form_success = createAction<{ key: string; lastModified: Date }>(
    FormalityAction.UPLOAD_FORM_SUCCESS
);
const signOut = createAction<null>(AuthAction.SIGN_OUT_SUCCESS);
const reducer = createReducer({ ...initialState }, (builder) => {
    builder
        .addCase(delete_all_user_forms_success, (state) => {
            return {
                ...state,
                savedUserDocs: [],
            };
        })
        .addCase(delete_saved_form_success, (state, action) => {
            return {
                ...state,
                savedUserDocs: state.savedUserDocs.filter(
                    (item) => item.key !== action.payload
                ),
            };
        })
        .addCase(formality_init_start, (state) => {
            return {
                ...state,
                isLoading: true,
            };
        })
        .addCase(formality_init_success, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        })
        .addCase(get_saved_user_docs_success, (state, action) => {
            console.log("%cGet this type right!", action.payload);
            return { ...state, savedUserDocs: action.payload };
        })
        .addCase(upload_form_success, (state, action) => {
            return {
                ...state,
                savedUserDocs: [...state.savedUserDocs, action.payload],
            };
        })
        .addCase(print_form_fail, (state, action) => {
            console.log(
                "%cAdd this to state!",
                "color:hotpink",
                action.payload
            );
            return {
                ...state,
                isProcessing: false,
                hasPrintError: true,
                printError: action.payload,
            };
        })
        .addCase(print_form_start, (state) => {
            return {
                ...state,
                isProcessing: true,
                hasPrintError: false,
                printError: "",
            };
        })
        .addCase(print_form_success, (state) => {
            return {
                ...state,
                isProcessing: false,
                hasPrintError: false,
                printError: "",
            };
        })
        .addCase(reset_saved_user_docs, (state) => {
            return {
                ...state,
                savedUserDocs: [],
            };
        })
        .addCase(save_form_url, (state, action) => {
            return { ...state, formUrl: action.payload };
        })
        .addCase(set_form_url, (state, action) => {
            return { ...state, formUrl: action.payload };
        })
        .addCase(set_form_to_upload, (state, action) => {
            return { ...state, formToUpload: action.payload };
        })
        .addCase(set_print_error, (state, action) => {
            return {
                ...state,
                isProcessing: false,
                hasPrintError: true,
                printError: action.payload,
            };
        })
        .addCase(set_selected_event, (state, action) => {
            return {
                ...state,
                selectedEvent: action.payload,
            };
        })
        .addCase(set_selected_form, (state, action) => {
            return {
                ...state,
                selectedForm: action.payload,
            };
        })
        .addCase(set_user_alert, (state, action) => {
            return { ...state, alert: action.payload };
        })
        .addCase(start_subscription_process, (state, action) => {
            return {
                ...state,
                isSubscribing: true,
                selectedPlan: action.payload,
                subscribingAs:
                    action.payload === "ฝรั่งขี้นก" ? "individual" : "company",
            };
        })
        .addCase(stop_subscription_process, (state) => {
            return {
                ...state,
                isSubscribing: false,
                selectedPlan: "",
                subscribingAs: "",
            };
        })
        .addCase(signOut, () => {
            return initialState;
        });
});
export default reducer;

import { useState } from "react";
import { Formik, Form, Field } from "formik";
import { applicationDetailsFormSchema } from "../../validators";
import { saveFormData } from "../../actions";
import { SaveFormButton } from "../SaveFormButton";
import { MobileFormHeader } from "../MobileFormHeader";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export const ApplicationDetails = ({ title }) => {
    const dispatch = useAppDispatch();
    const {
        applicationDetailsFormData: { applicationDate, writtenAt },
    } = useAppSelector((state) => state.formData);

    const [isSaved, setIsSaved] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    applicationDate: applicationDate || "",
                    writtenAt: writtenAt || "",
                }}
                validationSchema={applicationDetailsFormSchema}
                onSubmit={async (values, actions) => {
                    try {
                        setIsSaved(false);
                        actions.setSubmitting(true);
                        await dispatch(
                            saveFormData({
                                name: "applicationDetailsFormData",
                                data: values,
                            })
                        );
                        actions.setSubmitting(false);
                        setIsSaved(true);
                    } catch (error) {
                        actions.setSubmitting(false);
                        console.warn(error);
                    }
                }}
            >
                {({ dirty, isSubmitting }) => (
                    <Form
                        className="animate__animated animate__fadeIn p-3"
                        noValidate
                    >
                        <MobileFormHeader title={title} />
                        <div className="form-group mb-2">
                            <label
                                htmlFor="applicationDate"
                                className="form-label"
                            >
                                Application Date
                            </label>
                            <Field
                                aria-describedby="applicationDateHelp"
                                className="form-control"
                                id="applicationDate"
                                name="applicationDate"
                                type="date"
                            />
                            <small
                                id="applicationDateHelp"
                                className="form-text text-muted"
                            >
                                Leave blank to fill in by hand
                            </small>
                            {/* <div className="formikErrorMessage">
                <ErrorMessage name="applicationDate" />
              </div> */}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="writtenAt" className="form-label">
                                Written at
                            </label>
                            <Field
                                aria-describedby="writtenAtHelp"
                                className="form-control"
                                id="writtenAt"
                                name="writtenAt"
                                placeholder="e.g. Chiang Mai Immigration"
                                spellCheck="false"
                                type="text"
                            />
                            <small
                                id="writtenAtHelp"
                                className="form-text text-muted"
                            >
                                Leave blank to fill in by hand
                            </small>
                            {/* <div className="formikErrorMessage">
                <ErrorMessage name="writtenAt" />
              </div> */}
                        </div>
                        <SaveFormButton
                            dirty={dirty}
                            isSaved={isSaved}
                            isSubmitting={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

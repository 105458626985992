import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";

export enum AuthAction {
    COMPLETE_NEW_PASSWORD_FAIL = "complete_new_password_fail",
    COMPLETE_NEW_PASSWORD_START = "complete_new_password_start",
    COMPLETE_NEW_PASSWORD_SUCCESS = "complete_new_password_success",
    CONFIRM_VERIFICATION_CODE_FAIL = "confirm_verification_code_fail",
    CONFIRM_VERIFICATION_CODE_START = "confirm_verification_code_start",
    CONFIRM_VERIFICATION_CODE_STOP = "confirm_verification_code_stop",
    CONFIRM_VERIFICATION_CODE_SUCCESS = "confirm_verification_code_success",
    FORGOT_PASSWORD_FAIL = "forgot_password_fail",
    FORGOT_PASSWORD_START = "forgot_password_start",
    FORGOT_PASSWORD_SUCCESS = "forgot_password_start",
    FORGOT_SUBMIT_PASSWORD_FAIL = "forgot_submit_password_fail",
    FORGOT_SUBMIT_PASSWORD_START = "forgot_submit_password_start",
    FORGOT_SUBMIT_PASSWORD_SUCCESS = "forgot_submit_password_success",
    GET_COGNITO_USER_DETAILS_FAIL = "get_cognito_user_details_fail",
    GET_COGNITO_USER_DETAILS_START = "get_cognito_user_details_start",
    GET_COGNITO_USER_DETAILS_SUCCESS = "get_cognito_user_details_success",
    INITIATE_SIGN_UP_FAIL = "initiate_sign_up_fail",
    INITIATE_SIGN_UP_START = "initiate_sign_up_start",
    INITIATE_SIGN_UP_SUCCESS = "initiate_sign_up_success",
    RESEND_VERIFICATION_CODE_FAIL = "resend_verification_code_fail",
    RESEND_VERIFICATION_CODE_START = "resend_verification_code_start",
    RESEND_VERIFICATION_CODE_SUCCESS = "resend_verification_code_success",
    RESET_AUTH = "reset_auth",
    SET_SIGN_UP_ERROR = "set_sign_up_error",
    SET_UNCONFIRMED_USER = "set_unconfirmed_user",
    SETUP_DEMO_AUTH = "setup_demo_auth",
    SIGN_IN_FAIL = "sign_in_fail",
    SIGN_IN_START = "sign_in_start",
    SIGN_IN_SUCCESS = "sign_in_success",
    SIGN_OUT_FAIL = "sign_out_fail",
    SIGN_OUT_START = "sign_out_start",
    SIGN_OUT_SUCCESS = "sign_out_success",
    UPDATE_AUTH = "update_auth",
}

export interface AuthState {
    attemptedValues: Record<string, any>;
    attributes: Record<string, any>;
    awaitingSignUpConfirmation: boolean;
    cognitoUser: CognitoUser | undefined;
    codeResent: boolean;
    confirmationError: string;
    email: string;
    forgotPassword: boolean;
    id: string;
    isAdmin: boolean;
    isDemo: boolean;
    newPasswordRequired: boolean;
    signInError: string;
    signUpError: string;
}

export type AuthUser = {
    id: string;
    attributes: CognitoUserAttribute[];
};

export type SignupPayload = Record<string, any>;

import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { AppThunk } from "../store/store";
import {
    AuthAction,
    FormDataAction,
    FormalityAction,
    UserAction,
} from "../types";
import { getAdminData } from "./adminActions";
import { getCognitoUserDetails } from "./authActions";
import { getManagerCompanyData } from "./managerActions";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { omit, omitBy, isNil } from "lodash";
import { paypalSubscriptionIsActive } from "./paypalActions";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

const isDev = process.env.NODE_ENV === "development";

export const formalityInit =
    (cognitoUser: any): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: FormalityAction.FORMALITY_INIT_START });
            const session = await Auth.currentSession();
            const { "cognito:groups": groups } = session.getIdToken().payload;
            if (groups && groups.includes("Admins")) {
                console.log("🕴️️ User is an Admin!");
                dispatch({
                    type: AuthAction.UPDATE_AUTH,
                    payload: { isAdmin: true },
                });
                await dispatch(getAdminData());
                dispatch({ type: FormalityAction.FORMALITY_INIT_SUCCESS });
                return;
            }
            console.log("🕵️‍♀️ Checking for existing user in db");
            const user = await dispatch(getFormalityUser(cognitoUser.username));
            if (user) {
                console.log(`🙆 User, ${user.email}, found in db!`);

                if (user.role === "manager") {
                    console.log("🤵 User is manager, fetching company data.");
                    await dispatch(getManagerCompanyData());
                } else {
                    console.log("🐕 Fetching saved user documents...");
                    const docs = await dispatch(
                        getSavedUserDocuments(cognitoUser.username)
                    );
                    console.log(
                        docs.length
                            ? `🦴 Retrieved ${docs.length} documents`
                            : "🤷 User has no saved documents."
                    );
                }
                if (user.subscriptionPlan || user.role === "manager") {
                    console.log("📰 User is Subscriber");
                    console.log(`🕵🏻‍♀️ Checking subscription status...`);
                    const isActive = await dispatch(
                        paypalSubscriptionIsActive()
                    );
                    if (!isActive) {
                        dispatch({
                            type: FormalityAction.SET_USER_ALERT,
                            payload: {
                                alertType: "danger",
                                message: "Your subscription is not active",
                                emoticon: "",
                                title: "Problem with subscription",
                            },
                        });
                    } else {
                        if (isDev) {
                            console.log(
                                `🤑 Fetching Paypal subscription details...`
                            );
                            console.warn("do something here!");
                        }
                    }
                    // const subscription = await dispatch(getPaypalSubscriptionDetails());
                    // console.log(`👍 User is subscribed!...`, subscription);
                }
            } else {
                const newUser = await dispatch(
                    createFormalityUser({
                        id: cognitoUser.username,
                        email: cognitoUser.attributes.email,
                        companyId: cognitoUser.attributes["custom:companyId"],
                        firstName: cognitoUser.attributes.given_name,
                        familyName: cognitoUser.attributes.family_name,
                        role: cognitoUser.attributes["custom:role"],
                    })
                );
                console.log(
                    `👶 New user, ${newUser.email}, created in db${
                        cognitoUser.attributes["custom:role"] === "employee"
                            ? " as an employee!"
                            : "!"
                    }!`
                );
            }
            dispatch({ type: FormalityAction.FORMALITY_INIT_SUCCESS });
            return Promise.resolve();
        } catch (error) {
            console.warn(error);
            dispatch({
                type: FormalityAction.FORMALITY_INIT_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const changeUserEmailAddress =
    (email: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            await dispatch(updateFormalityUser({ email }));
            return Promise.resolve();
        } catch (error) {
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const createFormalityUser =
    (user: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        try {
            dispatch({ type: FormalityAction.CREATE_FORMALITY_USER_START });
            const data: GraphQLResult<any> = await API.graphql(
                graphqlOperation(mutations.createFormalityUser, { input: user })
            );
            const userData = data.data.createFormalityUser;
            dispatch({ type: FormalityAction.CREATE_FORMALITY_USER_SUCCESS });
            return Promise.resolve(userData);
        } catch (error: any) {
            dispatch({ type: FormalityAction.CREATE_FORMALITY_USER_FAIL });
            return Promise.reject(
                new Error(`Failed to create user: ${error.errors[0].message}`)
            );
        }
    };

export const deleteFormalityCompany =
    (id: string): AppThunk<Promise<any>> =>
    async (dispatch) => {
        try {
            dispatch({ type: UserAction.GET_FORMALITY_USER_START });
            const data: GraphQLResult<any> = await API.graphql(
                graphqlOperation(mutations.deleteFormalityCompany, {
                    input: { id },
                })
            );

            const companyData = data.data.deleteFormalityCompany;
            dispatch({ type: FormalityAction.DELETE_FORMALITY_COMPANY_START });
            return Promise.resolve(companyData);
        } catch (error: any) {
            if (error.errors) {
                dispatch({
                    type: FormalityAction.DELETE_FORMALITY_COMPANY_SUCCESS,
                    payload: error.errors[0].message,
                });
                return Promise.reject(
                    new Error(
                        `Failed to delete user: ${error.errors[0].message}`
                    )
                );
            } else {
                dispatch({
                    type: FormalityAction.DELETE_FORMALITY_COMPANY_FAIL,
                    payload: error.message,
                });
                return Promise.reject(
                    new Error(`Failed to delete user: ${error.message}`)
                );
            }
        }
    };

export const deleteFormalityUser =
    (id: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: FormalityAction.DELETE_FORMALITY_USER_START });
            const data: GraphQLResult<any> = await API.graphql(
                graphqlOperation(mutations.deleteFormalityUser, {
                    input: { id },
                })
            );
            const userData = data.data.deleteFormalityUser;
            dispatch({ type: FormalityAction.DELETE_FORMALITY_USER_SUCCESS });
            return Promise.resolve(userData);
        } catch (error: any) {
            if (error.errors) {
                dispatch({
                    type: FormalityAction.DELETE_FORMALITY_USER_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(
                    new Error(`Failed to user: ${error.errors[0].message}`)
                );
            } else {
                dispatch({
                    type: FormalityAction.DELETE_FORMALITY_USER_FAIL,
                    payload: error.message,
                });
                return Promise.reject(
                    new Error(`Failed to user: ${error.message}`)
                );
            }
        }
    };

export const getFormalityUser =
    (id: string): AppThunk<Promise<any>> =>
    async (dispatch) => {
        try {
            dispatch({ type: UserAction.GET_FORMALITY_USER_START });
            const data: GraphQLResult<any> = await API.graphql(
                graphqlOperation(queries.getFormalityUser, { id })
            );
            if (!data.data.getFormalityUser) {
                return Promise.resolve(null);
            }
            const userData = data.data.getFormalityUser;
            const payload = omitBy(userData, isNil);
            payload.cookiesAccepted = userData.cookiesAccepted;
            dispatch({
                type: UserAction.GET_FORMALITY_USER_SUCCESS,
                payload: omit(payload, "forms"),
            });

            const formData: Record<string, any> = {};
            for (let item of userData.forms.items) {
                formData[item.name] = JSON.parse(item.data);
            }
            console.log("%cFormData", "color:hotpink", formData);
            dispatch({
                type: FormDataAction.SET_USER_INIT_FORM_DATA,
                payload: formData,
            });

            return Promise.resolve(userData);
        } catch (error: any) {
            if (error.errors) {
                dispatch({
                    type: UserAction.GET_FORMALITY_USER_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(
                    new Error(`Failed to get user: ${error.errors[0].message}`)
                );
            } else {
                dispatch({
                    type: UserAction.GET_FORMALITY_USER_FAIL,
                    payload: error,
                });
                return Promise.reject(error);
            }
        }
    };
export const getSavedUserDocuments =
    (employeeId: string): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            const {
                auth: {
                    id,
                    isDemo,
                    attributes: { "custom:role": role },
                },
                user: { paypalSubscriptionId },
            } = getState();

            const getPath = () => {
                if (isDemo) {
                    return "demo/";
                }
                if (role || (!role && paypalSubscriptionId)) {
                    return "";
                }
                return "free/";
            };
            dispatch({ type: FormalityAction.GET_SAVED_USER_DOCS_START });
            const docs = await Storage.list(
                `${getPath()}${employeeId ? employeeId : id}/`,
                {
                    level: "public",
                    pageSize: "ALL",
                }
            );
            dispatch({
                type: FormalityAction.GET_SAVED_USER_DOCS_SUCCESS,
                payload: docs.results,
            });
            return Promise.resolve(docs.results);
        } catch (error) {
            dispatch({ type: FormalityAction.GET_SAVED_USER_DOCS_FAIL, error });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const deleteAllSavedDocuments =
    (): AppThunk<Promise<any[]>> => async (dispatch, getState) => {
        const deleteDocument = async (key: string) => {
            const response = await Storage.remove(key, {
                level: "public",
            });
            return response;
        };

        try {
            dispatch({ type: FormalityAction.DELETE_ALL_SAVED_DOCS_START });
            const {
                formality: { savedUserDocs },
            } = getState();
            const pendingDeletes = savedUserDocs.map(
                (form: Record<string, any>) => deleteDocument(form.key)
            );
            const deletedDocs = await Promise.all(pendingDeletes);
            dispatch({ type: FormalityAction.DELETE_ALL_SAVED_DOCS_SUCCESS });
            return Promise.resolve(deletedDocs);
        } catch (error) {
            dispatch({ type: FormalityAction.DELETE_ALL_SAVED_DOCS_FAIL });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const deleteAllUserForms =
    (userId: string): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        const deleteForm = async (formId: string) => {
            const response = await API.graphql(
                graphqlOperation(mutations.deleteFormalityForm, {
                    input: { id: formId },
                })
            );
            return Promise.resolve(response);
        };

        try {
            dispatch({ type: FormalityAction.DELETE_ALL_USER_FORMS_START });
            const { id } = getState().auth;
            const data: GraphQLResult<any> = await API.graphql(
                graphqlOperation(queries.listFormalityForms, {
                    filter: { userId: { eq: userId ? userId : id } },
                    limit: 50,
                })
            );
            if (!data.data.listFormalityForms.items.length) {
                return Promise.resolve();
            }
            const formIds = data.data.listFormalityForms.items.map(
                (item: Record<string, any>) => item.id
            );
            const pendingDeletes = formIds.map((item: string) =>
                deleteForm(item)
            );
            await Promise.all(pendingDeletes);
            dispatch({ type: FormalityAction.DELETE_ALL_USER_FORMS_SUCCESS });
            return Promise.resolve(data);
        } catch (error: any) {
            let message = "";
            if (error.errors) {
                message = error.errors[0].message;
            } else {
                message = error.message;
            }
            dispatch({ type: FormalityAction.DELETE_ALL_USER_FORMS_FAIL });
            return Promise.reject(message);
        }
    };

export const deleteSavedForm =
    (key: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({ type: FormalityAction.DELETE_SAVED_FORM_START });
            await Storage.remove(key, {
                level: "public",
            });
            dispatch({
                type: FormalityAction.DELETE_SAVED_FORM_SUCCESS,
                payload: key,
            });
            return Promise.resolve();
        } catch (error) {
            dispatch({ type: FormalityAction.DELETE_SAVED_FORM_FAIL });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const downloadSavedForm =
    (key: string): AppThunk<Promise<string>> =>
    async (dispatch) => {
        try {
            dispatch({ type: FormalityAction.DOWNLOAD_SAVED_FORM_START });
            const url = await Storage.get(key, {
                level: "public",
                contentDisposition: "attachment",
            });
            dispatch({ type: FormalityAction.DOWNLOAD_SAVED_FORM_SUCCESS });
            return Promise.resolve(url);
        } catch (error) {
            dispatch({ type: FormalityAction.DOWNLOAD_SAVED_FORM_FAIL });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const viewSavedForm =
    (key: string): AppThunk<Promise<string>> =>
    async (dispatch) => {
        try {
            dispatch({ type: FormalityAction.VIEW_SAVED_FORM_START });
            const url = await Storage.get(key, {
                level: "public",
                contentType: "application/pdf",
            });
            dispatch({ type: FormalityAction.VIEW_SAVED_FORM_SUCCESS });
            return Promise.resolve(url);
        } catch (error) {
            dispatch({ type: FormalityAction.VIEW_SAVED_FORM_FAIL });
            console.warn(error);
            return Promise.reject(error);
        }
    };

export const updateFormalityCompany =
    (values: Record<string, any>): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_COMPANY_START,
            });
            await API.graphql(
                graphqlOperation(mutations.updateFormalityCompany, {
                    input: values,
                })
            );
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_COMPANY_SUCCESS,
            });
            return Promise.resolve();
        } catch (error: any) {
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_COMPANY_FAIL,
                payload: error.errors ? error.errors[0].message : error.message,
            });
            return Promise.reject(
                error.errors ? error.errors[0].message : error.message
            );
        }
    };

export const updateFormalityUser =
    (values: Record<string, any>): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const {
                auth: { id, email },
            } = getState();

            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_USER_START,
            });
            const input = {
                id,
                email,
                ...values,
            };
            await API.graphql(
                graphqlOperation(mutations.updateFormalityUser, { input })
            );
            if (values.firstName && values.familyName) {
                console.log("🕵🏽 Updating Cognito...");
                const cognitoUser = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(cognitoUser, {
                    given_name: values.firstName,
                    family_name: values.familyName,
                });
                console.log("💾 Cognito updated!");
                console.log("✨ Updating state with new user status...");
                await dispatch(getCognitoUserDetails());
            }
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_USER_SUCCESS,
            });
            return Promise.resolve();
        } catch (error: any) {
            if (error.errors) {
                console.warn(error.errors[0].message);
                dispatch({
                    type: FormalityAction.UPDATE_FORMALITY_USER_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(error.errors[0].message);
            }
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_USER_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const updateFormalityUserEmail =
    (values: Record<string, any>): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const {
                auth: { id },
            } = getState();

            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_USER_EMAIL_START,
            });
            const input = {
                id,
                email: values.email,
                ...values,
            };
            await API.graphql(
                graphqlOperation(mutations.updateFormalityUser, { input })
            );
            console.log("🕵🏽 Updating Cognito...");
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(cognitoUser, {
                email: values.email,
            });
            console.log("💾 Cognito updated!");
            console.log("✨ Updating state with new user status...");
            await dispatch(getCognitoUserDetails());
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_USER_EMAIL_SUCCESS,
            });
            return Promise.resolve();
        } catch (error: any) {
            if (error.errors) {
                console.warn(error.errors[0].message);
                dispatch({
                    type: FormalityAction.UPDATE_FORMALITY_USER_EMAIL_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(error.errors[0].message);
            }
            dispatch({
                type: FormalityAction.UPDATE_FORMALITY_USER_EMAIL_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

export const sendSupportEmail =
    (values: Record<string, any>, isInterested:boolean=false): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch({ type: FormalityAction.SEND_SUPPORT_MAIL_START });
        try {
            const { data } = await API.post(
                "thaiFormalityAPI",
                "/email/support",
                {
                    body: {
                        content: { ...values, isInterested },
                    },
                }
            );
            console.log(`💁🏻‍♀️ Support email sent`);
            dispatch({ type: FormalityAction.SEND_SUPPORT_MAIL_SUCCESS });
            return Promise.resolve(data);
        } catch (error: any) {
            console.warn(error.response ? error.response : error.message);
            const message = error.response ? error.response : error.message;
            dispatch({
                type: FormalityAction.SEND_SUPPORT_MAIL_FAIL,
                payload: message,
            });
            return Promise.reject(new Error(message));
        }
    };

import React from "react";
import wai_classic from "../images/thai_wai_classical_200h.jpg";
import wai_blue from "../images/thai_wai_songkran_blue_160h.jpg";
import wai_green from "../images/thai_wai_songkran_green_200h.jpg";
import { CompanySubscriptionTierCard } from "./CompanySubscriptionTierCard";
import { useWindowSize } from "../hooks/useWindowSize";

export const SubscriptionPlanSelector = () => {
  const [, width] = useWindowSize();
  return (
    <>
      <div
        className={`container d-flex flex-column w-100 animate__animated animate__fadeIn  ${
          width >= 576
            ? "justify-content-center flex-grow-1"
            : "justify-content-center flex-grow-1"
        }`}
      >
        <div className="display text-center text-muted mt-4 mt-xl-0 mb-2 mb-sm-4">
          Select Your Plan
        </div>
        {/* <div className="lead text-center text-muted my-3">
        There's still time to change the road you're on
      </div> */}
        <div className="d-flex flex-wrap align-items-center justify-content-around mt-3">
          <CompanySubscriptionTierCard
            blurb="Single User Plan"
            color="info"
            buyPhrase="฿100 per month"
            wai={wai_blue}
            altWai={"Thai Wai Songkran Blue"}
            planType="single"
            planName="ฝรั่งขี้นก"
            planTagline="Frugality is a virtue"
            // planTagline="He who will not economize will have to agonize"
          />
          <CompanySubscriptionTierCard
            blurb="Manage up to 3 employees"
            color="success"
            buyPhrase="฿750 per month"
            wai={wai_green}
            altWai={"Thai Wai Songkran Green"}
            planType="secretary"
            planName="เลขานุการ"
            planTagline="Best for small organizations"
          />
          <CompanySubscriptionTierCard
            blurb="Manage unlimited employees"
            color="primary"
            buyPhrase="฿7500 per month"
            wai={wai_classic}
            altWai={"Thai Wai Classical"}
            planType="bigboss"
            planName="หัวหน้าใหญ่"
            planTagline="For large organizations"
          />
        </div>
      </div>
    </>
  );
};

import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    children: JSX.Element | JSX.Element[];
}
export const PrivateRoute = ({ children }: Props) => {
    const { id, isAdmin, isDemo } = useAppSelector((state) => state.auth);
    const location = useLocation();
    const isAuthenticated = !!id;
    if (isAuthenticated) {
        if (isAdmin) {
            if (isDev) {
                console.log(
                    "%cRouting to Admin from Private Route",
                    "color:cyan"
                );
            }
            return (
                <Navigate
                    to={{
                        pathname: "/admin",
                    }}
                    replace
                    state={{ from: location }}
                />
            );
        } else {
            if (isDev) {
                console.log(
                    `%cShowing Private Route ${isDemo ? "(Demo)" : ""}`,
                    "color:cyan"
                );
            }
            return children;
        }
    } else {
        if (isDev) {
            console.log("%cRerouting to Home from Private Route", "color:cyan");
        }
        return (
            <Navigate
                to={{
                    pathname: "/",
                }}
                replace
                state={{ from: location }}
            />
        );
    }
    // return (
    //     <Route
    //         {...rest}
    //         render={({ location }) =>
    //             isAuthenticated ? (
    //                 isAdmin ? (
    //                     <Navigate
    //                         to={{
    //                             pathname: "/admin",
    //                             state: { from: location },
    //                         }}
    //                         replace
    //                     />
    //                 ) : (
    //                     children
    //                 )
    //             ) : (
    //                 <Navigate
    //                     to={{
    //                         pathname: "/",
    //                         state: { from: location },
    //                     }}
    //                     replace
    //                 />
    //             )
    //         }
    //     />
    // );
};

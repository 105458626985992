import { useContext } from "react";
import { Button } from "react-bootstrap";
import { getButtonColor } from "../helpers";
import { Header } from "../components/Header";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();
    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <Header title="ไม่มี" />
            <Button
                onClick={() => navigate("/")}
                variant={getButtonColor("primary", isDark)}
            >
                Try Again
            </Button>
        </div>
    );
};

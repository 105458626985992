export const Faq = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <p className="display">FAQ</p>
      <ul className="list=group">
        <li className="list-group-item">How do you make PDFs like that?</li>
        <li className="list-group-item">How is my data kept secure?</li>
        <li className="list-group-item">
          What is Thai Formality's Privacy Policy
        </li>
        <li className="list-group-item">
          Why does Thai Formality cost so much?
        </li>
      </ul>
    </div>
  );
};

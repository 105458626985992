import { lazy, Suspense, useEffect, useMemo } from "react";
import { AdminRoute } from "./AdminRoute";
import { App } from "../App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Faq } from "../pages/Faq";
import { Goodbye } from "../pages/GoodBye";
import { LandingPage } from "../pages/LandingPage";
import { MobileFormContainer } from "../pages/MobileFormContainer";
import { NavBar } from "../components/NavBar";
import { NotFoundPage } from "../pages/NotFoundPage";
import { PaypalPayment } from "../pages/PaypalPayment";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { RegistrationPage } from "../pages/RegistrationPage";
import { UpdateUserEmailPage } from "../pages/UpdateUserEmailPage";
import { useWindowSize } from "../hooks/useWindowSize";
import TagManager from "react-gtm-module";

export const AppRouter = () => {
    const [, width] = useWindowSize();
    const navbarOffset = useMemo(() => {
        if (width < 576) {
            return 56;
        }
        return 63;
    }, [width]);

    const Admin = lazy(() => import("../pages/Admin"));
    const Contact = lazy(() => import("../pages/Contact"));
    const DangerZone = lazy(() => import("../pages/DangerZone"));
    const Demo = lazy(() => import("../pages/demo/Demo"));
    const Forms = lazy(() => import("../pages/Forms"));
    const MyDocuments = lazy(() => import("../pages/MyDocuments"));
    const MyEmployees = lazy(() => import("../pages/MyEmployees"));
    const Profile = lazy(() => import("../pages/Profile"));
    const Tutorial = lazy(() => import("../pages/demo/Tutorial"));

    useEffect(() => {
        const tagManagerArgs = {
            gtmId: "G-51DB89DZBQ",
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/landing" element={<LandingPage />} />
                <Route
                    path="/demo"
                    element={
                        <PublicRoute>
                            <Suspense>
                                <Demo />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route path="/goodbye" element={<Goodbye />} />
                <Route path="/faq" element={<Faq />} />
                <Route
                    path="/admin"
                    element={
                        <AdminRoute>
                            <NavBar />
                            <Suspense>
                                <Admin navbarOffset={navbarOffset} />
                            </Suspense>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <Suspense>
                                <Contact navbarOffset={navbarOffset} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/interested"
                    element={
                        <PublicRoute>
                            <Suspense>
                                <Contact navbarOffset={navbarOffset} />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/update-email"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <UpdateUserEmailPage navbarOffset={navbarOffset} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/danger-zone"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <Suspense>
                                <DangerZone navbarOffset={navbarOffset} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/forms"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <Suspense>
                                <Forms navbarOffset={navbarOffset} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/my-docs"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <Suspense>
                                <MyDocuments navbarOffset={navbarOffset} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/my-employees"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <Suspense>
                                <MyEmployees navbarOffset={navbarOffset} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/mobile-form"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <MobileFormContainer navbarOffset={navbarOffset} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/paypal-payment"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <PaypalPayment navbarOffset={navbarOffset} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <Suspense>
                                <Profile navbarOffset={navbarOffset} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/registration"
                    element={
                        <PrivateRoute>
                            <NavBar />
                            <RegistrationPage navbarOffset={navbarOffset} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/tutorial"
                    element={
                        <Suspense>
                            <Tutorial />
                        </Suspense>
                    }
                />
                <Route
                    path="/"
                    element={
                        <PublicRoute>
                            <App />
                        </PublicRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, DropdownButton } from "react-bootstrap";
import { resetImpersonating } from "../../actions";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";

interface Props {
    companyName: string | undefined;
    email: string | undefined;
    familyName: string | undefined;
    firstName: string | undefined;
    isAdmin: boolean;
    isDark: boolean;
    isDemo: boolean;
    picture: string | undefined;
    role: string | undefined;
    userSubscriptionPlan: string;
    width: number;
}
export const NavBarDropDown = ({
    companyName,
    email,
    familyName,
    firstName,
    isAdmin,
    isDark,
    isDemo,
    picture,
    role,
    userSubscriptionPlan,
    width,
}: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return isDemo || isAdmin ? (
        <div>
            <li className="nav-item d-flex align-items-center me-3">
                {picture ? (
                    <img
                        className="me-2"
                        src={picture}
                        height="32px"
                        width="32px"
                        alt="avatar"
                        style={{ borderRadius: "50%" }}
                    />
                ) : null}
                {isAdmin ? (
                    <span className="text-primary">{email}</span>
                ) : (
                    <span>{firstName + " " + familyName}</span>
                )}
            </li>
        </div>
    ) : (
        <>
            <DropdownButton
                // id="dropdown-basic-button"
                size="sm"
                title={
                    <div className="d-flex align-items-center">
                        {picture ? (
                            <img
                                src={picture}
                                height="32px"
                                width="32px"
                                alt="avatar"
                                style={{ borderRadius: "50%" }}
                            />
                        ) : null}
                        <span>
                            {role ? firstName + " " + familyName : email}
                        </span>
                        <i className="material-icons">arrow_drop_down</i>
                    </div>
                }
                variant={isDark ? "dark" : "light"}
            >
                <Dropdown.Item
                    className="d-flex align-items-center"
                    onClick={() => navigate("/profile")}
                >
                    Profile
                </Dropdown.Item>
                {!role && !userSubscriptionPlan && (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(resetImpersonating());
                            navigate("/registration");
                        }}
                    >
                        Subscribe
                    </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={() => {
                        dispatch(resetImpersonating());
                        navigate("/contact");
                    }}
                >
                    Contact Support
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        dispatch(resetImpersonating());
                        navigate("/danger-zone");
                    }}
                >
                    Delete Account
                </Dropdown.Item>

                {companyName === "disconnected" && (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(resetImpersonating());
                            navigate("/update-email");
                        }}
                    >
                        Update Email
                    </Dropdown.Item>
                )}
                {role === "admin" && (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(resetImpersonating());
                            navigate("/admin");
                        }}
                    >
                        Update Email
                    </Dropdown.Item>
                )}
            </DropdownButton>
        </>
    );
};

import { object, string, date, ref, number } from "yup";
import moment from "moment";

export const applicationDetailsFormSchema = object({
    applicationDate: date(),
    writtenAt: string().max(25),
});

export const changeUserEmailSchema = object({
    email: string().email("Please use a valid email").required("Required"),
});

export const companyInviteEmployeeFormSchema = object({
    firstName: string().required("Required"),
    familyName: string().required("Required"),
    managerEmail: string().email().required("Required"),
    username: string()
        .email("Please use a vaild email.")
        .test("match", "Must be company email", function (username) {
            if (username && username.includes("@")) {
                const userDomain = username.split("@")[1];
                const companyDomain = this.parent.managerEmail.split("@")[1];
                return userDomain === companyDomain;
            } else {
                return false;
            }
        })
        .required("Required"),
});

export const contactPageSchema = object({
    name: string().required("Required"),
    email: string().email().required("Required"),
    message: string().required("Required"),
    companyName: string(),
    companyPaypalSubscriptionId: string(),
    userPaypalSubscriptionId: string(),
});

export const companyRegistrationFormSchema = object({
    companyName: string().required("Required"),
    companyNumber: string()
        .matches(/^[0-9]{10}$/, "Must be a ten digit number.")
        .required("Required"),
    contactFirstName: string().required("Required"),
    contactFamilyName: string().required("Required"),
    contactEmail: string()
        .email("Please use a vaild email.")
        .required("Required"),
    paymentMethod: string().required("Required"),
    phone: string()
        .matches(/^\+[0-9]{11}$/, "Must be a ten digit number.")
        .required("Required"),
    addressNumber: string().required("Required"),
    road: string(),
    subdistrict: string().required("Required"),
    district: string().required("Required"),
    province: string().required("Required"),
    postalCode: string()
        .matches(/^[0-9]{5}$/, "Must be a five digit number.")
        .required("Required"),
    subscriptionPlan: string().required("Required"),
});

export const linkPaypalSubscriptionIndividual = object({
    firstName: string().required("Required"),
    familyName: string().required("Required"),
    email: string().email("Please use a vaild email.").required("Required"),
    paymentMethod: string().required("Required"),
    paypalSubscriptionId: string().required("Required"),
    phone: string()
        .matches(/^\+[0-9]{11}$/, "Please match this pattern: +66891231234")
        .required("Required"),
    addressNumber: string().required("Required"),
    road: string(),
    subdistrict: string().required("Required"),
    district: string().required("Required"),
    province: string().required("Required"),
    postalCode: string()
        .matches(/^[0-9]{5}$/, "Must be a five digit number.")
        .required("Required"),
    subscriptionPlan: string().required("Required"),
});

export const forgotPasswordSchema = object({
    username: string().email("Please use a valid email").required("Required"),
    password: string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
    code: string()
        .matches(/^[0-9]{6}$/, "Must be a six digit code.")
        .required("Required"),
});

export const individualRegistrationFormSchema = object({
    firstName: string().required("Required"),
    familyName: string().required("Required"),
    email: string().email("Please use a vaild email.").required("Required"),
    paymentMethod: string().required("Required"),
    phone: string()
        .matches(/^\+[0-9]{11}$/, "Please match this pattern: +66891231234")
        .required("Required"),
    addressNumber: string().required("Required"),
    road: string(),
    subdistrict: string().required("Required"),
    district: string().required("Required"),
    province: string().required("Required"),
    postalCode: string()
        .matches(/^[0-9]{5}$/, "Must be a five digit number.")
        .required("Required"),
    subscriptionPlan: string().required("Required"),
});

export const linkPaypalBillingSchema = object({
    paypalBillingId: string()
        .matches(/I-[A-Z0-9]{12}/gm, "That doesn't look right")
        .required("Required"),
});

export const personalInfoFormSchema = object({
    dob: date().required("Required"),
    email: string().email("Please use a valid email").required("Required"),
    familyName: string().max(24).required("Required"),
    firstName: string().max(24).required("Required"),
    honorific: string(),
    // .matches(/Mr.|Mrs.|Miss|Ms./, "Should match: Miss, Mr., Mrs., or Ms.")
    // .required("Required"),
    middleName: string().max(24),
    nationality: string().max(20).required("Required"),
    occupation: string().max(25),
    phone: string().max(13).required("Required"),
    // placeOfBirth: string().max(24).required("Required"),
    stateOfBirth: string().max(24).required("Required"),
    countryOfBirth: string().max(24).required("Required"),
});

export const signInSchema = object({
    // companyName: string().when("isCompany", {
    //   is: true,
    //   then: string().required("Required")
    // }),
    username: string().email("Please use a valid email").required("Required"),
    password: string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Incorrect username or password."
        )
        .required("Required"),
});
export const signUpSchema = object({
    username: string().email("Please use a valid email").required("Required"),
    password: string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Does not meet complexity requirements"
        )
        .required("Required"),
    confirmPassword: string().test(
        "match",
        "Passwords to not match",
        function (confirmPassword) {
            return confirmPassword === this.parent.password;
        }
    ),
});

export const signUpConfirmationSchema = object({
    code: string()
        .matches(/^[0-9]{6}$/, "Must be a six digit code.")
        .required("Required"),
});

export const travelDocumentFormSchema = object({
    arrivalDate: date().nullable().required("Required"),
    arrivedBy: string().max(15).required("Required"),
    arrivedFrom: string().max(20).required("Required"),
    expDate: date().required("Required"),
    issueDate: date().required("Required"),
    issuedBy: string().max(20).required("Required"),
    issuingCountry: string().max(25).required("Required"),
    number: string().max(20).required("Required"),
    portOfArrival: string().max(20).required("Required"),
    tm6Number: string()
        .matches(/[A-Z]{2}\d{5}/, "Should look like this AB12345")
        .required("Required"),
    typeOfVisa: string().max(30).required("Required"),
    visaIssuedAt: string().max(30).required("Required"),
    visaIssueDate: date().required("Required"),
    visaExpDate: date().required("Required"),
    // ninetyDayExpDate: date().required("Required"),
    ninetyDayExpDate: date()
        .nullable()
        .test(
            "ninetyDaysCheck",
            "Required when entry date is more than 90 days ago",
            function (val) {
                if (!val) return false; // If ninetyDayExpDate is not provided, the condition is not met.
                const currentDate = moment().startOf("day");
                const arrivalDate = moment(
                    this.resolve(ref("arrivalDate"))
                ).startOf("day");
                const diffInDays = currentDate.diff(arrivalDate, "days");
                return diffInDays >= 90;
            }
        )
        .min(moment().startOf("day").toDate(), "Must not be in the past")
        .max(
            moment().add(90, "days").startOf("day").toDate(),
            "Must not be more than 90 days from now"
        ),

    // ninetyDayExpDate: date().when("arrivalDate", {
    //   is: (val) =>
    //     moment()
    //       .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    //       .diff(
    //         moment(val).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    //         "days"
    //       ) >= 90,
    //   then: date()
    //     .required("Required when entry date is more than 90 days ago")
    //     .min(
    //       moment()
    //         .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    //         .format(),
    //       "Must not be in the past"
    //     )
    //     .max(
    //       moment()
    //         .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    //         .add(92, "days")
    //         .format(),
    //       "Must not be more than 90 days from now"
    //     )
    // })
});

export const thaiAddressFormSchema = object({
    thaiAddressDistrict: string().max(25).required("Required"),
    thaiAddressNumber: string().max(15).required("Required"),
    thaiAddressProvince: string().max(25).required("Required"),
    thaiAddressRoad: string().max(15),
    thaiAddressSubdistrict: string().max(25).required("Required"),
    thaiAddressPostalCode: string()
        .matches(/\d{5}/, "Must be exactly 5 digits")
        .required("Required"),
});

export const tm7FormSchema = object({
    extensionDays: number()
        .min(1, "Don't be ridiculous!")
        .max(365, "Maximum is 365")
        .required("Required"),
    reasonForExtension: string().max(75).required("Required"),
});

export const tm8FormSchema = object({
    permitType: string().max(7).required("Required"),
    intendedDestination: string().max(25).required("Required"),
    departureDate: date().required("Required"),
    returnDate: date().required("Required"),
    modeOfTransportation: string().max(25).required("Required"),
    purposeOfVisa: string().max(30).required("Required"),
});

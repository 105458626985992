import { useContext } from "react";
import { AuthAction, FormalityAction } from "../types";
import { Button } from "react-bootstrap";
import { forgotPasswordSchema } from "../validators";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { handleForgotPasswordSubmit } from "../actions";
import { Spinner } from "./shared/Spinner";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";

export const ForgotPassword = () => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const {
        attemptedValues: { username: attemptedUsername },
    } = useAppSelector((state) => state.auth);
    return (
        <Formik
            initialValues={{
                username: attemptedUsername || "",
                password: "",
                code: "",
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={async (values, actions) => {
                try {
                    actions.setSubmitting(true);
                    await dispatch(handleForgotPasswordSubmit(values));
                } catch (error: any) {
                    actions.setSubmitting(false);
                    if (error.message === "User does not exist.") {
                        actions.setFieldError(
                            "username",
                            "We can't find that address. Are you new here?"
                        );
                    } else if (error.message === "User is not confirmed.") {
                        actions.setFieldError(
                            "username",
                            "Please confirm verification code."
                        );
                        dispatch({
                            type: AuthAction.SET_UNCONFIRMED_USER,
                            payload: {
                                signUpError: error.message,
                                attemptedValues: values,
                            },
                        });
                    } else {
                        dispatch({
                            type: FormalityAction.SET_USER_ALERT,
                            payload: {
                                alertType: "primary",
                                message: error.message,
                                emoticon: "",
                                title: "Problem forgetting password",
                            },
                        });
                    }
                    console.warn(error);
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldError,
                touched,
            }) => (
                <Form
                    className="d-flex flex-column align-items-center"
                    noValidate
                >
                    <div className="form-group mb-2">
                        <label htmlFor="username" className="form-label">
                            Email
                        </label>
                        <Field
                            autoComplete="username"
                            className={`form-control text-center ${
                                errors.username && touched.username
                                    ? "is-invalid"
                                    : null
                            }`}
                            id="username"
                            name="username"
                            placeholder="somchai@example.com"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            type="email"
                        />
                        <div className="formikErrorMessage text-left">
                            <ErrorMessage name="username" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="password" className="form-label">
                            New Password
                        </label>
                        <Field
                            autoFocus
                            autoComplete="current-password"
                            className={`form-control text-center ${
                                errors.password && touched.password
                                    ? "is-invalid"
                                    : null
                            }`}
                            id="password"
                            name="password"
                            placeholder="5@wasd33Krab"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            type="password"
                        />
                        <div className="formikErrorMessage text-left">
                            <ErrorMessage name="password" />
                        </div>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="code" className="form-label">
                            Verification Code
                        </label>
                        <Field
                            aria-describedby="verificationCodeHelp"
                            className={`form-control text-center ${
                                errors.code && touched.code
                                    ? "is-invalid"
                                    : null
                            }`}
                            id="code"
                            name="code"
                            placeholder="123456"
                            spellCheck="false"
                            style={{ width: "300px" }}
                            type="text"
                        />
                        {!errors.code ? (
                            <small
                                id="verificationCodeHelp"
                                className="form-text text-muted"
                            >
                                Check your {attemptedUsername} inbox for this
                            </small>
                        ) : null}
                        <div className="formikErrorMessage text-left">
                            <ErrorMessage name="code" />
                        </div>
                    </div>
                    <Button
                        className={`d-flex align-items-center justify-content-center w-100 mt-2`}
                        disabled={!dirty || isSubmitting}
                        style={{ height: "40px" }}
                        type="submit"
                        variant={getButtonColor("primary", isDark)}
                    >
                        {isSubmitting ? (
                            <Spinner
                                color={getSpinnerColor("primary", isDark)}
                                size={10}
                                type="beat"
                            />
                        ) : (
                            <span>Change Password</span>
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Nav, Navbar } from "react-bootstrap";
import { ImpersonationAlert } from "./ImpersonationAlert";
import { NavBarCollapse } from "./shared/NavBarCollapse";
import { NavBarDropDown } from "./shared/NavBarDropDown";
import { signOut } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useIsConnected } from "../hooks/useIsConnected";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useHotkeys } from "react-hotkeys-hook";
import { getButtonColor } from "../helpers";

export const NavBar = () => {
    const { isDark, toggleTheme } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [, width] = useWindowSize();
    const { isConnected, checkInternetConnection } = useIsConnected();
    const {
        isAdmin,
        isDemo,
        attributes: {
            email,
            given_name: firstName,
            family_name: familyName,
            "custom:companyName": companyName,
            "custom:role": role,
            picture,
        },
    } = useAppSelector((state) => state.auth);
    const { impersonating, subscriptionPlan: userSubscriptionPlan } =
        useAppSelector((state) => state.user);
    useHotkeys("meta+j", () => toggleTheme(), {
        enableOnContentEditable: true,
    });
    return (
        <Navbar
            className="fixed-top bg-body-tertiary w-100"
            collapseOnSelect
            expand="sm"
        >
            <div className="container w-100">
                {impersonating.id ? (
                    <div className="m-0">
                        <ImpersonationAlert />
                    </div>
                ) : (
                    <Navbar.Brand
                        className="navbar-brand"
                        data-toggle="collapse"
                        data-target="#navbarContent"
                        onClick={() => navigate("/")}
                        style={{ cursor: "pointer" }}
                    >
                        Thai Formality
                    </Navbar.Brand>
                )}
                {!isConnected && width < 576 && (
                    <i
                        className="material-icons text-danger m-2 ms-auto"
                        onClick={() => checkInternetConnection()}
                        style={{ cursor: "pointer" }}
                        title="No Internet connection"
                    >
                        router
                    </i>
                )}
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav w-100">
                    <Nav className="w-100">
                        <div className="d-flex align-items-center ms-auto">
                            {!isConnected && width >= 576 && (
                                <i
                                    className="material-icons text-danger m-2 ms-auto"
                                    onClick={() => checkInternetConnection()}
                                    style={{ cursor: "pointer" }}
                                    title="No Internet connection"
                                >
                                    router
                                </i>
                            )}
                        </div>
                        {width < 576 && (
                            <NavBarCollapse
                                companyName={companyName}
                                isAdmin={isAdmin}
                                isDemo={isDemo}
                                role={role}
                                userSubscriptionPlan={userSubscriptionPlan}
                            />
                        )}

                        {width >= 576 && (
                            <div className="d-flex align-items-center">
                                <NavBarDropDown
                                    companyName={companyName}
                                    email={email}
                                    familyName={familyName}
                                    firstName={firstName}
                                    isDark={isDark}
                                    isDemo={isDemo}
                                    isAdmin={isAdmin}
                                    picture={picture}
                                    role={role}
                                    userSubscriptionPlan={userSubscriptionPlan}
                                    width={width}
                                />
                                <Button
                                    className="my-2 ms-3"
                                    onClick={() => {
                                        if (isDemo) {
                                            console.log("🙇‍♀️ Leaving Demo");
                                        }
                                        dispatch(signOut());
                                    }}
                                    size="sm"
                                    variant={getButtonColor("primary", isDark)}
                                >
                                    {isDemo ? "End Demo" : "Sign Out"}
                                </Button>
                            </div>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

import $ from "jquery";

export const getButtonColor = (color, isDark) => {
    if (isDark) {
        return color;
    } else {
        return `outline-${color}`;
    }
};
export const getSpinnerColor = (color, isDark) => {
    if (isDark) {
        return "light";
    } else {
        return color;
    }
};

export const getErrorMessage = async (error) => {
    if (error.errors) {
        return error.errors[0].message;
    } else if (error.message) {
        return error.message;
    } else {
        return error;
    }
};

export const getPlanName = (planId) => {
    switch (planId) {
        case "P-2Y2777191U406962SL4I27RI":
            return "ฝรั่งขี้นก";
        case "P-7RH42591DG492925AL4I4UKQ":
            return "เลขานุการ";
        case "P-32W865918L280584LL4I4YGQ":
            return "หัวหน้าใหญ่";
        default:
            return "unknown plan";
    }
};

export const getRandomUserData = async (type) => {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: "https://randomuser.me/api/",
            dataType: "json",
            error: (error) => {
                reject(error);
            },
            success: ({ results }) => {
                console.warn(results);
                const entry = results[0];
                switch (type) {
                    case "demo":
                        const demoData = {
                            honorific: getTitleCase(entry.name.title) + ".",
                            firstName: getTitleCase(entry.name.first),
                            middleName: "X.",
                            familyName: getTitleCase(entry.name.last),
                            dob: entry.dob.date,
                            nationality: entry.nat,
                            picture: entry.picture.medium,
                            placeOfBirth: `${getTitleCase(
                                entry.location.city
                            )}, ${getTitleCase(entry.location.state)}`,
                            phone: `+66${Math.floor(
                                100000000 + Math.random() * 900000000
                            )}`,
                        };
                        return resolve(demoData);
                    case "personal":
                        const personalInfoData = {
                            honorific: getTitleCase(entry.name.title) + ".",
                            firstName: getTitleCase(entry.name.first),
                            middleName: "X.",
                            familyName: getTitleCase(entry.name.last),
                            dob: entry.dob.date,
                            nationality: entry.nat,
                            picture: entry.picture.medium,
                            placeOfBirth: `${getTitleCase(
                                entry.location.city
                            )}, ${getTitleCase(entry.location.state)}`,
                            phone: `+66${Math.floor(
                                100000000 + Math.random() * 900000000
                            )}`,
                        };
                        return resolve(personalInfoData);
                    case "address":
                        const addressData = {
                            addressNumber: `${Math.floor(
                                100 + Math.random() * 900
                            )}/${Math.floor(1 + Math.random() * 9)}`,
                            subdistrict: "",
                            district: getTitleCase(entry.location.city),
                            province: getTitleCase(entry.location.state),
                            postalCode: `${Math.floor(
                                10000 + Math.random() * 90000
                            )}`,
                            road: `Soi ${Math.floor(10 + Math.random() * 90)}`,
                        };
                        return resolve(addressData);
                    default:
                        break;
                }
            },
        });
    });
};

export const getTitleCase = (str) =>
    str
        .split(" ")
        .map((str) => {
            const word = str.toLowerCase();
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");

export const isEmptyObject = (obj) => {
    if (Object.entries(obj).length === 0 && obj.constructor === Object) {
        return true;
    }
    return false;
};

export const parseSlugifyUserData = (users, update) => {
    const parseItem = (obj) => {
        try {
            if (!obj || isEmptyObject(obj)) {
                return {};
            }
            if (typeof obj === "string") {
                if (update) {
                    return JSON.parse(JSON.parse(obj));
                } else {
                    return JSON.parse(obj);
                }
            }
            return obj;
        } catch (error) {
            console.warn(error);
        }
    };
    const parseUser = (user) => {
        try {
            return {
                appleMusicSlugifyPlaylist: user.appleMusicSlugifyPlaylist,
                expoPushTokenData: parseItem(user.expoPushTokenData),
                friends: user.friends.items || [],
                homeMenu: user.homeMenu,
                id: user.id,
                isSubscribedOneSignal: user.isSubscribedOneSignal,
                owner: user.owner,
                primaryMusicStream: user.primaryMusicStream,
                screenAppearance: user.screenAppearance,
                slugifyMobileSlurpDestination: parseItem(
                    user.slugifyMobileSlurpDestination
                ),
                slurpedTracks: user.slurpedTracks.items || [],
                spotifyTokenData: parseItem(user.spotifyTokenData),
                spotifyUserData: parseItem(user.spotifyUserData),
                username: user.username,
            };
        } catch (error) {
            console.warn(error);
        }
    };
    try {
        if (Array.isArray(users)) {
            if (users.length) {
                return Promise.resolve(users.map((user) => parseUser(user)));
            } else {
                return Promise.resolve([]);
            }
        } else {
            return Promise.resolve(parseUser(users));
        }
    } catch (error) {
        console.warn(error);
        Promise.reject(error);
    }
};

export const trimText = (len, text) => {
    if (text && text.length <= len) return text;
    return text.slice(0, len - 3) + "...";
};

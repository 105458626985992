import { API, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as custom from "../custom/queries";
import { getCognitoUserDetails, refreshCognitoToken } from "./authActions";
import { omit } from "lodash";
import { SubscriptionAction } from "../types";

export const checkCompanyExists = (companyId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SubscriptionAction.CHECK_COMPANY_EXISTS_START });
        const {
            data: { getFormalityCompany: result },
        } = await API.graphql(
            graphqlOperation(custom.checkCompanyExists, { id: companyId })
        );
        dispatch({ type: SubscriptionAction.CHECK_COMPANY_EXISTS_SUCCESS });
        if (result) {
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    } catch (error) {
        const message = error.errors ? error.errors[0].message : error.message;
        dispatch({
            type: SubscriptionAction.CHECK_COMPANY_EXISTS_FAIL,
            payload: message,
        });
        return Promise.reject(message);
    }
};

export const getCompany = (companyId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SubscriptionAction.GET_COMPANY_START });
        const {
            data: { getFormalityCompany: companyData },
        } = await API.graphql(
            graphqlOperation(queries.getFormalityCompany, {
                id: companyId,
            })
        );
        dispatch({
            type: SubscriptionAction.GET_COMPANY_SUCCESS,
            payload: companyData,
        });
        return Promise.resolve(companyData);
    } catch (error) {
        console.warn(error);
        dispatch({ type: SubscriptionAction.GET_COMPANY_FAIL, payload: error });
        return Promise.reject(error);
    }
};

export const startSubscription = (planType) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SubscriptionAction.START_SUBSCRIPTION_PROCESS,
            payload: planType,
        });
    } catch (error) {
        console.warn(error);
    }
};
export const saveCompanyRegistrationInformation =
    (values) => async (dispatch, getState) => {
        try {
            const {
                auth: { id: currentUserId },
            } = getState();

            dispatch({
                type: SubscriptionAction.SAVE_COMPANY_REGISTRATION_INFO_START,
            });

            const companyInput = {
                companyName: values.companyName,
                ...omit(values, [
                    "employees",
                    "companyName",
                    "contactFirstName",
                    "contactFamilyName",
                    "contactEmail",
                ]),
            };

            console.log("🏗️ Creating company...");
            await API.graphql(
                graphqlOperation(mutations.createFormalityCompany, {
                    input: companyInput,
                })
            );
            console.log("👷‍♀️ Company created!");
            const userInput = {
                id: currentUserId,
                firstName: values.contactFirstName,
                familyName: values.contactFamilyName,
                role: "manager",
                companyId: values.companyNumber,
            };
            console.log("⛪ Converting user to manager...");
            await API.graphql(
                graphqlOperation(mutations.updateFormalityUser, {
                    input: userInput,
                })
            );
            console.log("🕵🏽 Updating Cognito...");
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(cognitoUser, {
                given_name: values.contactFirstName,
                family_name: values.contactFamilyName,
                "custom:role": "manager",
                "custom:companyId": values.companyNumber,
                "custom:companyName": values.companyName,
            });
            console.log("👔 Adding User to Managment Group");
            await API.post(
                "thaiFormalityAPI",
                "/cognito/add-to-manager-group",
                {
                    body: {
                        userId: currentUserId,
                    },
                }
            );
            // need to get a new token here to make all this work without sign-off/on
            await dispatch(refreshCognitoToken());
            console.log("💾 Cognito updated!");
            console.log("🕍 User converted!");
            console.log("✨ Updating state with new user status...");
            await dispatch(getCognitoUserDetails());
            dispatch({
                type: SubscriptionAction.SAVE_COMPANY_REGISTRATION_INFO_SUCCESS,
                payload: {
                    companyId: values.companyNumber,
                    ...values,
                },
            });
            return Promise.resolve();
        } catch (error) {
            if (error.errors) {
                console.warn(error.errors[0].message);
                dispatch({
                    type: SubscriptionAction.SAVE_COMPANY_REGISTRATION_INFO_FAIL,
                    payload: error.errors[0].message,
                });
                return Promise.reject(error.errors[0].message);
            }
            dispatch({
                type: SubscriptionAction.SAVE_COMPANY_REGISTRATION_INFO_FAIL,
                payload: error,
            });
            return Promise.reject(error);
        }
    };

import { Formik, Form, Field, ErrorMessage } from "formik";
import { linkPaypalSubscriptionIndividual } from "../validators";
import { useNavigate } from "react-router-dom";
import { ThaiAddressSelector } from "./ThaiAddressSelector";
import { updateFormalityUser } from "../actions";
import { getButtonColor, getPlanName, getSpinnerColor } from "../helpers";
import { Spinner } from "./shared/Spinner";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { FormalityAction, SubscriptionAction, UserAction } from "../types";
import { Button } from "react-bootstrap";
import { ThemeContext } from "../contexts/ThemeProvider";
import { useContext } from "react";

export const LinkPaypalSubscriptionConfirmationFormIndividual = ({
    subscriptionData,
}) => {
    const {
        id: paypalSubscriptionId,
        subscriber: {
            email_address: email,
            name: { given_name: firstName, surname: familyName },
        },
        plan_id: planId,
    } = subscriptionData;
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isDemo } = useAppSelector((state) => state.auth);
    const { data } = useAppSelector((state) => state.subscription);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                firstName: data.firstName
                    ? data.firstName
                    : firstName
                    ? firstName
                    : "",
                familyName: data.familyName
                    ? data.familyName
                    : familyName
                    ? familyName
                    : "",
                email: data.email
                    ? data.email
                    : email
                    ? email
                    : "Somthing's not right...",
                paymentMethod: "PayPal",
                phone: data.phone ? data.phone : "",
                addressNumber: data.addressNumber ? data.addressNumber : "",
                road: data.road ? data.road : "",
                subdistrict: data.subdistrict ? data.subdistrict : "",
                district: data.district ? data.district : "",
                province: data.province ? data.province : "",
                postalCode: data.postalCode ? data.postalCode : "",
                paypalSubscriptionId:
                    paypalSubscriptionId || "Something's not right",
                subscriptionPlan: getPlanName(planId),
            }}
            validationSchema={linkPaypalSubscriptionIndividual}
            onSubmit={async (values, actions) => {
                try {
                    dispatch({
                        type: FormalityAction.SET_USER_ALERT,
                        payload: null,
                    });
                    actions.setSubmitting(true);
                    const user = await dispatch(
                        updateFormalityUser({ ...values })
                    );
                    dispatch({ type: UserAction.UPDATE_USER, payload: user });
                    dispatch({
                        type: SubscriptionAction.SAVE_SUBSCRIPTION_FORM_VALUES,
                        payload: values,
                    });
                    navigate("/profile");
                } catch (error) {
                    actions.setSubmitting(false);
                    console.warn(error);
                }
            }}
        >
            {({
                values,
                dirty,
                errors,
                isSubmitting,
                setFieldValue,
                touched,
            }) => (
                <Form className="w-100">
                    <div
                        className="display text-center text-muted mt-4 mt-xl-0 mb-4"
                        noValidate
                    >
                        Link Paypal Subscription
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="firstName"
                                    className="form-label"
                                >
                                    First Name
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.firstName && touched.firstName
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Somchai"
                                    spellCheck="false"
                                    tabIndex="1"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="firstName" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="familyName"
                                    className="form-label"
                                >
                                    Last Name
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.familyName && touched.familyName
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="familyName"
                                    name="familyName"
                                    placeholder="Smith"
                                    spellCheck="false"
                                    tabIndex="2"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="familyName" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email" className="form-label">
                                    Contact Email
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.email && touched.email
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="email"
                                    name="email"
                                    tabIndex="-1"
                                    type="email"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="phone" className="form-label">
                                    Phone
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.phone && touched.phone
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="phone"
                                    name="phone"
                                    placeholder="+66891231234"
                                    spellCheck="false"
                                    tabIndex="3"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="phone" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="subscriptionPlan"
                                    className="form-label"
                                >
                                    Subscription Plan
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.subscriptionPlan &&
                                        touched.subscriptionPlan
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="subscriptionPlan"
                                    name="subscriptionPlan"
                                    spellCheck="false"
                                    tabIndex="-1"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="subscriptionPlan" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="paypalSubscriptionId"
                                    className="form-label"
                                >
                                    Subscription ID
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.paypalSubscriptionId &&
                                        touched.paypalSubscriptionId
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="paypalSubscriptionId"
                                    name="paypalSubscriptionId"
                                    spellCheck="false"
                                    tabIndex="-1"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="paypalSubscriptionId" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="paymentMethod"
                                    className="form-label"
                                >
                                    Payment Method
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.paymentMethod &&
                                        touched.paymentMethod
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    readOnly
                                    id="paymentMethod"
                                    name="paymentMethod"
                                    spellCheck="false"
                                    tabIndex="-1"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="paymentMethod" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm">
                            <ThaiAddressSelector
                                formDestination={"registration"}
                                tabIndex="4"
                                values={values}
                            />
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="addressNumber"
                                    className="form-label"
                                >
                                    Address Number
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.addressNumber &&
                                        touched.addressNumber
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="addressNumber"
                                    name="addressNumber"
                                    placeholder="122/3"
                                    spellCheck="false"
                                    tabIndex="4"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="addressNumber" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="road" className="form-label">
                                    Road
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.road && touched.road
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="road"
                                    name="road"
                                    placeholder="Soi 12"
                                    spellCheck="false"
                                    tabIndex="5"
                                    type="text"
                                />
                                <div className="formikErrorMessage text-left">
                                    <ErrorMessage name="road" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="subdistrict"
                                    className="form-label"
                                >
                                    Subdistrict (
                                    <span className="font-italic">Tambol</span>/
                                    <span className="font-italic">Khwaeng</span>
                                    )
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.subdistrict &&
                                        touched.subdistrict
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="subdistrict"
                                    name="subdistrict"
                                    placeholder="Changklang"
                                    spellCheck="false"
                                    tabIndex="6"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="subdistrict" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="district"
                                    className="form-label"
                                >
                                    District (
                                    <span className="font-italic">Amphoe</span>/
                                    <span className="font-italic">Khet</span>)
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.district && touched.district
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="district"
                                    name="district"
                                    placeholder="Muang"
                                    spellCheck="false"
                                    tabIndex="7"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="district" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="province"
                                    className="form-label"
                                >
                                    Province (
                                    <span className="font-italic">
                                        Changwat
                                    </span>
                                    )
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.province && touched.province
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="province"
                                    name="province"
                                    placeholder="Chiang Mai"
                                    spellCheck="false"
                                    tabIndex="8"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="province" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label
                                    htmlFor="postalCode"
                                    className="form-label"
                                >
                                    Postal Code
                                </label>
                                <Field
                                    className={`form-control text-left ${
                                        errors.postalCode && touched.postalCode
                                            ? "is-invalid"
                                            : ""
                                    }`}
                                    id="postalCode"
                                    name="postalCode"
                                    placeholder="50140"
                                    spellCheck="false"
                                    tabIndex="9"
                                    type="text"
                                />
                                <div className="formikErrorMessage">
                                    <ErrorMessage name="postalCode" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <Button
                            className="d-flex align-items-center justify-content-center"
                            disabled={isSubmitting}
                            style={{ height: "40px", width: "96px" }}
                            type="submit"
                            variant={getButtonColor("primary", isDark)}
                        >
                            {isSubmitting ? (
                                <Spinner
                                    size={10}
                                    color={getSpinnerColor("primary", isDark)}
                                    type="beat"
                                />
                            ) : (
                                <span>Register</span>
                            )}
                        </Button>
                        <Button
                            className="ms-2"
                            onClick={() => {
                                dispatch({
                                    type: SubscriptionAction.STOP_SUBSCRIPTION_PROCESS,
                                });
                                navigate(-1);
                            }}
                            style={{ height: "40px", width: "96px" }}
                            variant={getButtonColor("primary", isDark)}
                        >
                            Cancel
                        </Button>
                    </div>
                    {isDemo ? (
                        <small className="text-danger">
                            Registration is disabled in demo
                        </small>
                    ) : null}
                </Form>
            )}
        </Formik>
    );
};

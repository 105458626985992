import { createAction, createReducer } from "@reduxjs/toolkit";
import { AdminAction, AdminState, AuthAction } from "../types";
const initialState: AdminState = { cognitoUsers: [], companies: [], users: [] };

const delete_admin_company = createAction<null>(
    AdminAction.DELETE_ADMIN_COMPANY
);
const delete_cognito_user = createAction<null>(AdminAction.DELETE_COGNITO_USER);
const get_all_cognito_users_success = createAction<any[]>(
    AdminAction.GET_ALL_COGNITO_USERS_SUCCESS
);
const get_formality_companies_success = createAction<any[]>(
    AdminAction.GET_FORMALITY_COMPANIES_SUCCESS
);
const get_formality_users_success = createAction<any[]>(
    AdminAction.GET_FORMALITY_USERS_SUCCESS
);
const update_admin = createAction<any[]>(AdminAction.UPDATE_ADMIN);
const sign_out_success = createAction<Record<string, any>>(
    AuthAction.SIGN_OUT_SUCCESS
);
const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(delete_admin_company, (state, action) => {
            return {
                ...state,
                companies: state.companies.filter(
                    (item) => item.id !== action.payload
                ),
            };
        })
        .addCase(delete_cognito_user, (state, action) => {
            return {
                ...state,
                cognitoUsers: state.cognitoUsers.filter(
                    (item) => item.username !== action.payload
                ),
                users: state.users.filter((item) => item.id !== action.payload),
            };
        })
        .addCase(get_all_cognito_users_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(get_formality_companies_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(get_formality_users_success, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(update_admin, (state, action) => {
            return { ...state, ...action.payload };
        })
        .addCase(sign_out_success, () => {
            return initialState;
        });
});
export default reducer;

import { resetImpersonating } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useWindowSize } from "../hooks/useWindowSize";

export const ImpersonationAlert = () => {
    const [, width] = useWindowSize();
    const dispatch = useAppDispatch();
    const {
        user: { impersonating },
    } = useAppSelector((state) => state);

    const renderAlert = () => {
        return (
            <div className="user-alert-message">
                <strong>
                    {impersonating.firstName} {impersonating.familyName}
                </strong>
            </div>
        );
        // if (width >= 992) {
        //   return (
        //     <div className="user-alert-message">
        //       <strong>
        //         {impersonating.firstName} {impersonating.familyName}
        //       </strong>
        //     </div>
        //   );
        // } else if (width >= 768) {
        //   return <div className="user-alert-message">{impersonating.email}</div>;
        // }
        // return (
        //   <div className="user-alert-message">
        //     {impersonating.firstName.slice(0, 1).toUpperCase()}
        //     {impersonating.familyName.slice(0, 1).toUpperCase()}
        //   </div>
        // );
    };

    return (
        <div
            className={`alert alert-danger mb-0 py-1 animate__animated animate__fadeIn ${
                width < 576 ? "pl-2 pr-2" : "pr-3"
            }`}
        >
            <div className="d-flex justify-content-between align-items-center">
                {renderAlert()}
                <i>
                    <span
                        className={`material-icons ${
                            width < 576 ? "ms-2" : "ms-3"
                        }`}
                        onClick={() => {
                            dispatch(resetImpersonating());
                        }}
                        style={{
                            color: "#721C24",
                            fontSize: "1rem",
                            cursor: "pointer",
                            marginTop: "6px",
                        }}
                    >
                        cancel
                    </span>
                </i>
            </div>
        </div>
    );
};

import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { resetImpersonating, signOut } from "../../actions";
import { useAppDispatch } from "../../hooks/reduxHooks";

interface Props {
    companyName: string;
    isAdmin: boolean;
    isDemo: boolean;
    role: string;
    userSubscriptionPlan: string;
}
export const NavBarCollapse = ({
    companyName,
    isAdmin,
    isDemo,
    role,
    userSubscriptionPlan,
}: Props) => {
    const dispatch = useAppDispatch();

    if (isAdmin) {
        return null;
    }
    if (isDemo) {
        return (
            <Nav.Link
                eventKey="/end-demo"
                onClick={() => {
                    dispatch(signOut());
                }}
            >
                End Demo
            </Nav.Link>
        );
    }
    return isDemo || isAdmin ? null : (
        <>
            <Nav.Link
                as={Link}
                to="/profile"
                eventKey="/profile"
                onClick={() => {
                    dispatch(resetImpersonating());
                }}
                style={{ cursor: "pointer" }}
            >
                Profile
            </Nav.Link>
            {role || userSubscriptionPlan ? null : (
                <Nav.Link as={Link} to="/registration" eventKey="/registration">
                    Subscribe
                </Nav.Link>
            )}
            <Nav.Link
                as={Link}
                to="/contact"
                eventKey="/contact"
                onClick={() => {
                    dispatch(resetImpersonating());
                }}
            >
                Contact Support
            </Nav.Link>
            <Nav.Link
                as={Link}
                to="/danger-zone"
                eventKey="/danger-zone"
                onClick={() => {
                    dispatch(resetImpersonating());
                }}
            >
                Delete Account
            </Nav.Link>
            {companyName === "disconnected" && (
                <Nav.Link as={Link} to="/update-email">
                    Update Email
                </Nav.Link>
            )}
            <Nav.Link
                eventKey="/sign-out"
                onClick={() => {
                    dispatch(signOut());
                }}
            >
                Sign Out
            </Nav.Link>
        </>
    );
};

import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";

const isDev = process.env.NODE_ENV === "development";

interface Props {
    children: JSX.Element | JSX.Element[];
}
export const AdminRoute = ({ children }: Props) => {
    const { id, isAdmin } = useAppSelector((state) => state.auth);
    const isAuthenticated = !!id;
    const location = useLocation();
    if (isAdmin && isAuthenticated) {
        if (isDev) {
            console.log(`%cShowing Admin Route`, "color:cyan");
        }
        return children;
    } else {
        if (isDev) {
            console.log(`%cRerouting to home from Admin Route`, "color:cyan");
        }
        return (
            <Navigate
                to={{
                    pathname: "/",
                }}
                replace
                state={{ from: location }}
            />
        );
    }
};

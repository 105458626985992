import { useMemo, useState } from "react";
import { AuthAction } from "./types";
import { Button } from "react-bootstrap";
import { ErrorAlert } from "./components/ErrorAlert";
import { Footer } from "./components/shared/Footer";
import { ForgotPassword } from "./components/ForgotPassword";
import { Header } from "./components/Header";
import { NewPasswordRequired } from "./components/NewPasswordRequired";
import { resendVerificationCode, handleForgotPassword } from "./actions";
import { ShowInterestModal } from "./components/modals/ShowInterestModal";
import { SignIn } from "./components/SignIn";
import { SignUp } from "./components/SignUp";
import { SignUpConfirmation } from "./components/SignUpConfirmation";
import { Spinner } from "./components/shared/Spinner";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { useNavigate, useSearchParams } from "react-router-dom";

const isDev = process.env.NODE_ENV === "development";

export const App = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        awaitingSignUpConfirmation,
        codeResent,
        forgotPassword,
        newPasswordRequired,
        signUpError,
    } = useAppSelector((state) => state.auth);
    const { alert } = useAppSelector((state) => state.formality);

    const [isNewUser, setIsNewUser] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [show, setShow] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const [searchParams] = useSearchParams();
    const params = useMemo(() => {
        let company = searchParams.get("company");
        let from = searchParams.get("from");
        let id = searchParams.get("id");
        let isInvite = searchParams.get("isInvite") === "true" ? true : false;
        let username = searchParams.get("username");
        let firstName = searchParams.get("firstName");
        let familyName = searchParams.get("familyName");
        const params = {
            company,
            from,
            id,
            isInvite,
            username,
            firstName,
            familyName,
        };
        return params;
    }, [searchParams]);

    const handleResendVerificationCode = async () => {
        try {
            setIsResending(true);
            await dispatch(resendVerificationCode());
            setIsResending(false);
        } catch (error) {
            console.warn(error);
            setIsResending(false);
        }
    };

    const renderForm = () => {
        if (awaitingSignUpConfirmation) {
            return <SignUpConfirmation />;
        }
        if (forgotPassword) {
            return <ForgotPassword />;
        }
        if (newPasswordRequired) {
            return (
                <NewPasswordRequired
                    setShowForgotPassword={setShowForgotPassword}
                />
            );
        }
        if (isNewUser || searchParams.get("isInvite")) {
            return <SignUp params={params} />;
        }
        return <SignIn setShowForgotPassword={setShowForgotPassword} />;
    };

    return (
        <>
            <div className="container vh-100">
                <div className="d-flex flex-column align-items-center justify-content-between vh-100 w-100 animate__animated animate__fadeIn">
                    <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                        <Header
                            title="Thai Formality"
                            image={
                                isNewUser || params.isInvite
                                    ? "songkran"
                                    : "classical"
                            }
                            font="fancy"
                        />
                        {renderForm()}
                        <div className="d-flex justify-content-center my-2">
                            {!awaitingSignUpConfirmation && !params.isInvite ? (
                                <Button
                                    className="custom-link-button d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        dispatch({
                                            type: AuthAction.CONFIRM_VERIFICATION_CODE_STOP,
                                        });
                                        setShow(true)
                                        // setIsNewUser(!isNewUser);
                                    }}
                                    size="sm"
                                    tabIndex={-1}
                                    variant="link"
                                >
                                    {isNewUser ? "Existing User?" : "New User?"}
                                </Button>
                            ) : null}
                            {!awaitingSignUpConfirmation && !params.isInvite ? (
                                <Button
                                    className="custom-link-button d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        dispatch({
                                            type: AuthAction.UPDATE_AUTH,
                                            payload: { isDemo: true },
                                        });
                                        if (isDev) {
                                            console.log(
                                                "%c📽️ Entering Demo Mode",
                                                "color:cyan"
                                            );
                                        }
                                        navigate("/demo");
                                    }}
                                    size="sm"
                                    tabIndex={-1}
                                    variant="link"
                                >
                                    Demo
                                </Button>
                            ) : null}
                            {showForgotPassword ? (
                                <Button
                                    className="custom-link-button-warning d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        setShowForgotPassword(false);
                                        dispatch(handleForgotPassword());
                                    }}
                                    tabIndex={-1}
                                    size="sm"
                                    variant="link"
                                >
                                    Forgot password?
                                </Button>
                            ) : null}
                            {signUpError && (
                                <Button
                                    className={`d-flex align-items-center justify-content-center ${
                                        codeResent
                                            ? "custom-link-button-success"
                                            : "custom-link-button-danger"
                                    }`}
                                    disabled={codeResent || isResending}
                                    onClick={() =>
                                        handleResendVerificationCode()
                                    }
                                    size="sm"
                                    // style={{ width: codeResent ? 120 : 178 }}
                                    tabIndex={-1}
                                    value="link"
                                >
                                    {isResending ? (
                                        <Spinner
                                            size={8}
                                            color={
                                                codeResent
                                                    ? "success"
                                                    : "danger"
                                            }
                                            type="propagate"
                                        />
                                    ) : (
                                        <span>
                                            {codeResent
                                                ? "Code Re-sent"
                                                : "Resend Verification Code"}
                                        </span>
                                    )}
                                </Button>
                            )}
                            {awaitingSignUpConfirmation && !signUpError && (
                                <Button
                                    className={
                                        "custom-link-button-primary d-flex align-items-center justify-content-center"
                                    }
                                    disabled={codeResent || isResending}
                                    onClick={() =>
                                        handleResendVerificationCode()
                                    }
                                    // style={{ width: codeResent ? 120 : 178 }}
                                    tabIndex={-1}
                                    size="sm"
                                    variant="link"
                                >
                                    <span>
                                        {codeResent
                                            ? "Code Re-sent"
                                            : "Resend Verification Code"}
                                    </span>
                                    {/* {isResending ? (
                                        <Spinner
                                            size={8}
                                            color={isDark ? "light" : "dark"}
                                            type="propagate"
                                        />
                                    ) : (
                                        <span>
                                            {codeResent
                                                ? "Code Re-sent"
                                                : "Resend Verification Code"}
                                        </span>
                                    )} */}
                                </Button>
                            )}
                            {forgotPassword ||
                                (awaitingSignUpConfirmation && (
                                    <Button
                                        className="custom-link-button-primary d-flex align-items-center justify-content-center"
                                        onClick={() => {
                                            setIsNewUser(false);
                                            dispatch({
                                                type: AuthAction.RESET_AUTH,
                                            });
                                        }}
                                        tabIndex={-1}
                                        size="sm"
                                        variant="link"
                                    >
                                        Cancel
                                    </Button>
                                ))}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            {alert ? (
                <div className="fixed-bottom">
                    <ErrorAlert />
                </div>
            ) : null}
            <ShowInterestModal show={show} setShow={setShow}  />
        </>
    );
};

import React, { useContext } from "react";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import BounceLoader from "react-spinners/BounceLoader";
import CircleLoader from "react-spinners/CircleLoader";
import MoonLoader from "react-spinners/MoonLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import PuffLoader from "react-spinners/PuffLoader";
import RingLoader from "react-spinners/RingLoader";
import { ThemeContext } from "../../contexts/ThemeProvider";

interface Props {
    size: number;
    color: string;
    type?: string;
}
export const Spinner = ({ size, color, type = "puff" }: Props) => {
    const { isDark } = useContext(ThemeContext);
    const colors: Record<string, string> = {
        danger: "var(--bs-danger)",
        info: "var(--bs-info)",
        light: "var(--bs-light)",
        muted: "var(--bs-secondary-body)",
        primary: isDark ? "var(--bs-link-color)" : "var(--bs-primary)",
        secondary: "var(--bs-secondary)",
        success: "var(--bs-success)",
    };
    const override = css`
        display: flex;
        margin: 0 auto;
        &hover: {
            color: ${colors.light};
        }
    `;
    if (type === "beat") {
        return (
            <BeatLoader
                cssOverride={override as React.CSSProperties}
                size={size}
                color={color ? colors[color] : colors.light}
            />
        );
    }
    if (type === "circle") {
        return (
            <CircleLoader
                cssOverride={override as React.CSSProperties}
                size={size}
                color={color ? colors[color] : colors.light}
            />
        );
    }
    if (type === "moon") {
        return (
            <MoonLoader
                cssOverride={override as React.CSSProperties}
                size={size}
                color={color ? colors[color] : colors.primary}
            />
        );
    }
    if (type === "propagate") {
        return (
            <PropagateLoader
                cssOverride={override as React.CSSProperties}
                size={size}
                color={color ? colors[color] : colors.primary}
            />
        );
    }
    if (type === "puff") {
        return (
            <PuffLoader
                cssOverride={override as React.CSSProperties}
                size={size}
                color={color ? colors[color] : colors.primary}
            />
        );
    }
    if (type === "ring") {
        return (
            <RingLoader
                cssOverride={override as React.CSSProperties}
                size={size}
                color={color ? colors[color] : colors.primary}
            />
        );
    }
    return (
        <BounceLoader
            cssOverride={override as React.CSSProperties}
            size={size}
            color={color ? colors[color] : colors.light}
        />
    );
};

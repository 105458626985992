import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
import { changeUserEmailSchema } from "../validators";
import { getButtonColor, getSpinnerColor } from "../helpers";
import { Spinner } from "../components/shared/Spinner";
import { ThemeContext } from "../contexts/ThemeProvider";
import { updateFormalityUserEmail } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { useContext } from "react";

interface Props {
    isAwaitingConfirmationCode: boolean;
    setIsAwaitingConfirmationCode: (value: boolean) => void;
}
export const UpdateUserEmailForm = ({
    isAwaitingConfirmationCode,
    setIsAwaitingConfirmationCode,
}: Props) => {
    const { isDark } = useContext(ThemeContext);
    const dispatch = useAppDispatch();
    const {
        attributes: { email: currentEmail },
    } = useAppSelector((state) => state.auth);

    return (
        <Formik
            initialValues={{
                email: currentEmail || "",
            }}
            validationSchema={changeUserEmailSchema}
            onSubmit={async (values, actions) => {
                try {
                    actions.setSubmitting(true);
                    await dispatch(updateFormalityUserEmail(values));
                    setIsAwaitingConfirmationCode(true);
                } catch (error) {
                    actions.setSubmitting(false);
                    console.warn(error);
                }
            }}
        >
            {({ values, isSubmitting }) => (
                <Form
                    className="d-flex flex-column align-items-center animate__animated animate__fadeIn"
                    noValidate
                >
                    <div className="form-group mb-3">
                        <label htmlFor="email" className="form-label mt-0">
                            Email
                        </label>
                        <Field
                            className="form-control"
                            id="email"
                            name="email"
                            type="text"
                            style={{ width: 300 }}
                        />
                        <div className="formikErrorMessage">
                            <ErrorMessage name="email" />
                        </div>
                    </div>
                    <Button
                        className="d-flex align-items-center justify-content-center mt-2 mt-sm-3"
                        disabled={currentEmail === values.email || isSubmitting}
                        type="submit"
                        style={{ height: 40, width: 80 }}
                        variant={getButtonColor("primary", isDark)}
                    >
                        {isSubmitting ? (
                            <Spinner
                                size={10}
                                color={getSpinnerColor("primary", isDark)}
                                type="beat"
                            />
                        ) : (
                            <span>Save</span>
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

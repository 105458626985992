import { useContext  } from "react";
import { Button, Modal } from "react-bootstrap";
import { ThemeContext } from "../../contexts/ThemeProvider";
import { getButtonColor } from "../../helpers";
import { useNavigate } from "react-router-dom";

interface Props {
    show: boolean;
    setShow: (b: boolean) => void;
}
export function ShowInterestModal({ show, setShow }: Props) {
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ border: "none" }}>
                <Modal.Title>Thai Formality</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
                <span role="img" aria-label="Panda" style={{ fontSize: 64 }}>
                    🐼
                </span>
                <p className="lead">Thai Formality is currently in beta.</p>
                <p className="lead">Check out the demo</p>
                <p className="lead">Contact us to learn more</p>
            </Modal.Body>
            <Modal.Footer
                className="d-flex align-items-center justify-content-between"
                style={{ border: "none" }}
            >
                <Button
                    className="custom-cancel-button"
                    variant="link"
                    onClick={handleClose}
                >
                    close
                </Button>
                <Button
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => navigate("/interested")}
                    style={{ height: 38, width: 90 }}
                    variant={getButtonColor("primary", isDark)}
                >
                    <span>Contact</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

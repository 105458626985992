export enum FormDataAction {
    FILL_FORM_FAIL = "fill_form_fail",
    FILL_FORM_START = "fill_form_start",
    FILL_FORM_SUCCESS = "fill_form_success",
    RESET_FORMS = "reset_forms",
    SAVE_FORM_DATA_FAIL = "save_form_data_fail",
    SAVE_FORM_DATA_START = "save_form_data_start",
    SAVE_FORM_DATA_SUCCESS = "save_form_data_success",
    SET_THAI_ADDRESS_SEARCH_DATA = "set_thai_address_search_data",
    SET_USER_FORM_DATA = "set_user_form_data",
    SET_USER_INIT_FORM_DATA = "set_user_init_form_data",
    SETUP_DEMO_FORMS = "setup_demo_forms",
}
export interface FormData {
    applicationDetailsFormData: {
        applicationDate: string | undefined;
        writtenAt: string | undefined;
    };
    personalInfoFormData: {};
    stm2FormData: {};
    thaiAddressFormData: {};
    tm47FormData: {};
    tm7FormData: {};
    tm8FormData: {};
    travelDocFormData: {};
}
export type FormDataState = {
    [key in keyof FormData]: any;
};
export type RequiredFormDataItem = {
    dataSource: string;
    detail: string[];
};

export enum FormMetaDataItemType {
    DEPENDENT = "dependent",
    FILL = "fill",
    UPLOAD = "upload",
}

export type FormMetaData = Record<string, FormMetaDataItem>;
export type FormMetaDataItem = {
    name: string;
    shortName: string;
    title: string;
    printTitle: string;
    type: FormMetaDataItemType;
    printable: boolean;
    requiredFormData: RequiredFormDataItem[];
};
